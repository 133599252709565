import { Button, Link, Navbar } from "@nextui-org/react";
import { useHistory } from "react-router-dom";

const items = [
  {
    id: "login",
    display: "Login",
    path: "/login"
  },
  {
    id: "signup",
    display: "Sign Up",
    path: "/signup"
  }
];

const LandingNavBar = () => {
  const history = useHistory();

  return (
    <Navbar variant={"floating"} disableShadow>
      <Navbar.Brand onClick={() => history.push("/")}>
        <img
          width={200}
          style={{ objectFit: "contain", cursor:"pointer" }}
          src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Logo-Black.png?alt=media&token=a7e0a7ee-6f9c-4383-a870-3540e832d101" />
      </Navbar.Brand>
      <Navbar.Toggle aria-label="toggle navigation" showIn={"xs"}/>
      <Navbar.Collapse>
        {items.map((item) => (
          <Navbar.CollapseItem key={item.id}>
            <Link
              onClick={() => history.push(item.path)}
              color="inherit"
              css={{ minWidth: "100%" }}>
              {item.display}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>

      <Navbar.Content hideIn="xs">
        <Navbar.Link onClick={() => history.push("/login")}>
          Log In
        </Navbar.Link>
        <Navbar.Item>
          <Button 
            auto 
            as={Link} 
            bordered 
            rounded 
            color="secondary"
            onPress={() => history.push("/signup")}>
            Sign Up
          </Button>
        </Navbar.Item>
      </Navbar.Content>
    </Navbar>
  )
}

export default LandingNavBar;