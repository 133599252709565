import { Tag } from "react-tag-autocomplete";
import { ExperienceRole } from "types";

export const DEFAULT_INPUT_PROPS = {
  size: "xl",
  fullWidth: true,
}

export enum OnboardingStatus {
  DRAFT = "DRAFT",
  COMPLETE = "COMPLETE"
}
export interface CompanyRegistration {
  contactName: string;
  contactTitle: string;
  logoUrl: string;
  name: string;
  website: string;
  industry: Tag[];
  salesOrgSize: string;
  interviewStages: string[];
  salesHiringGoal: string;
  salesRoleHiringType: string[];
  preferredValueAdd: string[];
  atsProvider: string;
  type: OnboardingStatus;
}

export interface ExpertRegistration {
  tier: string;
  hourlyRate: number;
  hourlyRateCurrency: string;
  firstName: string;
  lastName: string;
  headline: string;
  linkedin: string;
  profilePhotoUrl: string;
  roles: ExperienceRole[];
  managedSalesRoles: string[];
  salesRolesExpertise: string[];
  industries: Tag[];
  regionsExperience: string[];
  type: OnboardingStatus;
}

export interface CreateInterviewFormValues {
  confirmSelection: boolean;
  draft?: boolean;
  expertName: string;
  expertCredits: number;
  expertId: string;
  name: string;
  email: string;
  linkedinUrl: string;
  resumeUrl: string;
  jobDescriptionUrl: string;
  roleTitle: string;
  roleLevel: string;
  roleSalaryRange: string;
  roleRegion: string;
  roleDealSize: string;
  roleAvgSalesCycle: string;
  tags: string[];
  description: string;
  notes: string;
}

export interface CandidateAssessmentFormValues {
  feedback: string;
  concerns: string;
  scoreCard: { [key: string]: number }
}
