import { CANDID_VALUE_ADD, COMPANY_SIZE, INDUSTRIES, INTERVIEW_STAGES } from "api/data/company";
import { SALES_ROLES } from "api/data/roles";
import CompanyProfile from "components/forms/company-profile";
import SelectOptionInput from "components/forms/fields/select-option-input";
import TagInput from "components/forms/fields/tag-input";
import { Tag } from "react-tag-autocomplete";
import { OnboardingStatus } from "types/forms";

export const FORM_DEFAULT_VALUES = {
  contactName: "",
  contactTitle: "",
  name: "",
  website: "",
  atsProvider: "",
  industry: new Array<Tag>(),
  salesOrgSize: "",
  salesRoleHiringType: new Array<string>(),
  salesHiringGoal: "",
  interviewStages: new Array<string>(),
  preferredValueAdd: new Array<string>(),
  type: OnboardingStatus.DRAFT,
};

export const STEPS = [
  {
    title: "What industry is your Company in?",
    component: (
      <TagInput
        id="industry"
        selectionMode="single"
        suggestions={INDUSTRIES.map(t => ({ label: t, value: t }))} />
    )
  },
  {
    title: "What's the size of your sales organisation?",
    component: (
      <SelectOptionInput
        id="salesOrgSize"
        options={COMPANY_SIZE}
        selectionMode="single" />
    )
  },
  {
    title: "Which of the following sales roles is your company likely to hire for in the next 12 months? ",
    component: (
      <SelectOptionInput 
        id="salesRoleHiringType" 
        options={SALES_ROLES} />
    )
  }
  ,
  {
    title: "And, how many sales roles are you likely to hire for?",
    component: (
      <SelectOptionInput 
        id="salesHiringGoal" 
        options={COMPANY_SIZE} 
        selectionMode="single" />
    )
  }
  ,
  {
    title: "At what stage(s) of the interview process do you expect to use Candid?",
    component: (
      <SelectOptionInput 
        id="interviewStages" 
        options={INTERVIEW_STAGES} 
        selectionMode="multiple" />
    )
  }
  ,
  {
    title: "And, where do you hope Candid will add value?",
    component: (
      <SelectOptionInput 
        id="preferredValueAdd" 
        options={CANDID_VALUE_ADD.map(i => ({display: i, value: i}))} 
        selectionMode="multiple" />
    )
  },
  {
    title: "Create your Company Profile",
    description: "",
    component: <CompanyProfile />
  },
]
