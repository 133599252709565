import { Button, Container, Grid, Spacer, Text } from "@nextui-org/react"
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { CSSProperties } from "react";

import { Caption } from "components/shared/styled"
import { EXPERTS, FEATURES, WHY_CANDID } from "api/data/landing";
import { styles } from "./styles";

import HeroText from "components/landing/hero-text";
import LandingNavBar from "components/landing/navbar";
import ExpertProfileCard from "components/landing/expert-profile-card";
import FeatureSection from "components/landing/feature-section";
import AutoScroller from "components/shared/auto-scroller";
import LandingFooter from "components/landing/footer";

const LandingPage = () => {
  const history = useHistory();

  const onClickCta = () => history.push('/signup');

  return (
    <>
      <div style={styles.heroContainer as CSSProperties}>
        <LandingNavBar />
        <Container
          lg
          justify="center"
          alignItems="center"
          display="flex"
          direction="column"
          css={{ flexFlow: "column" }}>
          <Spacer y={6} />
          <HeroText />
          <Spacer y={1} />
          <Text css={styles.heroSubtitle}>
            De-risk hiring by using external sales leaders to conduct a live video assessment of your candidates within 72 hours. Get quick, candid, unbiased feedback so you can continue to hire with confidence.
          </Text>
          <Spacer y={2} />
          <div style={{ display: "flex" }}>
            <Button
              auto
              rounded
              css={{ zIndex: 1 }}
              color="secondary"
              size={"lg"}
              iconRight={<BsArrowRight />}
              onClick={onClickCta}>
              Get Started for Free
            </Button>
          </div>
          <Spacer y={6} />
        </Container>
        <Container xl css={{ overflow: "hidden", w: "100%" }}>
          <AutoScroller margin="0 15px" speed="35s" pauseOnHover>
            {EXPERTS.map(expert =>
              <ExpertProfileCard {...expert} key={expert.name} />
            )}
          </AutoScroller>
        </Container>
      </div>
      <Spacer y={6} />
      <Container lg justify="center" alignItems="center">
        {FEATURES.map((feature, idx) => (
          <>
            <FeatureSection
              {...feature}
              reverse={idx % 2 == 0} />
            <Spacer y={6} />
          </>
        ))}
      </Container>

      <Container lg justify="center" alignItems="center">
        <Text h1 css={styles.sectionTitle}>
          Why Candid?
        </Text>
        <Spacer y={2} />
        <Grid.Container gap={4}>
          {WHY_CANDID.map(({ icon, title, description }) =>
            <Grid md={3} direction="column">
              {icon}
              <Spacer y={0.5} />
              <Text h3>
                {title}
              </Text>
              <Caption css={styles.sectionDescription}>
                {description}
              </Caption>
            </Grid>
          )}
        </Grid.Container>
        <Spacer y={4} />
      </Container>
      <LandingFooter/>
    </>
  )
}

export default LandingPage;