import { CSS } from "@nextui-org/react";

export const styles: { [key: string]: CSS } = {
  container: {
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    background:
      'radial-gradient(41.9% 33.3% at 49.2% 50%, rgb(82, 182, 138, 0.6) 0%, rgba(255, 255, 255, 0) 100%) 0% 0% / cover',
    padding: 15,
    borderRadius: 10,
    "@smMax": {
      height: 525
    }
  },
  loadingContainer: { 
    height: 75, 
    justifyContent: 'center', 
    alignItems: 'center', 
    display: 'flex', 
    flexDirection: 'column' 
  },
  textContainer: { 
    fontSize: 16, 
    whiteSpace: 'pre-line', 
    maxHeight: 280, 
    "@smMax": {
      maxHeight: 225
    },
    overflowY: 'auto' 
  },
  questionDefaults: { 
    display: "flex", 
    flexWrap: "wrap", 
    position: "absolute", 
    bottom: 60 
  }
}