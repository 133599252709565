import { Divider, Link, Spacer, Text } from "@nextui-org/react"
import { useHistory } from "react-router-dom"

const LandingFooter = () => {
  const history = useHistory();

  return (
    <>
      <Divider />
      <Spacer y={1} />
      <div style={{ textAlign: "center", }}>
        <Text>© AgencyDiagonal Limited. All Rights Reserved.</Text>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Link css={{ pt: "$5" }} onClick={() => history.push("/contact")}>
            Contact Us
          </Link>
          <Spacer x={0.5} />
          <Link css={{ pt: "$5" }} onClick={() => history.push("/privacy")}>
            Privacy Policy
          </Link>
        </div>
      </div>
      <Spacer y={1} />
    </>
  )
}

export default LandingFooter;