import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  container: { 
    height: 450, 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center" 
  },
  loadingContainer: { 
    textAlign: "center", 
    display: "flex", 
    flexDirection: "column" 
  }
}