import { BsCalendarCheckFill, BsPencilFill, BsPersonFill, BsSendFill } from "react-icons/bs";
import { InterviewStatus } from "types";

export const STATUSES = [
  {
    completionStatus: [InterviewStatus.REQUESTED, InterviewStatus.EXPERT_ACCEPTED, InterviewStatus.INTERVIEW_SCHEDULED, InterviewStatus.INTERVIEW_COMPLETED, InterviewStatus.REPORT_COMPLETED, InterviewStatus.CANDIDATE_ACCEPTED],
    icon: <BsSendFill />,
    title: "Interview Requested",
    description: "The interview request has been sent to the expert."
  },
  {
    completionStatus: [InterviewStatus.EXPERT_ACCEPTED, InterviewStatus.INTERVIEW_SCHEDULED, InterviewStatus.INTERVIEW_COMPLETED, InterviewStatus.REPORT_COMPLETED, InterviewStatus.CANDIDATE_ACCEPTED],
    statusId: InterviewStatus.EXPERT_ACCEPTED,
    icon: <BsPersonFill />,
    title: "Expert Accepted",
    description: "The expert has accepted the interview request."
  }
  ,
  {
    completionStatus: [InterviewStatus.INTERVIEW_SCHEDULED, InterviewStatus.INTERVIEW_COMPLETED, InterviewStatus.REPORT_COMPLETED, InterviewStatus.CANDIDATE_ACCEPTED],
    statusId: InterviewStatus.INTERVIEW_SCHEDULED,
    icon: <BsCalendarCheckFill />,
    title: "Interview Scheduled",
    description: "The interview has been scheduled with the expert and candidate."
  },

  {
    completionStatus: [InterviewStatus.INTERVIEW_COMPLETED, InterviewStatus.REPORT_COMPLETED],
    icon: <BsSendFill />,
    title: "Interview Completed",
    description: "The interview has been completed and a report will be sent shortly."
  },

  {
    completionStatus: [InterviewStatus.REPORT_COMPLETED],
    icon: <BsPencilFill />,
    title: "Report Ready",
    description: "Your interview is now complete! You should be able to view the expert report."
  }
]