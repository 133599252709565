export enum SupportedImageTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg'
}
export enum UploadState {
  INIT,
  DONE,
  UPLOADING
}

export interface ExperienceRole {
  title: string;
  company: string;
  startYear: string;
  endYear: string;
}

export interface PublicExpert {
  name: string;
  city: string;
  profilePhoto: string;
  title: string;
  company: string;
  previousCompanies: string[];
}

export interface CallInfo {
  interviewId: string;
  completed: boolean;
  callId: string;
  dateTime: Date;
  roomToken: {
    token: string;
    uid: string;
  }
  isExpert: boolean;
  expert: {
    id: string;
    agoraId: string;
    name: string;
    profilePhotoUrl: string;
  },
  candidate: {
    id: string;
    resumeUrl: string;
    linkedinUrl: string;
    agoraId: string;
    name: string;
  };
  assessment?: {
    jobDescriptionUrl: string;
    roleLevel: string;
    roleTitle: string;
    description: string;
    notes: string;
    tags: string[];
  }
}

export interface InterviewCandidate {
  id: string;
  name: string;
  email: string;
  linkedinUrl: string;
  resumeUrl: string;
}

export interface InterviewRole {
  id: string;
  title: string;
  currency?: SupportedCurrencies,
  salaryRange: string;
  location: string;
  level: string;
  jobDescriptionUrl: string;
  averageSalesCycle: string;
  averageDealSize: string;
}


export enum InterviewStatus {
  DRAFT = 'draft',
  DECLINED = 'declined',
  REQUESTED = 'requested',
  EXPERT_ACCEPTED = 'expert_accepted',
  CANDIDATE_ACCEPTED = 'candidate_accepted',
  INTERVIEW_SCHEDULED = 'interview_scheduled',
  INTERVIEW_COMPLETED = 'interview_completed',
  REPORT_COMPLETED = 'report_completed'
}

export interface CompanyInterview {
  id: string;
  description: string;
  notes: string;
  tags: string[];
  status: InterviewStatus;
  created: Date;
  updated: Date;
  call: any;
  expert: any;
  report: any;
  role: InterviewRole;
  candidate: InterviewCandidate;
}

export interface CandidateInterview {
  id: string;
  created: Date;
  updated: Date;
  description: string;
  notes: string;
  status: InterviewStatus;
  tags: string[];
  expert: any;
  call: any;
}

export type AccountType = 'COMPANY' | 'EXPERT'

interface StripeConnectAccount {
  id: string;
  ownerId: string;
  country: string;
  currency: string;
  enabled: boolean;
}

interface AccountManager {
  created: Date;
  updated: Date;
  email: string;
  id: string;
  name: string;
  stripeId: string;
}

export interface ExpertProfile {
  id: string;
  tier: string;
  profilePhotoUrl: string;
  firstName: string;
  lastName: string;
  headline: string;
  credits: string;
  roleLevel: string;
  linkedin: string;
  connectedAccount?: StripeConnectAccount;
  manager?: AccountManager;
  questionnaire: {
    managedSalesRoles: string[];
    industries: string[];
    regionsExperience: string[];
    salesRolesExpertise: string[];
    roles: {
      title: string;
      endYear: string;
      proudestAchievement: string;
      startYear: string;
      company: string;
    }[]
  }
}

export type SupportedCurrencies = 'GBP' | 'USD' | 'EUR';

