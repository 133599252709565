import { Divider, Grid, Input, Spacer, Text } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { DEFAULT_INPUT_PROPS } from "screens/auth";
import SelectOptionInput from "../fields/select-option-input";
import { DEAL_SIZES, SALARY_RANGES, SALES_CYCLES, SALES_ROLES } from "api/data/roles";
import { REGIONS } from "api/data/company";
import DropdownSelectInput from "../fields/dropdown-select-input";
import FormikUploadFileOrTextInput from "../fields/upload-file-input/file-or-text";

const Curriencies = [
  {
    display: "USD $",
    value: "USD"
  },
  {
    display: "GBP £",
    value: "GBP"
  },
  {
    display: "EUR €",
    value: "EUR"
  }
]
const RoleInfoForm = () => {
  const { handleChange, values, touched, errors } = useFormikContext<any>();

  return (
    <>
      <Grid.Container gap={0.5} css={{ pl: 0 }}>
        <Grid md={6} sm={12} xs={12} direction="column">

          <Input
            {...DEFAULT_INPUT_PROPS}
            id="roleTitle"
            name="roleTitle"
            label="Role Title"
            onChange={handleChange}
            value={values.roleTitle}
            helperText={touched?.roleTitle && errors?.roleTitle ? errors.roleTitle : ""}
            helperColor={"error"}
            readOnly={values?.readOnly} />
        </Grid>
        <Grid md={6} sm={12} xs={12} direction="column">
          <DropdownSelectInput
            id="roleRegion"
            label="Primary Role Location"
            placeholder="Select Region"
            selectionMode="single"
            data={REGIONS.map(region => ({ value: region, display: region }))} />
        </Grid>
      </Grid.Container>
      <Spacer y={1} />
      <Grid.Container gap={0.5} css={{ pl: 0 }}>
        <Grid md={6} sm={12} xs={12} direction="column">
          <DropdownSelectInput
            id="roleCurrency"
            label="Role Currency"
            placeholder="Select Currency"
            selectionMode="single"
            data={Curriencies} />
        </Grid>
        <Grid md={6} sm={12} xs={12} direction="column">
          <DropdownSelectInput
            id="roleSalaryRange"
            label="Role Base Salary Range"
            placeholder="Select Salary Range"
            selectionMode="single"
            data={SALARY_RANGES(values.roleCurrency) as any} />
        </Grid>
      </Grid.Container>
      <Spacer y={1} />
      <FormikUploadFileOrTextInput
        id="jobDescriptionUrl"
        label="Job Description"
        uploadBasePath="jobDescriptions" />
      <Spacer y={1} />
      <Text css={{ ml: 5 }}>
        Role Level
      </Text>
      <SelectOptionInput
        id="roleLevel"
        selectionMode="single"
        options={SALES_ROLES.map(i => ({ display: i.display, value: i.value }))} />
      <Spacer y={0.5} />
      <Divider />
      <Spacer y={0.5} />
      <DropdownSelectInput
        id="roleDealSize"
        label="Average Deal Size"
        placeholder="Select Deal Size"
        selectionMode="single"
        data={DEAL_SIZES(values.roleCurrency) as any} />
      <Spacer y={1} />
      <Text css={{ ml: 5 }}>
        Average Sales Cycle
      </Text>
      <SelectOptionInput
        id="roleAvgSalesCycle"
        selectionMode="single"
        options={SALES_CYCLES.map(i => ({ display: i.display, value: i.value }))} />
    </>
  )
}

export default RoleInfoForm;
