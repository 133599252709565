import { useMemo } from "react";
import { Card, Grid } from "@nextui-org/react";
import { useParams } from "react-router-dom";

import { InterviewProvider } from "contexts/interview";
import { useInterview } from "hooks/useInterview";
import { useInterviewId } from "hooks/useInterviewId";
import Container from "layout/portal-layout/container";
import Tabs from "components/shared/tabs";
import ExpertInterviewRequestDetails from "./request-details";
import CandidateReportForm from "components/forms/candidate-report";
import AviAiInsights from "components/avi-insights";
import ExpertActionButtons from "components/expert/interview-action-button";

const TABS = [
  {
    id: "request",
    title: "Candidate details"
  },
  {
    id: "report",
    title: "Report"
  },
  {
    id: "avi",
    title: "Avi insights"
  }
]

const ExpertInterviewPage = () => {
  const { interview } = useInterview();

  const { tabId } = useParams<{ tabId?: string }>();
  const tab = tabId ?? "request";

  const tabContent = useMemo(() => {
    switch (tab) {
      case "request":
        return <ExpertInterviewRequestDetails />
      case "report":
        return (
          <Card>
            <Card.Body>
              <CandidateReportForm />
            </Card.Body>
          </Card>
        )
      case "avi":
        return (
          <Card>
            <Card.Body>
              <AviAiInsights />
            </Card.Body>
          </Card>
        )
    }
  }, [tab]);

  const { role, id, company } = interview;
  return (
    <Container
      title={`You have an interview request from ${company.name}`}
      subtitle={`${role?.title} Role`}
      secondaryActions={<ExpertActionButtons />}>
     <Grid.Container gap={2} css={{"@smMax": { padding: 0 }}}>
        <Grid md={2} sm={2} xs={12} direction="column" css={{"@smMax": { flexDirection:"row", gap: 15 }}}>
          <Tabs selectedTab={tab} tabs={TABS} basePath={`/interview/${id}`} />
        </Grid>
        <Grid md={10} sm={10} xs={12}>
          {tabContent}
        </Grid>
      </Grid.Container>
    </Container>
  )
}

export const withExpertInterview = (WrappedComponent: any) => {
  return (props: any) => {
    const id = useInterviewId();
    
    return (
      <InterviewProvider id={id} type={'EXPERT'}>
        <WrappedComponent {...props} />
      </InterviewProvider>
    );
  };
};

export default withExpertInterview(ExpertInterviewPage);