import * as qs from "query-string";
import { Suspense, lazy, useEffect } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { RouteWrapper } from "screens/route-wrapper"
import { ExpertInterviewAvailabilityPage } from "./interview-availability"
import { Loading } from "screens/loading"
import ExpertAccountPage from "./account"
import ExpertHome from "./home"
import ExpertInterviewPage from "./interview"
import InterviewLayout from "layout/interview-layout"
import PortalLayout from "layout/portal-layout"
import ContactPage from "screens/contact";
import AuthLayout from "layout/auth-layout";

const CandidateInterview = lazy(() => import('../../interview'));

export const ExpertPortalViews = () => {
  const history = useHistory();
  const { redirectTo } = qs.parse(window.location.search);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo as any);
    }
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <RouteWrapper
          exact
          path={"/account/:tabId?"}
          component={ExpertAccountPage}
          layout={PortalLayout}
        />
        <RouteWrapper
          exact
          path="/interview/:interviewId/:tabId?"
          component={ExpertInterviewPage}
          layout={PortalLayout} />

        <Route
          exact
          path={'/meet/:token'}
          component={CandidateInterview} />

        <RouteWrapper
          exact
          path={"/availability/:interviewId"}
          component={ExpertInterviewAvailabilityPage}
          layout={InterviewLayout} />

        <Route
          exact path={'/contact'}
          component={ContactPage} />

        <RouteWrapper
          exact
          path="/*"
          component={ExpertHome}
          layout={PortalLayout} />
      </Switch>
    </Suspense>
  )
}