import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Row,  Table, Text, Tooltip, User } from "@nextui-org/react";
import { DISPLAY_STATUS, columns } from "./columns";

interface InterviewTableProps {
  interviews: any[];
}
const InterviewTable = ({
  interviews
}: InterviewTableProps) => {
  const history = useHistory();

  const renderCell = (request: any, columnKey: any) => {
    switch (columnKey) {
      case "name":
        return (
          <User
            size="md"
            squared
            text={request.candidate?.name.slice(0,1)}
            name={`${request.candidate?.name}`}
            description={request?.expert ? `Expert Assigned: ${request.expert?.firstName}` : "No Expert Assigned"}/>
        );
      case "created":
        return (
          <>
            <Text css={{ fontSize: "$sm", color: "$gray800" }}>
              Created: {moment(request.created).calendar()}
            </Text>
            <Text css={{ fontSize: "$sm", color: "$gray800" }}>
              Updated: {moment(request.updated).calendar()}
            </Text>
          </>
        );
      case "status":
        const {color, display} = (DISPLAY_STATUS as any)[request.status];
        return (
          <Button
            size="xs"
            css={{bgColor:color}}>
            {display}
          </Button>
        );
      case "actions":
        return (
          <Row justify="center" align="center">
            <Tooltip content="View & Manage Interview">
              <Button
                auto
                size="xs"
                color={"primary"}
                flat
                onClick={() => history.push(`/interview/${request.id}`)}>
                View Details
              </Button>
            </Tooltip>
          </Row>
        );
      default:
        return <></>;
    }
  };

  return (
    <Table
      shadow={false}
      color="secondary"
      css={{
        minWidth: "100%",
        maxHeight: 300,
        pl: 0,
        pr: 0,
        "@xsMax": {
          p: 0,
        },
      }}
    >
      <Table.Header columns={columns}>
        {(column) => (
          <Table.Column
            key={column.uid}
            hideHeader={column.uid === "actions"}
            align={column.uid === "actions" ? "center" : "start"}
          >
            {column.name}
          </Table.Column>
        )}
      </Table.Header>
      <Table.Body items={interviews.sort((a,b) => a.created < b.created ? 1 : -1)}>
        {(item) => (
          <Table.Row>
            {(columnKey) => (
              <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
            )}
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default InterviewTable;