import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  transcribeContainer: {
    position: "relative",
    minWidth: "100%",
    height: "100%",
    background: "url(https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Background_Feature.png?alt=media&token=e1ac490c-d29f-4990-8c4d-c7138ebe52c7)", padding: 20, color: "#fff", borderRadius: 15, backgroundSize: "cover",
    "@smMax": {
      height: 420
    }
  },
  inputContainer: { 
    position: "absolute", 
    fontSize:"$sm",
    bottom: 15, 
    left: 15,
    right: 15,
  },
  text: { 
    whiteSpace: "pre-wrap", 
    fontFamily: "monospace", 
    fontSize: "0.75rem",
    color: "#fff", 
    height: 400, 
    overflowX: "scroll", 
  },
  reviewCard: { 
    // boxShadow: "rgba(149, 157, 165, 0.9) 0px 8px 24px",
    width: "100%", 
    objectFit: "contain", 
    border: "1px solid #389981", 
    borderRadius: 15 
  }
}