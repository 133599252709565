import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "@firebase/auth";
import { Button, Input, Loading, Spacer, Text } from "@nextui-org/react";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { auth } from "config";
import { mapAuthCodeToMessage } from "helpers";

interface ChangePasswordFormValues {
  newPassword: string;
  password: string;
}

const ChangePasswordForm = () => {
  const snackbar = useSnackbar();

  const onSubmit = async (values: ChangePasswordFormValues, {setSubmitting, resetForm}: FormikHelpers<ChangePasswordFormValues>) => {
    try {
      setSubmitting(true);
      const currentUser = auth.currentUser!;
      const credential = EmailAuthProvider.credential(
        currentUser.email!,
        values.password
      );
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, values.newPassword);
      resetForm();
      snackbar.enqueueSnackbar("Password successfully updated", { variant:"success" });
    } catch(err) {
      snackbar.enqueueSnackbar(mapAuthCodeToMessage((err as any).code), { variant:"error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        newPassword: "",
      }}
      onSubmit={onSubmit}>
      {({
        isSubmitting,
        values,
        handleChange,
        handleSubmit
      }) => (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Text h3 css={{ fontSize: 18, lineHeight: 1 }}>
            Change Password
          </Text>
          <Spacer y={0.5} />
          <Input
            id="password"
            name="password"
            type="password"
            label="Existing Password"
            onChange={handleChange}
            value={values.password} />
          <Spacer y={0.5} />
          <Input
            id="newPassword"
            name="newPassword"
            type="password"
            label="New Password"
            onChange={handleChange}
            value={values.newPassword} />
          <Spacer y={0.8} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              auto
              flat
              size="sm"
              color={"error"}
              onClick={() => handleSubmit()}
              disabled={!values.newPassword || !values.password}>
              {isSubmitting ? <Loading type="spinner"/> : "Update Password"}
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ChangePasswordForm;