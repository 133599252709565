import { Checkbox, Container, Divider, Modal, Spacer, Text } from "@nextui-org/react";
import FormStepperActions from "components/forms/stepper-actions";
import { Caption } from "components/shared/styled";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { useAPI } from "hooks/useApi";
import { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { STEPS } from "./steps";
import CreateInterviewHeader from "./header";
import { CreateInterviewFormValues } from "types/forms";
import { CREATE_INTERVIEW_REQUEST_VALIDATION_SCHEMA } from "config/validation.schema";
import { useSnackbar } from "notistack";
import { CompanyInterview } from "types";
import { useCompany } from "hooks/useCompany";
import { useMediaQuery } from "hooks/useMediaQuery";

interface CreateInterviewProps {
  interview: CompanyInterview;
  isEdit?: boolean;
  disabled?: boolean;
  onClose?: () => void;
}

const LastStepWarnings = () => {
  const { availableCredits } = useCompany();
  const { values, setFieldValue } = useFormikContext<CreateInterviewFormValues>();

  const content = useMemo(() => {
    if (availableCredits < values.expertCredits) {
      return (
        <Text color="primary">
          You need to purchase more credits in order to successfully submit.
          <Link to="/account/credits" >
            <b>{" Purchase More"}</b>
          </Link>
        </Text>
      )
    }
    return <></>
  }, [availableCredits, values.expertCredits])

  return (
    <>
      {content}
      <Checkbox size="sm" onChange={(selected) => setFieldValue("confirmSelection", selected)}>
        <Text weight="medium">
          Confirm Expert Selection: {values.expertName} for {values.expertCredits} Credit(s)
        </Text>
      </Checkbox>

    </>
  );
}

const CreateInterview = ({
  interview,
  onClose,
  isEdit = false,
  disabled = false,
}: CreateInterviewProps) => {
  const { refresh } = useCompany();
  const history = useHistory();
  const api = useAPI();
  const snackbar = useSnackbar();
  const isSmall = useMediaQuery(600);

  const [step, setStep] = useState(0);

  const onSubmit = async (values: CreateInterviewFormValues, { setSubmitting }: FormikHelpers<CreateInterviewFormValues>) => {
    if (values.draft) {
      handleSaveDraft(values, { setSubmitting } as FormikHelpers<CreateInterviewFormValues>);
      return;
    }
    setSubmitting(true);
    try {
      const { data } = isEdit ?
        await api.updateInterviewRequest(interview.id, values)
        : await api.createInterviewRequest(values);
      snackbar.enqueueSnackbar("Interview request submitted!", { variant: "success" });
      await refresh();
      history.push(`/interview/${data.id}`);
    } catch (err: any) {
      await handleSaveDraft(values, { setSubmitting } as any, true);
      await refresh();
      history.push('/');
      snackbar.enqueueSnackbar(err?.message ?? "Something went wrong, saving as draft for now.", { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSaveDraft = async (values: CreateInterviewFormValues, { setSubmitting }: FormikHelpers<CreateInterviewFormValues>, silent = false,) => {
    const params = {
      ...values,
      draft: true
    };
    if (isEdit) {
      await api.updateInterviewRequest(interview.id, params);
      await refresh();
      return;
    }
    if (values.name && values.email) {
      setSubmitting(true);
      const { data } = await api.createInterviewRequest({
        ...values,
        draft: true
      });
      await refresh();
      if (!silent) history.push(`/interview/${data.id}`);
      setSubmitting(false);
    }
  }

  const stepContent = useMemo(() => {
    const { title, description, component } = STEPS[step];
    return (
      <>
        <Text h3 css={{ "@smMax": { fontSize: 24 }, }}>
          {title}
        </Text>
        <Caption>
          {description}
        </Caption>
        <Spacer y={1} />
        {component}
        <Spacer y={1} />
      </>
    )
  }, [step]);

  return (
    <Modal
      open
      fullScreen={!isSmall}
      closeButton
      onClose={() => {
        if (onClose) {
          onClose();
          return
        }
        history.push('/')
      }}>
      <Formik
        initialValues={{
          confirmSelection: disabled ? true : false,
          readOnly: disabled,
          expertCredits: 0,
          expertName: interview?.expert?.name ?? "",
          expertId: interview?.expert?.id ?? "",
          name: interview?.candidate?.name ?? "",
          email: interview?.candidate?.email ?? "",
          linkedinUrl: interview?.candidate?.linkedinUrl ?? "",
          resumeUrl: interview?.candidate?.resumeUrl ?? "",
          jobDescriptionUrl: interview?.role?.jobDescriptionUrl ?? "",
          roleTitle: interview?.role?.title ?? "",
          roleLevel: interview?.role?.level ?? "",
          roleCurrency: interview?.role?.currency ?? "USD",
          roleSalaryRange: interview?.role?.salaryRange ?? "",
          roleRegion: interview?.role?.location ?? "",
          roleDealSize: interview?.role?.averageDealSize ?? "",
          roleAvgSalesCycle: interview?.role?.averageSalesCycle ?? "",
          tags: interview?.tags ?? new Array<string>(),
          description: interview?.description ?? "",
          notes: interview?.notes ?? ""
        }}
        validationSchema={CREATE_INTERVIEW_REQUEST_VALIDATION_SCHEMA}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}>
        {({ }) => (
          <>
            <Modal.Body css={{ flexDirection: "column", "@smMax": { p: 0 } }}>
              <div>
                <CreateInterviewHeader currStep={step} />
                <Spacer y={1} />
                <Divider />
                <Container xs>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Spacer y={1} />
                    {stepContent}
                    {((step === STEPS.length - 1) && !disabled) && <LastStepWarnings />}
                  </div>
                </Container>

              </div>
            </Modal.Body>
            <Modal.Footer css={{ flexDirection: "row", flexShrink: 0, justifyContent: "center" }}>
              <Container sm css={{ flex: 1, "@xsMax": { pl: 0, pr: 0 } }}>
                <FormStepperActions
                  shouldValidate={true}
                  step={step}
                  setStep={setStep}
                  STEPS={STEPS}
                  disableSubmission={disabled}
                  handleSaveDraft={handleSaveDraft} />
              </Container>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateInterview;