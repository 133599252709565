import * as Yup from "yup";
import {
  Input,
  Text,
  Button,
  Loading,
  Spacer,
} from "@nextui-org/react";
import { sendPasswordResetEmail } from "@firebase/auth";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";

import { auth } from "config";
import { Caption } from "components/shared/styled";
import { DEFAULT_INPUT_PROPS } from "..";
import { BsArrowLeft } from "react-icons/bs";
import { mapAuthCodeToMessage } from "helpers";

const validationSchema = Yup.object({ email: Yup.string().email().required() });

interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPassword = () => {
  const snackbar = useSnackbar();
  const history = useHistory();

  const onSubmit = async (
    values: ForgotPasswordFormValues,
    { resetForm, setSubmitting }: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    setSubmitting(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      snackbar.enqueueSnackbar(
        "An email with how to reset your password has been successfully sent",
        { variant: "success" }
      );
      resetForm({});
    } catch (err) {
      snackbar.enqueueSnackbar(mapAuthCodeToMessage((err as any).code), { variant: "error" });
    }
    setSubmitting(false);
  };

  return (
    <>
      <Text h2 weight="medium">
        Forgot Password
      </Text>
      <Caption css={{color:"$gray700"}}>
        If you are having issues with your account please contact us
      </Caption>
      <Spacer y={1} />
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          values
        }) => (
          <>
            <Input
              {...DEFAULT_INPUT_PROPS}
              id="email"
              name="email"
              label="Email"
              placeholder="Enter your email"
              helperText={touched.email && errors.email ? errors.email : ""}
              value={values.email}
              onChange={handleChange}
            />
            <Spacer y={1} />
            <Button
              css={{ w: "100%" }}
              type="submit"
              color="secondary"
              size="md"
              rounded
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {isSubmitting ? (
                <Loading type="spinner" color="currentColor" size="sm" />
              ) : (
                "Send password reset link"
              )}
            </Button>
          </>
        )}
      </Formik>
      <Spacer y={1}/>
      <Button
        light
        auto
        icon={<BsArrowLeft/>}
        onClick={() => history.goBack()}>
        Go Back
      </Button>
    </>
  );
};

export default ForgotPassword;
