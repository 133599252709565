import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  heroContainer: {
    position:"relative",
    p: "$15",
    "@smMax": {
      p:"$8"
    }
  },
  contentContainer: { 
    display: "flex", 
    flex: 1, 
    justifyContent: "center", 
    flexDirection: "column", 
    position:"relative",
    padding: "15px",
  },
  heroText: {
    fontSize: "$4xl",
    whiteSpace: "pre-wrap",
    lineHeight: 1.3,
    color: "$white",
  }
}