import { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Card, Container, Divider, Grid, Spacer, Text, Textarea, User } from "@nextui-org/react";
import { styles } from "./styles";
import { qaPairs } from "api/data/landing";
import { BsSend } from "react-icons/bs";
import { useMediaQuery } from "hooks/useMediaQuery";

const ReportFeaturePreview = () => {

  const isXSmall = useMediaQuery(600);

  const [inputValue, setInputValue] = useState('');
  const [answer, setAnswer] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const typingDelay = 40; // Milliseconds between each character

  const simulateTyping = async (sourceText: string, targetStateSetter: Dispatch<SetStateAction<string>>) => {
    for (let i = 0; i < sourceText.length; i++) {
      await new Promise(resolve => setTimeout(resolve, typingDelay));
      targetStateSetter(prevValue => prevValue + sourceText[i]);
    }
  }

  useEffect(() => {
    const simulateQuestionAndAnswer = async () => {
      if (currentQuestionIndex < qaPairs.length) {
        setInputValue(''); // Reset question input
        setAnswer(''); // Reset answer
        const currentQAPair = qaPairs[currentQuestionIndex];
        await simulateTyping(currentQAPair.question, setInputValue);
        await new Promise(resolve => setTimeout(resolve, typingDelay * 2));
        await simulateTyping(currentQAPair.answer, setAnswer);
  
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      } else {
        // Reset the index to loop back to the first question
        setCurrentQuestionIndex(0);
      }
    };
  
    simulateQuestionAndAnswer();
  }, [currentQuestionIndex]);  

  return (
    <div style={{ borderRadius: 15, padding: "12.5px", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <User
          size={isXSmall ? "sm" : "lg"}
          src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/SampleCandidate.png?alt=media&token=778bfdbb-f06b-4fd4-9c3c-56da14ce3067"
          name="Review of Kate J"
          description="Senior Sales Director Role" />
        <Button size="xs" disabled>
          View Interview
        </Button>
      </div>
      <Spacer y={0.5} />
      <Grid.Container gap={0.5}>
        <Grid md={6} sm={6} xs={12}>
          <Container fluid css={styles.transcribeContainer}>
            <Text weight={"semibold"} color="white">
              Ask our AI anything about the interview
            </Text>
            <Spacer y={0.5} />
            <Divider />
            <Spacer y={0.5} />
            <Text css={styles.text}>
              {answer}
              <Spacer y={1} />
              <Textarea
                size="sm"
                color="secondary"
                minRows={4}
                css={styles.inputContainer}
                value={inputValue} />
              
              <Button 
                auto 
                size={"xs"} 
                css={{ position:"absolute", bottom: 20, right: 20 }}>
                <BsSend/>
              </Button>
            </Text>
          </Container>
        </Grid>

        <Grid md={6} sm={6} xs={12}>
          <img
            style={styles.reviewCard as CSSProperties}
            src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Hiring-Report.png?alt=media&token=b3f84d73-a82f-4594-b927-0005af580970" />
        </Grid>
      </Grid.Container>
    </div>
  )
}

export default ReportFeaturePreview;