import { useEffect, useState } from "react";
import { useAPI } from "hooks/useApi";
import { Loading } from "screens/loading";
import Container from "layout/portal-layout/container"
import InterviewTable from "components/interview/table";
import { Text } from "@nextui-org/react";
import { Link } from "react-router-dom";

const ExpertHome = () => {
  const api = useAPI();

  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchInterviews = async() => {
    setLoading(true);
    const { data } = await api.fetchExpertInterviews();
    setInterviews(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchInterviews()
  },[])

  if(loading) return <Loading/>;
  return (
    <Container
      title="Interviews"
      subtitle="Manage your upcoming interviews">
      <InterviewTable interviews={interviews}/>
      {((interviews || []).length === 0) ? (
        <Text h5 css={{whiteSpace:"pre-line", textAlign:"center"}}>
          {"Thanks for signing up to Candid as an expert; your interview requests will appear here.\nIn the meantime, if you haven't already, set up your Stripe payments "}
          <Link to="/account/payments">here</Link>
        </Text>
      ): <></>}
    </Container>
  )
}

export default ExpertHome;