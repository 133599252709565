import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  container: { 
    justifyContent: "center", 
    display: "flex", 
    alignItems: "center",
    "@xsMax": {p:0}
  },
  headerContainer: { 
    flexDirection: "column", 
    textAlign: "center", 
    pt: "$10" 
  },
  footerLogo: { 
    width: 75, 
    marginLeft: 5, 
    objectFit: "contain" 
  }
}