import { useEffect, useState } from "react";
import { Button, Card, Divider, Input, Spacer, Text, User } from "@nextui-org/react";
import { Caption2 } from "components/shared/styled";
import { useAPI } from "hooks/useApi";
import { Loading } from "screens/loading";

const Integrations = [
  {
    id: "greenhouse",
    name: "Greenhouse",
    description: "Connect your Greenhouse ATS with the API key below",
    logoUrl: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/greenhouse-logo.png?alt=media&token=b2062cbc-6809-4cd3-ade1-e8c6d5fcabc6&_gl=1*1tvy3tz*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODA3NjQ1MC41MS4xLjE2OTgwNzY2NTcuMzAuMC4w",
    actionUrl: "",
  }
];

interface ActiveIntegrations {
  [key: string]: string;
}
const AccountIntegrations = () => {
  const api = useAPI();

  const [integrations, setIntegrations] = useState<ActiveIntegrations>();
  const [loading, setLoading] = useState(false);

  const fetchApiKeys = async () => {
    setLoading(true);
    const { data } = await api.getAtsIntegrationApiKeys();
    setIntegrations({ greenhouse: data?.greenhouseKey })
    setLoading(false);
  }
  const onGenerateKey = async (id: string) => {
    switch (id) {
      case "greenhouse":
        setLoading(true);
        await api.generateGreenhouseApiKey();
        await fetchApiKeys();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    fetchApiKeys()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Card variant="bordered" css={{ p: "$8" }}>
      <Text h4 weight="normal" css={{ mb: "$5" }}>
        Setup your ATS Integration
      </Text>
      <Caption2 css={{ fontSize: 14 }}>
        Connect your ATS system to recieve candidates directly inside of Candid.
      </Caption2>
      <Spacer y={1} />
      <Divider />
      <Spacer y={1} />
      {Integrations.map((integration) => (
        <Card css={{ maxW: 400 }} key={integration.id}>
          <Card.Header>
            <User
              squared
              size="lg"
              css={{ pl: 0 }}
              src={integration.logoUrl}
              name={integration.name}>
              {integration.description}
            </User>
          </Card.Header>
          <Card.Body css={{ pt: "$5" }}>
            {integrations?.[integration.id] ? (
              <Input
                fullWidth
                readOnly
                label="Your Partner API Key"
                value={integrations?.[integration.id]} />
            ) : (
              <Button 
                auto 
                ghost 
                onClick={onGenerateKey.bind(this, integration.id)}>
                Generate API Key
              </Button>
            )}
          </Card.Body>
        </Card>
      ))}
    </Card>
  )
}

export default AccountIntegrations;