import {
  createContext,
  FunctionComponent,
  useMemo
} from "react";

import { APIService } from "../api";

export const apiContext = createContext<APIService>(new APIService());

export const APIProvider: FunctionComponent = ({ children }) => {
  const api = useMemo(() => new APIService(), []);

  return <apiContext.Provider value={api}>{children}</apiContext.Provider>;
};
