import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { Card, Grid } from "@nextui-org/react";
import Container from "layout/portal-layout/container";
import EditUser from "components/forms/edit-user";
import Tabs from "components/shared/tabs";
import { ExpertProfileWrapper } from "components/forms/expert/expert-profile";
import ExpertStripePayments from "./payments";

export const TABS = [
  {
    id: "profile",
    title: "Profile",
  },
  {
    id: "user",
    title: "User",
  },
  {
    id: "payments",
    title: "Payments",
  },
];

const ExpertAccountPage = () => {
  const { tabId } = useParams<{ tabId?: string }>();
  const tab = tabId ?? "profile";

  const tabContent = useMemo(() => {
    switch (tab) {
      case "profile":
        return (
          <Card variant="bordered">
            <Card.Body>
              <ExpertProfileWrapper />
            </Card.Body>
          </Card>
        )
      case "user":
        return <EditUser />
      case "payments":
        return <ExpertStripePayments />
    }
  }, [tab]);

  return (
    <Container
      title="Account"
      subtitle="Manage your account and view your payouts">
      <Grid.Container gap={2} css={{ "@smMax": { padding: 0 } }}>
        <Grid md={2} xs={12} direction="column" css={{ "@smMax": { flexDirection: "row", gap: 15 } }}>
          <Tabs tabs={TABS} selectedTab={tab} />
        </Grid>
        <Grid md={10} xs={12}>
          {tabContent}
        </Grid>
      </Grid.Container>
    </Container>
  );
}

export default ExpertAccountPage;