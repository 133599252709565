import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  imageContainer: { 
    pb: 0, 
    justifyContent:"center", 
    alignItems:"center",
    display:"flex",
    position:"relative",
  },
  textContainer: {
    textAlign:"left",
    pt: 0,
    borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
    bottom: 0,
    zIndex: 1,
  },
  title: { 
    fontWeight: "$semibold", 
    fontSize: "$2xl", 
    lineHeight: 1.3, 
    textAlign:"center",
    whiteSpace: "pre-wrap", 
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
}