import { useState } from "react";
import moment from "moment";
import CreateInterview from "../create-interview-request";
import { Avatar, Button, Card, Grid, Spacer, Text } from "@nextui-org/react";
import { InterviewProvider } from "contexts/interview";
import { useInterview } from "hooks/useInterview";
import { useInterviewId } from "hooks/useInterviewId";
import InterviewStatusUpdates from "components/interview/status-updates";
import Container from "layout/portal-layout/container";
import { InterviewStatus } from "types";
import { useIntercom } from 'react-use-intercom';
import { useHistory } from "react-router-dom";
import { getInitials } from "utils";

const InterviewPage = () => {
  const history = useHistory();

  const { interview } = useInterview();
  const { show } = useIntercom();
  const [showDetails, setShowDetails] = useState(false);

  if (interview.status === "draft") {
    return (
      <CreateInterview
        isEdit
        interview={interview} />
    )
  }

  if (showDetails) {
    return (
      <CreateInterview
        disabled
        isEdit={true}
        interview={interview}
        onClose={() => setShowDetails(false)} />
    )
  }

  const { candidate, role, expert, created, status, id } = interview;
  return (
    <Container
      title={candidate?.name}
      subtitle={`Interview for ${role?.title}`}>
      <Grid.Container>
        <Grid md={5} direction="column">
          <InterviewStatusUpdates />
        </Grid>
        <Grid md={7} sm={12} xs={12} css={{ "@smMax": { mt: "$15" } }}>
          <Card>
            <Card.Body css={{ alignItems: "center", justifyContent: "center" }}>
              <Avatar.Group css={{ pl: "$5" }}>
                <Avatar size="xl" src={expert.profilePhotoUrl} style={{ height: 100, width: 100 }} />
                <Avatar size="xl" text={getInitials(candidate.name)} style={{ height: 100, width: 100 }} />
              </Avatar.Group>
              <Spacer y={0.3} />
              <Text h4 weight="medium">
                {`${expert?.firstName} ${expert?.lastName} x ${candidate?.name}`}
              </Text>
              <Text css={{ fontSize: 12, opacity: 0.8 }}>
                Requested on {moment(created).calendar()}
              </Text>

              <Spacer y={0.8} />
              <Button
                onClick={() => history.push(`/interview/${interview.id}/report`)}
                disabled={status !== InterviewStatus.REPORT_COMPLETED}>
                View Report
              </Button>

              <Button light onClick={() => setShowDetails(true)}>
                View Request Details
              </Button>
              <Button light css={{ opacity: 0.4 }} onClick={() => window.open(`${window.location.origin}/contact`, "_blank")}>
                Need Help?
              </Button>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>
    </Container>
  )
}

export const withCompanyInterview = (WrappedComponent: any) => {
  return (props: any) => {
    // checks whether we are on the client/browser or server.
    const id = useInterviewId();

    return (
      <InterviewProvider id={id} type={'COMPANY'}>
        <WrappedComponent {...props} />
      </InterviewProvider>
    );
  };
};
export default withCompanyInterview(InterviewPage);