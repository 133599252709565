import { Card, Divider, Grid, Text } from "@nextui-org/react";

import ChangePasswordForm from "../change-password";
import ChangeEmailForm from "../change-email";

const EditUser = () => {
  return (
    <Card variant="bordered">
      <Card.Header css={{ p: "$8", flexDirection: "column", alignItems: "flex-start" }}>
        <Text h4 weight="normal">
          User Account
        </Text>
        <Divider />
      </Card.Header>
      <Card.Body css={{ pt: 0 }}>
        <Grid.Container gap={2}>
          <Grid md={6} sm={12} xs={12}>
            <ChangeEmailForm />
          </Grid>
          <Grid md={6} sm={12} xs={12}>
            <ChangePasswordForm />
          </Grid>
        </Grid.Container>
      </Card.Body>
    </Card>
  )
}
export default EditUser;