import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  loadingContainer: {
     height: 250, 
     display: 'flex', 
     justifyContent: 'center', 
     alignItems: 'center' 
  },
  creditCellContainer: {
    display: 'flex',
      justifyContent: 'space-between',
      cursor: "pointer",
      alignItems: 'center',
      borderRadius: 10,
      padding: 10,
      marginBottom: 15
  }
}