import { Container, Grid, Text } from "@nextui-org/react";
import { styles } from "./styles";
import Canvas from "components/shared/stripe-gradient/canvas";

interface AuthLayoutProps {
  reverse?: boolean;
  children: JSX.Element | JSX.Element[]
}
const AuthLayout = ({
  reverse = false,
  children
}: AuthLayoutProps) => {

  return (
    <Grid.Container css={{ h: "100vh", "@smMax": { h: "auto" } }} direction={reverse ? "row-reverse" : "row"}>
      <Grid md={6} sm={6} xs={12} css={styles.heroContainer}>
        <Canvas/>
        <div style={{zIndex: 9999, justifyContent:"space-between", display:"flex", flexDirection:"column"}}>
        <img
          width={200}
          style={{ objectFit: "contain" }}
          src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Logo-White.png?alt=media&token=793f6330-2bcb-496d-bc0b-d8c5d7e1de8c" />
        <div>
          <Text h1 css={styles.heroText} weight={"bold"} hideIn={"sm"}>
            {"Build high-performing sales teams with trusted external experts"}
          </Text>
          <Text css={{ whiteSpace: "pre-wrap", color: "$white", letterSpacing: 0.1 }} hideIn={"sm"}>
            {"Quick, candid, unbiased feedback so you can continue to hire with confidence."}
          </Text>
          </div>
        <Text small color="white" hideIn={"sm"}>
          © 2023 AgencyDiagonal Limited. All rights reserved.
        </Text>
        </div>

      </Grid>
      <Grid md={6} sm={6} xs={12} justify="center" alignItems="center" css={{ p: '$3' }} direction="column">
        <Container xs css={styles.contentContainer}>
          {children}
        </Container>
      </Grid>
    </Grid.Container>
  );
};

export default AuthLayout;