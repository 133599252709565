import { useFormikContext } from "formik";
import { useCallback } from "react";
import { ReactTags, Tag } from "react-tag-autocomplete"
import { Text } from "@nextui-org/react";

interface TagInputProps {
  id: string;
  label?: string;
  suggestions: Tag[];
  selectionMode?: "single" | "multiple"
}

const TagInput = ({ id, suggestions, label, selectionMode }: TagInputProps) => {

  const { values, setFieldValue, errors, touched } = useFormikContext<any>();


  const onAdd = useCallback(
    (newTag) => {
      if (selectionMode === "single") {
        setFieldValue(id, [newTag])
      } else {
        const currValues = values[id] || [];
        setFieldValue(id, [...currValues, newTag])
      }
    },
    [values[id]]
  )

  const onDelete = useCallback(
    (tagIndex) => {
      const currValues = values[id] || [];
      setFieldValue(id, currValues.filter((_: Tag, i: number) => i !== tagIndex))
    },
    [values[id]]
  )

  return (
    <>
      <ReactTags
        key={suggestions.length}
        labelText="Tags"
        selected={values?.[id] || []}
        placeholderText={label || ""}
        suggestions={suggestions}
        onAdd={onAdd}
        onDelete={onDelete}
        noOptionsText="No matching tags" />
      <Text small color="error" css={{mt:"$5"}}>{errors[id]}</Text>
    </>
  )
}

export default TagInput;