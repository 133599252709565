
export const ASSESSMENT_GOALS = [
  "Motivation for Seeking New Role",
  "Deal Sizes",
  "Sales Cycles",
  "Too Junior",
  "Too Senior",
  "Reasons for Leaving Last Role",
  "Have They Met Sales Targets",
  "Career Progression",
  "Sales Skillsets",
  "What Interests Them About This Industry",
  "Communication & Rapport",
  "Nothing in Particular",
];