import { Loading as LoadingIndicator } from "@nextui-org/react";

export function Loading() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        background: "#fff",
        flexDirection: "column",
        placeItems: "center",
        justifyContent: "center"
      }}
    >
      <LoadingIndicator type="spinner" />
    </div>
  );
}
