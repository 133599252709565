import { CSSProperties, useCallback, useState } from "react";
import { BsSend } from "react-icons/bs";
import { Button, Card, Divider, Loading, Spacer, Text, Textarea } from "@nextui-org/react"
import { useAPI } from "hooks/useApi"
import { useInterview } from "hooks/useInterview";
import { styles } from "./styles";

const DEFAULT_QUESTIONS = [
  {
    display: "Summary of the call",
    value: "Provide a summary of the call"
  },
  {
    display: "Split of talk time",
    value: "Provide a percentage split of the talk time between the interviewer and candidate. Don't include the time spoken."
  },
  {
    display: "3 key points discussed",
    value: "Provide details of 3 important points discussed in the call"
  },
  {
    display: "How well did the candidate listen?",
    value: "Provide an analysis on how well the candidate listened on the call"
  },
  {
    display: "Areas for the candidate to improve",
    value: "Where could the candidate have improved?"
  },
  {
    display: "Candidate follow up questions",
    value: "What question(s) should we ask the candidate next?"
  }
];

const AviOracle = () => {
  const api = useAPI();
  const { interview } = useInterview();

  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const onAskQuestion = async (question: string) => {
    if (!question || question.trim() === '') return;
    setLoading(true);

    try {
      const { data } = await api.askCallQuestion(interview?.call?.id, { question });
      setAnswer(data);
    } catch (error) {
      setAnswer(`Error asking the question: ${JSON.stringify(error?.message)}`);
    } finally {
      setLoading(false);
      setQuestion('');
    }
  };

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && question) {
        onAskQuestion(question);
      }
    },
    [question]
  );

  return (
    <Card variant="bordered" css={styles.container}>
      <div>
        <Text h5 css={{ opacity: 0.4 }}>
          Ask 'Avi' our artificial video intelligence anything about the interview
        </Text>
        <Divider />
        <Spacer y={1} />
        <Text css={styles.textContainer}>
          {loading ? (
            <div style={styles.loadingContainer as CSSProperties}>
              <Loading type="points" />
            </div>
          ) : (
            <>
              {answer && (
                <Text css={{ opacity: 0.6 }} weight="medium">
                  AVI AI: {answer}
                </Text>
              )}
            </>
          )}
        </Text>
      </div>
      {(!loading) && (
        <div style={styles.questionDefaults as CSSProperties}>
          {DEFAULT_QUESTIONS.map((question, idx) =>
            <Button
              key={`default-q-${idx}`}
              size="xs"
              bordered
              css={{ m: "$2" }}
              onClick={onAskQuestion.bind(this, question.value)}>
              {question.display}
            </Button>
          )}
        </div>
      )}
      <div style={{ marginTop: 'auto', background: "#F1F3F5", borderRadius: 15 }}>
        <Textarea
          fullWidth
          shadow={false}
          animated={false}
          minRows={1}
          maxRows={2}
          css={{ pr: 25 }}
          placeholder="Type your question here"
          onChange={(e: any) => setQuestion(e.target.value)}
          value={question}
          disabled={loading}
          onKeyDown={onKeyPress} />
        <Button
          auto
          size="xs"
          disabled={!question || loading}
          onClick={onAskQuestion.bind(this, question)}
          css={{ position: 'absolute', bottom: 22, right: 20 }}>
          <BsSend />
        </Button>
      </div>
    </Card>
  );
};

export default AviOracle;
