import { Container, Spacer } from "@nextui-org/react";
import Navbar from "./navbar";

const PortalLayout = ({children}: {children: JSX.Element | JSX.Element[]}) => {

  return (
    <>
      <Navbar/>
      <Container md>
        {children}
      </Container>
      <Spacer y={1}/>
    </>
  )
}

export default PortalLayout;