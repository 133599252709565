import { createUserWithEmailAndPassword } from "@firebase/auth";
import { Button, Input, Loading, Spacer, Text } from "@nextui-org/react";
import { Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Caption } from "components/shared/styled";
import { auth } from "config";
import { SIGNUP_VALIDATION_SCHEMA } from "config/validation.schema";
import { DEFAULT_INPUT_PROPS } from "..";
import LinkedinAuthButton from "components/auth/linkedin-auth-button";
import DividerWithText from "components/shared/divider-with-text";
import { mapAuthCodeToMessage } from "helpers";

interface SignupFormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp = () => {
  const snackbar = useSnackbar();

  const onSubmit = async (
    values: SignupFormValues,
    { setSubmitting }: FormikHelpers<SignupFormValues>
  ) => {
    setSubmitting(true);
    try {
      await createUserWithEmailAndPassword(auth, values.email, values.password);
      // Send form details
    } catch (err) {
      snackbar.enqueueSnackbar(mapAuthCodeToMessage((err as any).code), { variant: "error" });
    }
    setSubmitting(false);
  };


  return (
    <>
      <Text h2 weight="medium">
        Sign Up
      </Text>
      <Caption css={{ color: "$gray700" }}>
        Enter your email and password
      </Caption>
      <Spacer y={1} />
      <Formik
        validationSchema={SIGNUP_VALIDATION_SCHEMA}
        initialValues={{
          email: "",
          password: "",
          confirmPassword: ""
        }}
        onSubmit={onSubmit} >
        {({
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit
        }) => (
          <>
            <Input
              {...DEFAULT_INPUT_PROPS}
              label="Work Email"
              id="email"
              name="email"
              placeholder="you@email.com"
              helperText={touched.email && errors.email ? errors.email : ""}
              onChange={handleChange} />
            <Spacer y={0.5} />

            <Input
              {...DEFAULT_INPUT_PROPS}
              label="Password"
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              helperText={touched.password && errors.password ? errors.password : ""}
              onChange={handleChange} />
            <Spacer y={0.5} />

            <Input
              {...DEFAULT_INPUT_PROPS}
              label="Confirm Password"
              id="confirmPassowrd"
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ""}
              onChange={handleChange} />
            <Spacer y={1} />

            <Button
              css={{ w: "100%" }}
              type="submit"
              color="secondary"
              size="lg"
              rounded
              disabled={isSubmitting}
              onClick={() => handleSubmit()}>
              {isSubmitting ? (
                <Loading type="spinner" color="currentColor" size="sm" />
              ) : (
                "Sign Up"
              )}
            </Button>
          </>
        )}
      </Formik>
      <Spacer y={1.5} />
      <DividerWithText text="OR"/>
      <Spacer y={1.5} />
      <LinkedinAuthButton/>
      <Spacer y={1} />
      
      <Text css={{ textAlign: "center", mt: "$5", letterSpacing: -0.5, fontSize: "0.95rem" }}>
        {"Already have an account? "}
        <Link to={"/login"}>Log In</Link>
      </Text>
    </>

  );
};

export default SignUp;
