import { Button, Card, Grid, Spacer, Text } from "@nextui-org/react"
import { BiSolidBusiness } from "react-icons/bi"
import { FaUserTie } from "react-icons/fa"

import { Caption } from "components/shared/styled"
import { useHistory } from "react-router-dom"
import { BsArrowRight } from "react-icons/bs"
import { useState } from "react"

const types = [
  {
    id: "company",
    icon: <BiSolidBusiness fontSize={30} />,
    title: "I'm a Hiring Company",
    description: "Seeking an external expert to assess a candidate"
  },
  {
    id: "expert",
    icon: <FaUserTie fontSize={30} />,
    title: "I'm a Sales Leader",
    description: "Here to help companies hire amazing talent"
  },
]

const SelectAccountType = () => {
  const history = useHistory();

  const [type, setType] = useState<string | null>();
  return (
    <div>
      <Text h2>
        Select your Account Type
      </Text>
      <Spacer y={1} />
      <Grid.Container direction="row" gap={1}>
        {types.map(({ title, description, id }) => (
          <Grid md={6} sm={12} xs={12} key={id}>
            <Card
              variant="bordered"
              css={{ 
                p: "$5", 
                mt: "$5", 
                bgColor: type === id ? "#bfefda" : "inherit" 
              }}
              isPressable
              onClick={() => setType(id)}
              isHoverable>
              <Card.Body>
                <Text h3>
                  {title}
                </Text>
                <Caption>
                  {description}
                </Caption>
                <Spacer y={0.5} />
              </Card.Body>
            </Card>
          </Grid>
        ))}
      </Grid.Container>
      <Spacer y={1} />
      <Button
        disabled={!type}
        auto
        rounded
        css={{ float: "right" }}
        iconRight={<BsArrowRight />}
        onClick={() => history.push(`/${type}`)}>
        Continue
      </Button>
    </div>
  )
}
export default SelectAccountType;