import ReactPlayer from 'react-player';
import { useEffect, useState } from "react";
import { Button, Grid, Loading, Spacer, Text } from "@nextui-org/react";
import { CgTranscript } from "react-icons/cg";

import { Caption2 } from "components/shared/styled";
import { useAPI } from "hooks/useApi"
import { useInterview } from "hooks/useInterview";
import AviOracle from "./avi-oracle";

const AviAiInsights = () => {
  const { interview } = useInterview();
  const api = useAPI();

  const [loading, setLoading] = useState(false);
  const [transcript, setTranscript] = useState<any>();
  const [summary, setSummary] = useState<string>();

  const [videoUrl, setVideoUrl] = useState("");

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [showTranscript, setShowTranscript] = useState(false);

  useEffect(() => {
    const fetchInsights = async () => {
      setLoading(true);
      try {
        if(!interview?.call.id) return;
        const { data } = await api.getCallInsights(interview?.call?.id);
        setVideoUrl(data.videoUrl);
        setTranscript(data.transcript);
        setSummary(data.summary)
      } catch (error) {
        console.error('Failed to fetch insights:', error);
      }
      setLoading(false);
    };

    fetchInsights();
  }, [interview]);


  const handleProgress = (state: any) => {
    const currentTime = state.playedSeconds * 1000;

    // Find the current word index based on the video's currentTime
    const newWordIndex = transcript?.words.findIndex(
      (word: any) => word.start <= currentTime && word.end >= currentTime
    );

    if (newWordIndex !== -1) {
      setCurrentWordIndex(newWordIndex);
    }
  };

  if (!interview?.call?.completed) {
    return <Caption2>AVI Insights are only available once a call has taken place</Caption2>
  }

  if (loading) return (
    <Loading
      type="points"
      css={{ height: 400, justifyContent: "center", display: "flex" }} />
  );

  return (
    <Grid.Container gap={2} css={{ "@smMax": { p: 0 } }}>
      <Grid md={7} direction="column">
        <ReactPlayer
          controls
          url={videoUrl}
          width="100%"
          style={{ background: "#000" }}
          progressInterval={200}
          onProgress={handleProgress}/>
        <Spacer y={1} />
        <Text weight="medium">Transcript Summary</Text>
        <Caption2>{summary ?? "Not Available"}</Caption2>
        <Spacer y={1}/>
        {transcript?.words.length > 0 && <div>
          <Button
            auto
            ghost
            icon={<CgTranscript />}
            onClick={() => setShowTranscript(prev => !prev)}>
            {showTranscript ? 'Hide' : 'View'} Full Transcript
          </Button>
        </div>}
        <Spacer y={1} />
        {showTranscript && (
          <div>
            <Caption2 className="transcription">
              {transcript?.words.map((word: any, index: number) => (
                <span
                  key={index}
                  style={index === currentWordIndex ? { color: '#B97509', background: '#FDEFD8' } : {}}
                >
                  {word.text}{' '}
                </span>
              ))}
            </Caption2>
          </div>
        )}
      </Grid>
      <Grid md={5} direction="column">
        <AviOracle />
      </Grid>
    </Grid.Container>
  );
};

export default AviAiInsights;