
export const COMPANY_SIZE = [
  {
    value: "5",
    display: "1-5 people"
  },
  {
    value: "10",
    display: "6-10 people"
  },
  {
    value: "20",
    display: "11-20 people"
  },
  {
    value: "50",
    display: "21-50 people"
  },
  {
    value: "100",
    display: "51-100 people"
  },
  {
    value: "200",
    display: "100+ people"
  }
]


export const INTERVIEW_STAGES = [
  {
    value: "on_application",
    display: "On Application"
  },
  {
    value: "long_list",
    display: "Longlist"
  },
  {
    value: "hr_interview",
    display: "HR Interview"
  },
  {
    value: "short_list",
    display: "Shortlist"
  },
  {
    value: "final_interview",
    display: "Final Interview"
  },
  {
    value: "offer_stage",
    display: "Offer Stage"
  },
]
export const INDUSTRIES = [
  "Apparel & Fashion",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Capital Markets",
  "Computer Hardware",
  "Computer Software",
  "Consumer Goods",
  "Defense & Space",
  "Education",
  "Events",
  "Fintech/Financial Services",
  "Food & Beverages",
  "Gaming",
  "Health, Wellness & Fitness",
  "Hospitality",
  "Information Technology & Services",
  "Insurance",
  "Internet",
  "Investment Banking",
  "Investment Management",
  "Legal Services",
  "Leisure & Travel",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Management Consulting",
  "Marketing & Advertising",
  "Market Research",
  "Medical Device",
  "Military & Defense",
  "Mining & Metals",
  "Media & Entertainment",
  "Oil & Energy",
  "Outsourcing/Offshoring",
  "Pharmaceuticals",
  "Real Estate",
  "Renewables & Environment",
  "Retail",
  "Sports",
  "Staffing & Recruiting",
  "Telecommunications",
  "Utilities",
  "Venture Capital",
]

export const CANDID_VALUE_ADD = [
  "Unbiased recruiting",
  "De-risk bad hiring",
  "Validating critical hiring decisions",
  "Improving hiring quality",
  "Save hiring manager time ",
  "Save Talent Acquisition / HR time",
  "Removing hiring fatigue ",
  "Helping get sign-off"
];

export const REGIONS = [
  "United States",
  "United Kingdom",
  "France",
  "Germany",
  "Netherlands",
  "Spain",
  "Nordics",
  "DACH",
  "CEE",
  "Northern Europe",
  "Southern Europe",
  "Europe",
  "UAE",
  "Middle East",
  "EMEA",
  "North America",
  "India",
  "China",
  "APAC",
]