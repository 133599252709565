import { Suspense, lazy } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Loading } from "./loading";
import { CompanyPortalViews } from "./portal/company";
import { RouteWrapper } from "./route-wrapper";
import { ExpertPortalViews } from "./portal/expert";
import OnboardingViews from "./onboarding";
import LandingPage from "./landing";
import PrivacyPolicy from "./privacy";
import CandidateAvailability from "./candidate/availability";
import Login from "./auth/login";
import SignUp from "./auth/signup";
import ForgotPassword from "./auth/forgot-password";
import AuthLayout from "layout/auth-layout";
import InterviewLayout from "layout/interview-layout";
import ShareableInterviewReport from "./report/shareable-interview-report";
import ContactPage from "./contact";

const CandidateInterview = lazy(() => import('./interview'));

export const Views = () => {

  const location = useLocation();
  const {
    loaded,
    user,
    type,
    isOnboarding,
  } = useAuth();

  const loggedIn = !!user;

  if (!loaded) {
    return <Loading />;
  }

  if (loggedIn) {
    if (isOnboarding) {
      return <OnboardingViews />
    }
    if (type === "COMPANY") {
      return <CompanyPortalViews />
    }
    if(type === "EXPERT") {
      return <ExpertPortalViews/>
    }
  }

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={'/'} component={LandingPage} />
        <RouteWrapper exact path={'/c/availability/:availabilityId'} component={CandidateAvailability} layout={InterviewLayout}/>
        <Route exact path={'/meet/:token'} component={CandidateInterview} />
        <Route exact path={'/privacy'} component={PrivacyPolicy}/>
        <Route exact path={'/contact'} component={ContactPage}/>
        <RouteWrapper exact path={'/login'} component={Login} layout={AuthLayout} state={{from: location.pathname }}/>
        <RouteWrapper exact path={'/signup'} component={SignUp} layout={AuthLayout} />
        <RouteWrapper exact path={'/forgot-password'} component={ForgotPassword} layout={AuthLayout} />
        <Route exact path="/s/:shareId" component={ShareableInterviewReport}/>
        <Route path="/*" component={() => <Redirect to={`/login?redirectTo=${location.pathname}`}/>}/>
      </Switch>
    </Suspense>
  );
};

export default Views;
