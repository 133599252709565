import { useState } from "react";
import { Link, Text } from "@nextui-org/react";

interface ExpandTextProps {
  text: string;
  limit: number;
}

const truncateString = (string: string, limit: number) => {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
};

const ExpandText = ({
  text,
  limit
}: ExpandTextProps) => {
  const [ expandText, setExpandText ] = useState(false);

  if (!text) return <></>;
  return (
    <Text css={{fontSize:"$sm"}}>
      {truncateString(text, (expandText ? 1000000 : limit))}

      {limit < text.length && 
      (<Link
        color="secondary"
        style={{
          marginLeft: 5,
          fontWeight: 500,
          cursor: "pointer"
        }}
        onClick={() => setExpandText(!expandText)}>
        {expandText ? "See Less" : "See More"}
      </Link>
      )}
    </Text>
  );
};

export default ExpandText;