import Axios, { AxiosInstance } from "axios";
import { AccountType } from "types";

const API_URL = process.env.REACT_APP_API_URL;

export class APIService {
  private readonly axios: AxiosInstance = Axios.create({ baseURL: API_URL });

  public authToken?: string;

  constructor() {
    this.axios.interceptors.response.use(
      res => res,
      err => {
        throw new Error(err.response.data.message);
      }
    )
  }
  // Sets the auth token
  public setAuth(token: string): void {
    this.authToken = token;
    this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  public getAuthToken(): string {
    return this.axios.defaults.headers.common["Authorization"];
  }

  // Clears the token
  public clear(): void {
    this.clearToken();
  }

  public async createSupportTicket(data: object) {
    await this.axios.post(`mailer/contact`, data);
  }

  // **Start of Profile Related Routes**
  public async getManagedProfiles() {
    return this.axios.get(`user/managed`);
  }

  public async createCompanyProfile(data: object) {
    await this.axios.post(`company`, data);
  }

  public async getCompanyProfile() {
    return this.axios.get(`company`);
  }

  public async getMyCompany() {
    return this.axios.get(`company`);
  }

  public async createExpertProfile(data: object) {
    await this.axios.post(`expert`, data);
  }

  public async updateExpertProfileQuestionnaire(data: object) {
    await this.axios.post(`expert/questionnaire`, data);
  }


  public async getMyExpertProfile() {
    return this.axios.get(`expert`);
  }

  public async getRecommendedExperts(data: object) {
    return this.axios.post(`expert/recommended`, data);
  }
  // **End of Profile Related Routes**

  public async getShareableReportLink(id: string) {
    return this.axios.get(`company/interview/${id}/share`);
  }

  public async getInterviewReportFromShareLink(id: string) {
    return this.axios.get(`/interview/share/${id}`);
  }

  // **Start of Avi AI Related Routes**
  public async getCallInsights(id: string) {
    return this.axios.get(`avi/call/${id}/poll`);
  }

  public async askCallQuestion(id: string, data: object) {
    return this.axios.post(`avi/call/${id}/ask`, data);
  }
  // **End of Avi AI Related Routes**

  // **Start of Interview Related Routes**
  public async fetchExpertInterviews() {
    return this.axios.get(`expert/interviews`);
  }

  public async getInterviewAvailability(id: string) {
    return this.axios.get(`interview/availability/${id}`);
  }

  public async getInterviewFromToken(id: string) {
    return this.axios.get(`interview/meet/${id}`);
  }

  public async scheduleInterview(id: string, data: object) {
    return this.axios.post(`interview/availability/${id}/schedule`, data);
  }

  public async createInterviewReport(id: string, data: object) {
    return this.axios.post(`interview/meet/${id}/report`, data);
  }

  public async getInterviewReport(id: string) {
    return this.axios.get(`interview/meet/${id}/report`);
  }

  public async startInterviewRecording(id: string) {
    return this.axios.post(`interview/meet/${id}/record/start`);
  }

  public async completeInterview(id: string) {
    return this.axios.post(`interview/meet/${id}/complete`);
  }

  public async getInterview(id: string, type?: AccountType) {
    return this.axios.get(`${type === 'EXPERT' ? 'expert/' : type === 'COMPANY' ? 'company/' : ''}interview/${id}`);
  }

  public async createInterviewRequest(data: object) {
    return this.axios.post(`company/interview`, data);
  }

  public async updateInterviewRequest(id: string, data: object) {
    return this.axios.post(`company/interview/${id}`, data);
  }

  public async acceptInterview(id: string, data: object) {
    return this.axios.post(`expert/interview/${id}/accept`, data);
  }

  public async declineInterview(id: string) {
    return this.axios.post(`expert/interview/${id}/decline`);
  }
  // **End of Interview Related Routes**


  // **Start of Payment Related Routes**
  public async setupExpertStripeAccount() {
    return this.axios.post(`/expert/stripe`);
  }

  public async fetchExpertStripeAccountLink() {
    return this.axios.get(`/expert/stripe/link`);
  }

  public async getCreditPackages() {
    return this.axios.get(`payments/packages`);
  }

  public async createCheckoutSession(priceId: string) {
    return this.axios.get(`payments/purchase/${priceId}`);
  }

  public async getPaymentHistory() {
    return this.axios.get(`payments/history`);
  }

  public async longPollCreditOrder(chargeId: string) {
    return this.axios.get(`payments/verify/${chargeId}`);
  }
  // **End of Payment Related Routes**

  public async generateGreenhouseApiKey() {
    return this.axios.post(`/ats/greenhouse/generate-key`);
  }

  public async getAtsIntegrationApiKeys() {
    return this.axios.get(`/ats/greenhouse/api-keys`);
  }

  private clearToken() {
    delete this.axios.defaults.headers.common["Authorization"];
  }
}
