import { currencyMap } from "./currencyMap";

export function displayMoney(amount, currency, rounded = 2) {
  const currencySign = currencyMap[currency?.toUpperCase()] ?? currency;

  const displayAmount = (amount / 100);

  if(rounded) {
    return `${currencySign}${displayAmount.toFixed(rounded)}`;
  }
  return `${currencySign}${(amount / 100).toLocaleString()}`;
}

export const displayCurrencySign = (currency) => {
  const currencySign =
    currencyMap[currency.toUpperCase()] ?? currency.toUpperCase();
  return currencySign;
};

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const getHttpErrorMessage = (error) => {
  if (error?.response?.data) {
    return JSON.stringify(error?.response.data.message);
  }

  if (error?.code) {
    return JSON.stringify(error?.code);
  }
  return JSON.stringify(error);
};

export const getInitials = (name) => {
  const initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
  return initials.join("");
};