import { useAPI } from "hooks/useApi";
import { FunctionComponent, createContext, useEffect, useState } from "react";
import { Loading } from "screens/loading";
import { AccountType } from "types";

export interface InterviewContext {
  interview: any;
  refresh: () => Promise<void>
}

export const interviewContext = createContext<InterviewContext>({
  interview: null,
  refresh: async() => void 0,
});

interface Props {
  type?: AccountType;
  defaultInterview?: any;
  id?: string;
}

export const InterviewProvider: FunctionComponent<Props> = ({ id, type, defaultInterview, children }) => {
  const api = useAPI();
  
  const [interview, setInterview] = useState(defaultInterview);
  const [loading, setLoading] = useState(defaultInterview ? false : true);

  const refresh = async () => {
    if (defaultInterview) return;
    setLoading(true);
    try {
      const { data } = await api.getInterview(id!, type);
      setInterview(data);
    } catch (error) {
      console.error('Failed to refresh interview:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  if(loading) return <Loading/>;
  if(!interview) return <>Interview Failed To Load</>
  return (
    <interviewContext.Provider value={{
      interview,
      refresh
    }}>
      {children}
    </interviewContext.Provider>
  );
};
