// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { Avatar, Grid, Input, Link, Progress, Spacer, Text } from "@nextui-org/react";
import { storage } from "config";
import { UploadState } from "types";
import { useSnackbar } from "notistack";
import { FaPlus } from "react-icons/fa";

interface Props {
  label: string;
  savePath: string;
  helperText?: string;
  localUpload?: boolean;
  onChange: (media: string) => void;
  defaultFileUrl?: string;
}

const ImageUploader = ({
  label,
  helperText,
  localUpload,
  savePath,
  onChange,
  defaultFileUrl,
}: Props) => {
  const [file, setFile] = useState(null);
  const [uploadedMediaUrl, setUploadedMediaUrl] = useState<string | null>(null);
  const fileRef = useRef();
  const [uploadState, setUploadState] = useState(UploadState.INIT);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (uploadedMediaUrl) {
      setUploadState(UploadState.DONE);
      onChange(uploadedMediaUrl);
    }
  }, [uploadedMediaUrl]);

  const onFileChange = useCallback(async (files) => {
    const fileToUpload = files?.[0];

    if (localUpload) {
      const reader = new FileReader();
      reader.readAsDataURL(fileToUpload);
      reader.onloadend = (e) => {
        onChange(reader.result);
      };
      return;
    }

    const refLoc = ref(storage, `${savePath}/${fileToUpload.name}`);

    setFile(fileToUpload);
    setUploadState(UploadState.UPLOADING);

    // Create Upload Task
    const uploadTask = uploadBytesResumable(
      refLoc,
      fileToUpload,
      fileToUpload.type
    );
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => alert(error),
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        setUploadedMediaUrl(url);
      }
    );
  }, []);

  const isUploading = uploadState === UploadState.UPLOADING;
  return (
    <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
      <Avatar
        squared
        onClick={() => fileRef.current.click()}
        src={defaultFileUrl}
        icon={<FaPlus fontSize={40} />}
        css={{ width: 150, height: 150, cursor: "pointer" }} />
      <Input
        onChange={(e) => onFileChange(e.target.files)}
        ref={fileRef}
        type="file" css={{ display: "none" }} />
      {helperText && (
        <Text
          small
          size={"0.625rem"}
          weight="medium"
          color="error"
          css={{ marginTop: 5 }}
        >
          {helperText}
        </Text>
      )}
      <Spacer y={0.5} />
      {defaultFileUrl && !file && (
        <>
          <Text h6>
            <Link onClick={() => window.open(defaultFileUrl, "_blank")}>
              View Uploaded Image
            </Link>
          </Text>
          <Progress size="sm" color="success" value={100} />
          <Spacer y={1} />
        </>
      )}
      {file && (
        <Grid.Container direction="column">
          <Text h6>
            {isUploading ? "Uploading" : "Uploaded"}: {file.name}
          </Text>
          <Progress size="sm" color="success" value={progress} />
        </Grid.Container>
      )}
    </div>
  );
};


export default ImageUploader;
