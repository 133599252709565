import { CSS } from "@nextui-org/react";

export const styles: { [key: string]: CSS } = {
  headerContainer: { 
    display: "flex", 
    flexDirection: "row", 
    flexFlow: "row", 
    alignItems: "center" 
  },
  headerLink: { 
    lineHeight: 1, 
    fontSize: 14, 
    mt: 0, 
    letterSpacing: -0.4, 
    fontWeight: "$medium" 
  }
}