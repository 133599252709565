import moment from 'moment';
import ScheduleSelector from 'react-schedule-selector';
import { CSSProperties, useState } from 'react';
import { Button, Card, Container, Divider, Loading, Spacer, Text } from '@nextui-org/react';
import { useSnackbar } from 'notistack';

import { Caption2 } from 'components/shared/styled';
import { InterviewProvider } from 'contexts/interview';
import { useAPI } from 'hooks/useApi';
import { useInterview } from 'hooks/useInterview';
import { useInterviewId } from 'hooks/useInterviewId';
import { styles } from './styles';
import { InterviewStatus } from 'types';
import { Redirect } from 'react-router-dom';

interface ExpertInterviewAvailabilityProps {
  onClose?: () => void;
}

export const ExpertInterviewAvailability = ({
  onClose
}: ExpertInterviewAvailabilityProps) => {
  const api = useAPI();
  const snackbar = useSnackbar();

  const { interview, refresh } = useInterview();

  const [schedule, setSchedule] = useState<Date[]>();
  const [loading, setLoading] = useState(false);

  const onAcceptInterview = async () => {
    setLoading(true);
    try {
      await api.acceptInterview(interview.id, { availableTimes: schedule });
      await refresh();
      if (onClose) onClose();
    } catch (err) {
      snackbar.enqueueSnackbar(JSON.stringify(err), { variant: "error" });
    } finally {
      setLoading(false);
    }
  }
  
  // If not in requested state redirect to interview page
  if (interview.status !== InterviewStatus.REQUESTED) {
    return <Redirect to={`/interview/${interview.id}`} />;
  }

  const { candidate } = interview;
  return (
    <Container css={styles.container}>
      <Card>
        <Card.Header css={styles.headerContainer}>
          <Text h3 css={{ mb: 2 }}>
            Meet with {`${candidate?.name}`}
          </Text>
          <Caption2>
            {`Select all available times for a 45 mins video call. Please offer the candidate as many options to choose from as possible.`}
          </Caption2>
        </Card.Header>
        <Card.Divider />

        <Card.Body>
          <Spacer y={1} />
          <ScheduleSelector
            selectedColor='#52b68a'
            unselectedColor='#e5fbf1'
            hoveredColor='#73c8a3'
            selectionScheme={'linear'}
            numDays={7}
            dateFormat='dddd M/D'
            timeFormat='h:mma'
            minTime={7}
            maxTime={21}
            hourlyChunks={2}
            startDate={moment().toDate()}
            selection={schedule}
            onChange={newSchedule => setSchedule(newSchedule)} />
          <Spacer y={1} />
          <Divider />
          <Spacer y={1} />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <Text css={{ fontSize: 12 }}>Powered by</Text>
              <img style={styles.footerLogo as CSSProperties} src={process.env.REACT_APP_LOGO_URL} />
            </div>
            <div style={{ display: "flex" }}>
              <Button
                auto
                color={"secondary"}
                disabled={loading}
                onClick={onAcceptInterview}>
                {loading ? <Loading type="spinner" /> : "Send Availability"}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  )
}

export const ExpertInterviewAvailabilityPage = () => {
  const id = useInterviewId();
  return (
    <InterviewProvider id={id} type='EXPERT'>
      <ExpertInterviewAvailability />
    </InterviewProvider>
  )
}

