import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  heroText: { 
    fontSize: 72, 
    lineHeight: 1, 
    textAlign: "center", 
    whiteSpace: "pre-wrap",
    "@smMax": {
      fontSize: 52
    },
  }
}