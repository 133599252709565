import { CSSProperties } from "react";
import { Progress, Spacer, Text } from "@nextui-org/react";
import { BsCheck } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { Caption2 } from "components/shared/styled";
import { STATUSES } from "./statuses";
import { useInterview } from "hooks/useInterview";
import { styles } from "./styles";
import { InterviewStatus } from "types";

interface InterviewUpdateStep {
  completionStatus: InterviewStatus[];
  icon: JSX.Element;
  title: string;
  description: string;
}

const InterviewStatusUpdates = () => {
  const { interview } = useInterview();

  const renderStep = (step: InterviewUpdateStep) => {
    const isComplete = step.completionStatus.some(status => status === interview.status);

    return (
      <div style={styles.stepContainer as CSSProperties}>
        <div style={styles.stepHeaderContainer as CSSProperties}>
          <div style={{
            ...styles.iconContainer as CSSProperties,
            background: isComplete ? "#3eb68a" : "#999",
          }}>
            {step.icon}
          </div>
          <Progress
            animated
            value={isComplete ? 100 : 0}
            css={styles.progressBar} />
        </div>
        <Spacer y={0.5} />
        <div>
          <Text>
            {step.title}
          </Text>
          <Caption2 css={{ fontSize: 12 }}>
            {step.description}
          </Caption2>
        </div>
      </div>
    )
  }

  return (
    <>
      <Text h4>Updates</Text>
      <Spacer y={0.5} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        {STATUSES.map(renderStep)}
        <div style={{
          ...styles.iconContainer as CSSProperties,
          background: interview.status === InterviewStatus.REPORT_COMPLETED ? "#3eb68a" : interview.status === InterviewStatus.DECLINED ? "red" : "#999"
        }}>
          {InterviewStatus.DECLINED === interview?.status ? <AiFillCloseCircle/> : <BsCheck fontSize={30} />}
        </div>
      </div>
    </>
  )
}

export default InterviewStatusUpdates;