import { Spacer } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { Rating } from "react-custom-rating-component";
import { CandidateAssessmentFormValues } from "types/forms";

interface QuestionMap {
  [key: string]: string;
}

export const QUESTION_MAP: QuestionMap = {
  "rapport": "How well did the salesperson communicate and build rapport?",
  "motivation": "How motivated is the salesperson to take on this role?",
  "knowledge": "How well did the candidate demonstrate industry knowledge?",
  "seniority": "Is the salesperson suited to the seniority level required for this role?",
  "resiliency": "How resilient is the salesperson likely to be?",
  "coachable": "How coachable is the salesperson likely to be?"
};

const ScoreCardQuestions = () => {
  const { values, setFieldValue } = useFormikContext<CandidateAssessmentFormValues>();

  const handleRatingChange = (key: string, newRating: number) => {
    // Update the state with the new rating for the specified key
    setFieldValue("scoreCard", ({
      ...values.scoreCard,
      [key]: newRating
    }));
  };

  return (
    <>
      {Object.keys(QUESTION_MAP).map((key) => (
        <div key={key}>
          <p style={{ paddingBottom: "$3", fontSize: "0.875rem", letterSpacing: -0.4 }}>
            {QUESTION_MAP[key]}
          </p>
          <Spacer y={0.2} />
          <Rating
            count={5}
            defaultValue={values.scoreCard?.[key] || 0} // Use the stored rating or default to 0
            precision={0.5}
            size='24px'
            spacing='10px'
            activeColor='gold'
            onChange={(newRating) => handleRatingChange(key, newRating)}/>
          <Spacer y={0.6} />
        </div>
      ))}
    </>
  );
};

export default ScoreCardQuestions;