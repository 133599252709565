

import * as Yup from "yup";

const SIGNUP_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is a required field"),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Your password is too short.'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const LOGIN_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is a required field"),
  password: Yup.string()
    .required("Password is a required field")
    .min(6, "Your password seems a bit short")
});

const COMPANY_PROFILE_VALIDATION_SCHEMA = Yup.object({
  atsProvider: Yup.string().optional(),
  name: Yup.string().required("Company name is a required field"),
  contactName: Yup.string().required("Contact name is a required field"),
  contactTitle: Yup.string().required("Contact title is a required field"),
  industry: Yup.array().of(Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })).required("Industry is a required field").min(1, "Please enter your company industry"),
  interviewStages: Yup.array().of(Yup.string().required('Please select one or more interview stages you plan on using Candid for')).min(1, "Please select one or more interview stages you plan on using Candid for"),
  preferredValueAdd: Yup.array().of(Yup.string().required('Please select one or more places where you think Candid could add the most value')).min(1, "Please select one or more places where you think Candid could add the most value"),
  salesHiringGoal: Yup.string().required("Please your hiring goals for the next 12 months",),
  salesOrgSize: Yup.string().required("Please your sales organization size"),
  salesRoleHiringType: Yup.array().of(Yup.string().required('Please select one or more sales roles you plan on hiring for')).min(1, "Please select one or more sales roles you plan on hiring for"),
  website: Yup.string().required("Website is a required field"),
});


const ROLE_VALIDATION_SCHEMA = Yup.object({
  title: Yup.string().required(),
  proudestAchievement: Yup.string().required("Please enter a valid achievement"),
  company: Yup.string().required(),
  startYear: Yup.number().required(),
  endYear: Yup.string().required(),
});

const EXPERT_PROFILE_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("Please enter a valid first name"),
  lastName: Yup.string().required("Please enter a valid last name"),
  linkedin: Yup.string().required("Please enter a valid Linkedin URL"),
  headline: Yup.string().required("Please enter a valid headline"),
  hourlyRate: Yup.number().required("Please enter a valid hourly rate"),
  profilePhotoUrl: Yup.string().url().required("Please upload a valid profile photo"),
  industries: Yup.array().of(Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })).min(1, "Please select one or more industries you have experience in"),
  managedSalesRoles: Yup.array().of(Yup.string().required()).min(1, "Please select one or more sales roles you have experience in managing"),
  regionsExperience: Yup.array().of(Yup.string().required('Your regions experience is required')).min(1, "Please select one or more regions that you have sales experience in"),
  salesRolesExpertise: Yup.array().of(Yup.string().required('Sales roles expertise is required')).min(1, "Please select one or more sales roles that you have experience in"),
  tier: Yup.string().required(),
  roles: Yup.array().of(ROLE_VALIDATION_SCHEMA).min(1, "Please add one or more of your previous roles"),
});

const EXPERT_EDIT_QUESTIONNAIRE_VALIDATION_SCHEMA = Yup.object({
  industries: Yup.array().of(Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })).min(1, "Please select one or more industries you have experience in"),
  managedSalesRoles: Yup.array().of(Yup.string().required()).min(1, "Please select one or more sales roles you have experience in managing"),
  regionsExperience: Yup.array().of(Yup.string().required('Your regions experience is required')).min(1, "Please select one or more regions that you have sales experience in"),
  salesRolesExpertise: Yup.array().of(Yup.string().required('Sales roles expertise is required')).min(1, "Please select one or more sales roles that you have experience in"),
  roles: Yup.array().of(ROLE_VALIDATION_SCHEMA).min(1, "Please add one or more of your previous roles"),
});

const CREATE_INTERVIEW_REQUEST_VALIDATION_SCHEMA = Yup.object({
  confirmSelection: Yup.bool().oneOf([true], "Please confirm your expert selection").required(),
  expertId: Yup.string().required("Please select a valid expert"),
  name: Yup.string().required("Please enter a valid candidate name"),
  email: Yup.string().email().required("Please enter a valid candidate"),
  linkedinUrl: Yup.string().optional(),
  resumeUrl: Yup.string().optional(),
  jobDescriptionUrl: Yup.string().optional(),
  roleTitle: Yup.string().required("Please enter a valid role title"),
  roleLevel: Yup.string().required("Please enter a valid role level"),
  roleRegion: Yup.string().required("Please select a valid role location"),
  roleSalaryRange: Yup.string().required("Please select a valid salary range"),
  roleDealSize: Yup.string().required("Please select a valid deal size"),
  roleAvgSalesCycle: Yup.string().required("Please select a valid sales cycle"),
  description: Yup.string().required("Please enter a valid description"),
  notes: Yup.string().optional(),
  tags: Yup.array().of(Yup.string()).required("Please enter what you'd like the sales leader to look out for").min(1, "Please enter a minimum of 1").max(3, "Please enter a maximum of three"),
});


const CANDIDATE_ASSESSMENT_VALIDATION_SCHEMA = Yup.object({
  feedback: Yup.string().required("Please enter your feedback"),
  concerns: Yup.string().required("Please enter any red flags or if none write N/A"),
  scoreCard: Yup.object({
    rapport: Yup.number()
      .required("Question is a required field"),
    motivation: Yup.number()
      .required("Question is a required field"),
    knowledge: Yup.number()
      .required("Question is a required field"),
    seniority: Yup.number()
      .required("Question is a required field"),
    resiliency: Yup.number()
      .required("Question is a required field"),
    coachable: Yup.number()
      .required("Question is a required field"),

  }).required(),
});

const CONTACT_SUPPORT_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  message: Yup.string().required("Please enter your message"),
});


export {
  SIGNUP_VALIDATION_SCHEMA,
  LOGIN_VALIDATION_SCHEMA,
  COMPANY_PROFILE_VALIDATION_SCHEMA,
  EXPERT_PROFILE_VALIDATION_SCHEMA,
  ROLE_VALIDATION_SCHEMA,
  CREATE_INTERVIEW_REQUEST_VALIDATION_SCHEMA,
  CANDIDATE_ASSESSMENT_VALIDATION_SCHEMA,
  EXPERT_EDIT_QUESTIONNAIRE_VALIDATION_SCHEMA,
  CONTACT_SUPPORT_VALIDATION_SCHEMA
};