import { Input, Spacer } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { DEFAULT_INPUT_PROPS } from "screens/auth";
import FormikUploadFileInput from "../fields/upload-file-input";

const CandidateProfileForm = () => {
  const { handleChange, values, touched, errors } = useFormikContext<any>();

  return (
    <div>
      <Input
        {...DEFAULT_INPUT_PROPS}
        id="name"
        name="name"
        label="Candidate Name *"
        onChange={handleChange}
        value={values.name}
        helperText={touched?.name && errors?.name ? errors.name : ""}
        helperColor={"error"} />
      <Spacer y={1} />
      <Input
        {...DEFAULT_INPUT_PROPS}
        id="email"
        name="email"
        label="Candidate Email *"
        onChange={handleChange}
        value={values.email}
        helperText={touched?.email && errors?.email ? errors.email : ""}
        helperColor={"error"} />
      <Spacer y={1} />
      <Input
        {...DEFAULT_INPUT_PROPS}
        id="linkedinUrl"
        name="linkedinUrl"
        label="Linkedin URL"
        onChange={handleChange}
        value={values.linkedinUrl}
        helperText={touched?.linkedinUrl && errors?.linkedinUrl ? errors.linkedinUrl : ""}
        helperColor={"error"} />
      <Spacer y={1} />
      <FormikUploadFileInput 
        id="resumeUrl" 
        uploadBasePath="resumes"
        label="Upload Resume" />
    </div>
  )

}

export default CandidateProfileForm;