import ReactDOM from "react-dom";
import "./config";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import App from "./App";
import * as Sentry from "@sentry/react";

import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://fc24e55f5bab4a4a971a207e143ae8da@o1372123.ingest.sentry.io/6676927",
  integrations: [ new BrowserTracing() ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
