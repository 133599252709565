import moment from "moment-timezone"
import { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useIntercom } from "react-use-intercom";
import { Avatar, Button, Card, Container, Link, Spacer, Text } from "@nextui-org/react";
import { useAPI } from "hooks/useApi";
import { CandidateInterview, InterviewStatus } from "types";
import { Loading } from "screens/loading";
import { groupTimesByDay } from "utils/dates";
import { Caption2 } from "components/shared/styled";

const CANNOT_MAKE_IT_MESSAGE = (id: string) => `Hi - I cannot make my Candid expert interview. Can you please help me reschedule? Interview # ${id}`

const CandidateAvailability = () => {
  const { showNewMessage } = useIntercom();

  const api = useAPI();
  const snackbar = useSnackbar();

  const { availabilityId } = useParams<{ availabilityId: string }>();

  const [interview, setInterview] = useState<CandidateInterview>();
  const [availability, setAvailability] = useState<Date[]>([]);
  const [selectedTime, setSelectedTime] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  const refreshAvailability = async () => {
    setLoading(true);
    try {
      const { data } = await api.getInterviewAvailability(availabilityId);
      setInterview(data.interview);
      const times = groupTimesByDay(data.availableTimes);
      setAvailability(times);
    } catch (err) {
      snackbar.enqueueSnackbar(JSON.stringify(err), { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  const onConfirmTime = async () => {
    setLoading(true);
    try {
      await api.scheduleInterview(availabilityId, { time: selectedTime });
      await refreshAvailability();
    } catch (err) {
      snackbar.enqueueSnackbar(JSON.stringify(err), { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    refreshAvailability();
  }, []);

  
  const timeOptions = useMemo(() => {
    return Object.keys(availability).map(key => (
      <div key={key}>
        <Text css={{ opacity: 0.8 }}>
          {moment(key).format('dddd, MMMM D, YYYY')}
        </Text>
        <div style={{ display: "flex", marginTop: 10 }}>
          {(availability as any)[key].map((time: any) => (
            <Fragment key={time}>
              <Button
                auto
                onClick={() => setSelectedTime(time)}
                bordered={selectedTime !== time}>
                {moment(time).format('HH:mm')}
              </Button>
              <Spacer x={0.5} />
            </Fragment>
          ))}
        </div>
        <Spacer y={1} />
      </div>
    ))
  }, [availability, selectedTime])

  const onClickCannotMakeIt = () => window.open(`${window.location.origin}/contact?message=${CANNOT_MAKE_IT_MESSAGE(interview?.id || "No Id")}`, "_blank");

  const renderContent = () => {
    if (!interview?.call && interview?.status === InterviewStatus.EXPERT_ACCEPTED) {
      if (Object.keys(availability).length === 0) {
        return (
          <>
            <Text h4 weight="medium" css={{ textAlign: "center", lineHeight: 1 }}>
              {"No times in the future available"}
            </Text>
            <Caption2 css={{ textAlign: "center" }} onClick={onClickCannotMakeIt}>
              Get in touch with us to arrange the interview <Link onClick={onClickCannotMakeIt}><b>here</b></Link>
            </Caption2>
          </>
        )
      }
      return (
        <>
          <Text h4 weight="medium" css={{ textAlign: "center", lineHeight: 1 }}>
            {"Pick a time that works for you"}
          </Text>
          <Caption2 css={{ textAlign: "center" }}>
            {`Times are displayed in your local ${Intl.DateTimeFormat().resolvedOptions().timeZone} timezone`}
          </Caption2>
          <Spacer y={1} />
          {timeOptions}
          <Spacer y={1} />
          <Button
            auto
            onClick={onConfirmTime}
            disabled={!selectedTime}
            color="secondary"
            size="lg">
            Confirm Time
          </Button>
          <Spacer y={1} />
          <Text css={{ textAlign: "center" }} small onClick={onClickCannotMakeIt}>
            Don't see a time that works for you. <Link onClick={onClickCannotMakeIt}><b>Chat with us here</b></Link>
          </Text>
        </>
      );
    }

    return (
      <Text h4 css={{ textAlign: "center" }}>
        {interview?.call ? `Interview Scheduled for ${moment(interview?.call?.time).format('LLLL')}` : "Nothing here yet"}
      </Text>
    );
  }

  if (loading || !interview) return <Loading />;
  const { expert } = interview;
  return (
    <Container sm css={{ "@xsMax": { p: 15 } }}>
      <Card>
        <Card.Header css={{ flexDirection: "column" }}>
          <Avatar src={expert?.profilePhotoUrl} size="xl" css={{ h: 100, w: 100 }} />
          <Spacer y={0.5} />
          <Text h4>
            Meet with {expert.firstName} {expert.lastName}
          </Text>
        </Card.Header>
        <Card.Divider />
        <Card.Body>
          {renderContent()}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CandidateAvailability;
