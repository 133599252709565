import { Route, Switch, useHistory } from "react-router-dom";
import ExpertOnboardingForm from "./expert";
import CompanyOnboardingForm from "./company";
import SelectAccountType from "./select-type";
import AuthLayout from "layout/auth-layout";
import { Button, Divider, Spacer } from "@nextui-org/react";
import { useAuth } from "hooks/useAuth";


const OnboardingViews = () => {
  const { signOut } = useAuth();
  const history = useHistory();

  return (
    <AuthLayout reverse>
      <Switch>
        <Route exact path={'/expert'} component={ExpertOnboardingForm} />
        <Route exact path={'/company'} component={CompanyOnboardingForm} />
        <Route exact path="/*" component={SelectAccountType} />
      </Switch>
      <Spacer y={1} />

      <Divider/>
      <Spacer y={1} />
        <Button
          light
          auto
          color={"error"}
          size="xs"
          onClick={() => {
             signOut();
             history.replace('/')
          }}>
          Sign Out
        </Button>
    </AuthLayout>
  );
};

export default OnboardingViews;