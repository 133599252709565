import { useMemo } from "react";
import { Card, Divider, Spacer, Text, } from "@nextui-org/react";
import { Caption2 } from "components/shared/styled";
import { useExpert } from "hooks/useExpert";
import SetupStripeAccountButton from "components/payments/expert/setup-stripe-button";

const ExpertStripePayments = () => {
  const { profile } = useExpert();

  const isEnabled = useMemo(() => profile?.connectedAccount?.enabled, [profile?.connectedAccount]);
  return (
    <Card variant="bordered" css={{ p: "$8" }}>
      <Text h4 weight="normal">
        Your Payment Details
      </Text>
      <Caption2 css={{ fontSize: 14 }}>
        To complete your profile and receive interview payouts, setup your Stripe payments account here      
      </Caption2>
      <Spacer y={0.5} />
      <Divider />
      <Spacer y={1} />
      <Text>
        Account Status:
        <Text color={isEnabled ? "success" : "error"} weight="medium">
          {isEnabled ? "Enabled" : "Requires Setup"}
        </Text>
      </Text>
      <Spacer y={1} />
      <div>
        <SetupStripeAccountButton />
      </div>
    </Card>
  )
}

export default ExpertStripePayments;
;