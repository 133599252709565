import { Button, Divider, Loading, Spacer, Text, Textarea } from "@nextui-org/react"
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import ScoreCardQuestions from "../fields/score-card-questions";
import FormikFieldsErrorText from "../formik-error-field-text";
import { useInterview } from "hooks/useInterview";
import { useAPI } from "hooks/useApi";
import { InterviewStatus } from "types";
import { CANDIDATE_ASSESSMENT_VALIDATION_SCHEMA } from "config/validation.schema";
import { CandidateAssessmentFormValues } from "types/forms";

const CandidateReportForm = () => {
  const api = useAPI();
  const snackbar = useSnackbar();

  const { interview, refresh } = useInterview();

  const onSubmit = async (values: CandidateAssessmentFormValues, { setSubmitting }: FormikHelpers<CandidateAssessmentFormValues>) => {
    try {
      setSubmitting(true);
      await api.createInterviewReport(interview.call?.expertAccessToken, values),
      await refresh();
  
      snackbar.enqueueSnackbar(`Report successfully submitted`, { variant:"success" });
      // After both functions are completed, you can setSubmitting to false.
      setSubmitting(false);
    } catch (error) {
      snackbar.enqueueSnackbar(`An error occurred: ${JSON.stringify(error)}`, { variant:"error" });
      setSubmitting(false);
    }
  };

  const isReadOnly = interview?.status !== InterviewStatus.INTERVIEW_COMPLETED || !interview.call;
  return (
    <Formik
      initialValues={{
        feedback: interview?.report?.feedback || "",
        concerns: interview?.report?.concerns || "",
        scoreCard: interview?.report?.scoreCard || {},
      }}
      validationSchema={CANDIDATE_ASSESSMENT_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        values
      }) => (
        <>
          <Text h4 weight={"medium"} css={{ opacity: 0.6 }}>
            Rate the Candidate
          </Text>
          <Spacer y={0.5} />
          <ScoreCardQuestions />
          <Spacer y={1.25} />
          <Text h4 weight={"medium"} css={{ opacity: 0.6 }}>
            Written Opinion
          </Text>
          <Spacer y={0.5} />
          <Textarea
            size="lg"
            fullWidth
            id="feedback"
            name="feedback"
            label="Feedback"
            onChange={handleChange}
            value={values.feedback}
            readOnly={isReadOnly} />
          <Spacer y={1} />
          <Textarea
            fullWidth
            size="lg"
            id="concerns"
            name="concerns"
            label="Red Flags?"
            onChange={handleChange}
            value={values.concerns}
            readOnly={isReadOnly} />

          <Spacer y={1} />
          <FormikFieldsErrorText />
          <Spacer y={0.5} />
          <Divider />
          <Spacer y={0.5} />
          <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
            <Button
              auto
              disabled={isSubmitting || isReadOnly}
              onClick={() => handleSubmit()}>
              {isSubmitting ? <Loading type="spinner" size="xs"/> : "Submit Assessment"}
            </Button>
          </div>
        </>
      )}
    </Formik>
  )
}
export default CandidateReportForm;