import ReportFeaturePreview from "components/landing/report-feature-preview";
import { AiFillWarning } from "react-icons/ai";
import { BsFillFastForwardFill, BsFillSuitHeartFill } from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";

export const HEROTEXT_COMPANIES = ["Google", "Meta", "Salesforce", "Zendesk", "AWS", "Slack", "Microsoft", "Snowflake", "Workday"];

export const COMPANY_LOGOS = [
  "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1280px-Google_2015_logo.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/2560px-Microsoft_logo_%282012%29.svg.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Snowflake_Logo.svg/2560px-Snowflake_Logo.svg.png",
  "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Meta_Logo.png?alt=media&token=175af815-d6cc-45bb-803d-f4aaf2f6ed7b",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png",
];

export const LOGOS = {
  "Cisco": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Cisco_logo_blue_2016.svg/800px-Cisco_logo_blue_2016.svg.png",
  "Okta": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Okta_logo_%282023%29.svg/1200px-Okta_logo_%282023%29.svg.png",
  "Pinterest": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Pinterest_Logo.svg/2560px-Pinterest_Logo.svg.png",
  "Expedia": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Expedia_2012_logo.svg/2560px-Expedia_2012_logo.svg.png",
  "Spotify": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Spotify_logo_with_text.svg/2560px-Spotify_logo_with_text.svg.png",
  "MongoDB": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png",
  "Meta": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Meta_Platforms_Inc._logo.svg/1280px-Meta_Platforms_Inc._logo.svg.png",
  "Apptio": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Apptio_logo.svg/2560px-Apptio_logo.svg.png",
  "Tableau": "https://upload.wikimedia.org/wikipedia/commons/4/4b/Tableau_Logo.png",
  "GitLab": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/GitLab_logo.svg/2560px-GitLab_logo.svg.png",
  "Microsoft": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/2560px-Microsoft_logo_%282012%29.svg.png",
  "Oracle": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Oracle_logo.svg/2560px-Oracle_logo.svg.png",
  "Google": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1280px-Google_2015_logo.svg.png",
  "AirBNB": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Airbnb_Logo_B%C3%A9lo.svg/1280px-Airbnb_Logo_B%C3%A9lo.svg.png",
  "Reddit": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Reddit_logo.svg/1024px-Reddit_logo.svg.png?20230618173856",
  "Zendesk": "https://www.gainsight.com/wp-content/uploads/2023/04/Zendesk-Logo.png",
  "Salesforce": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1200px-Salesforce.com_logo.svg.png",
  "Workday": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Workday_logo.svg/1200px-Workday_logo.svg.png",
  "Gartner": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Gartner_logo.svg/2560px-Gartner_logo.svg.png",
  "Twilio": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Twilio-logo-red.svg/2560px-Twilio-logo-red.svg.png",
  "Unilever": "https://upload.wikimedia.org/wikipedia/commons/b/ba/Unilever_logo.png",
  "Alteryx": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Alteryx_logo.svg/2560px-Alteryx_logo.svg.png",
  "DataPac": "https://www.datapac.com/wp-content/uploads/2013/07/Datapac-Logo-large-format-1024x203.jpg",
  "Warner Bros": "https://cutewallpaper.org/24/warner-bros-pictures-logo-png/wb-warner-bros-%E2%80%93-logos-download.png",
  "Linkedin": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/1200px-LinkedIn_Logo.svg.png",
  "CA": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/CA_Technologies_logo.svg/2560px-CA_Technologies_logo.svg.png",
  "Mulesoft": "https://res.cloudinary.com/startup-grind/image/upload/dpr_2.0,fl_sanitize/v1/gcs/platform-data-mulesoft/contentbuilder/logo_Mulesoft_lgQwYjg.svg",
  "Conga": "https://documentation.conga.com/sign/_/0AE6065301865E250D19D82B368DA8DF/1689574663786/Conga_HeaderLogo.png"
}

export const EXPERTS = [
  {
    name: "Jason Janicke",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_3.jpeg?alt=media&token=8fd0a500-4532-42f9-b386-82bd10142ff3&_gl=1*ejq1of*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjMzODUuMjIuMC4w",
    title: "Senior Vice President\nSales EMEA",
    city: "London",
    company: "Alteryx",
    previousCompanies: ["Conga", "Salesforce"]
  },
  {
    name: "Judith David",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_2.jpeg?alt=media&token=2c4cf9c0-3fde-4e0f-ab70-6e46d7377b93&_gl=1*176qmcv*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjMzNTAuNTcuMC4w",
    title: "Global Head\nSales Enablement",
    city: "New York",
    company: "Pinterest",
    previousCompanies: ["Google", "Warner Bros"]
  },
  {
    name: "Nuria Munoz",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/professional-woman.jpg?alt=media&token=9dc1090d-be95-46e3-a99f-c89811100b67&_gl=1*1k4adhg*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5OTExMjU5My41OC4xLjE2OTkxMTI2NTIuMS4wLjA.",
    title: "Head of Talent\nSourcing EMEA",
    city: "London",
    company: "Microsoft",
    previousCompanies: ["Unilever"]
  },
  {
    name: "Andres Bang",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_4.jpeg?alt=media&token=c0a2667d-7e24-4e1f-8f8a-9fc7a7c23c4f&_gl=1*gs9ol0*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjM0MjAuNTQuMC4w",
    title: "Vice President\nRevenue Operations",
    city: "San Francisco",
    company: "Expedia",
    previousCompanies: ["AirBNB", "Linkedin"]
  },
  // {
  //   name: "Emily Huo",
  //   profilePhoto: "https://media.licdn.com/dms/image/D4E03AQHHKXmaCbgRLg/profile-displayphoto-shrink_800_800/0/1669640623445?e=1697673600&v=beta&t=03ceV2UCiS4uKHANHrcKquAS2R8hRVwYuBNTOZD2DHE",
  //   title: "SMB Sales Director",
  //   city: "San Francisco",
  //   company:"Spotify",
  //   previousCompanies: ["Reddit", "Twitter"]
  // },
  // {
  //   name: "Gwendoline	Jansonine",
  //   profilePhoto: "https://media.licdn.com/dms/image/D4E35AQHD49xgwz7M6Q/profile-framedphoto-shrink_800_800/0/1689251276160?e=1692885600&v=beta&t=kb6qBYPzjUkLEGaqjrBJSCUZKIGGyBXHx-WZUS7ggm4",
  //   title: "Regional Vice President, Enterprise & Tech",
  //   city: "Paris",
  //   company:"MongoDB",
  //   previousCompanies: ["Zendesk", "Avaya"]
  // },
  {
    name: "Jimmy Kehoe",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Expert-2.png?alt=media&token=5e8d39c4-c136-4594-8ebf-d3498930a5b6",
    title: "Vice President EMEA\n Sales Development",
    city: "Dublin",
    company: "Okta",
    previousCompanies: ["Oracle", "DataPac"]
  },
  {
    name: "Heidi Rhodes",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_5.jpeg?alt=media&token=555fa018-b5fb-490e-8b4e-6c7f20a0fa7a&_gl=1*qfnu4j*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjM0ODQuNTUuMC4w",
    title: "Head of Global Sales\n& Customer Success",
    city: "San Francisco",
    company: "Meta",
    previousCompanies: ["Cisco"]
  }
  ,
  {
    name: "Noel Coloe",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_6.jpeg?alt=media&token=ca7a612e-be79-4ba0-a6f3-9cc9707c6805&_gl=1*jofesj*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjM1MzEuOC4wLjA.",
    title: "Area Vice President\nSales UKI",
    city: "London",
    company: "Salesforce",
    previousCompanies: ["Mulesoft", "Oracle"]
  }
  ,
  {
    name: "Ronald Huber",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_7.jpeg?alt=media&token=0717f1f6-b939-437b-9033-ef84d53107c8&_gl=1*5ocpp*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjM2MDcuNTYuMC4w",
    title: "Vice President Sales\nEMEA",
    city: "Amsterdam",
    company: "Apptio",
    previousCompanies: ["Workday", "CA"]
  },
  {
    name: "Sabine	Buhrer",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_8.jpeg?alt=media&token=8e9d362c-409a-4fe7-90e7-5ffe2a1f7914&_gl=1*1mtxe8i*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjM2NjkuNTYuMC4w",
    title: "Regional Vice President\n& Country Lead",
    city: "Zurich",
    company: "Tableau",
    previousCompanies: ["Gartner"]
  },
  {
    name: "Joanna Tourne",
    profilePhoto: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Favatar_9.jpeg?alt=media&token=94a71a10-48ff-4504-90d3-9e9b93461820&_gl=1*zaibu7*_ga*OTk4ODk3OTkwLjE2OTI5NDIyMTQ.*_ga_CW55HF8NVT*MTY5ODE2MzI2MC41Mi4xLjE2OTgxNjM3MDIuMjMuMC4w",
    title: "Senior Recruiter\nSales",
    city: "London",
    company: "GitLab",
    previousCompanies: ["Twilio"]
  }
];

export const WHY_CANDID = [
  {
    title: "Expert validation",
    icon: <IoIosCheckmarkCircle fontSize={48} color="#52b68a" />,
    description: "A trusted external opinion at any step of the process. Giving you peace of mind for those important hiring decisions."
  },
  {
    title: "De-risk bad hiring",
    icon: <AiFillWarning fontSize={48} color="#52b68a" />,
    description: "Sales hiring is one of the most important aspects of business growth, but one of the most costly if done wrong. The cost of a bad hire is rarely measured but costs a business around £132,000 for a mid-level hire (3x base salary) according to the Recruitment & Employment Confederation."
  },
  {
    title: "Accelerate Hiring",
    icon: <BsFillFastForwardFill fontSize={48} color="#52b68a" />,
    description: "When new or junior managers experience 'recruitment fatigue' or 'decision paralysis', or if you're concerned about missing out on top talent due to hiring managers being too busy, our experts will conduct interviews within 72 hours of your request."
  },
  {
    title: "Unbiased recruiting",
    icon: <BsFillSuitHeartFill fontSize={48} color="#52b68a" />,
    description: "Our sales leaders and influencers are paid regardless of the hiring outcome to ensure they always provide an independent and honest opinion. DE&I interview best practice is followed."
  },
];

export const FEATURES = [
  {
    color: "$white",
    title: "Access top industry sales leaders & influencers",
    description: "Our sales leaders have a track record of hiring and cultivating high performing sales teams at the world's best companies. Candid will match you with a sales leader that fits your open role, company culture and hiring needs. This provides you with a valuable second opinion on your preferred applicants so you can continue the hiring process with confidence.",
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Feature-One.png?alt=media&token=1021ae46-4267-4f85-964c-3edb8006e03d"
  },
  {
    color: "#e5fbf1",
    title: "Your applicants get live video assessed by your chosen sales leader",
    description: "Your preferred applicants will get evaluated on a range of factors including communication & rapport, sales competency, suitability for the role and any specific concerns you highlight. Our process is designed to follow sales interview best practice.",
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Feature-2.png?alt=media&token=b8cb7a5a-2151-43f3-aa91-86b68b7fa7ff"
  },
  {
    color: "$white",
    title: "Receive a dynamic report with actionable AI insights",
    description: "Receive a detailed interview scorecard within 24 hours and a 'recommend to hire' score you can confidently rely on. Additionally, delve deeper by reviewing a transcribed summary of the interview and interact with 'Avi' our artificial video intelligence to ask questions & uncover specific insights about the candidate.",
    component: <ReportFeaturePreview />,
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Feature-Three.png?alt=media&token=b946c788-0eeb-48b5-80aa-859cebe4f81f"
  },
  {
    color: "#e5fbf1",
    title: "Integrate seamlessly into your existing ATS",
    description: "Push preferred applicants from Greenhouse and other major ATS platforms directly into Candid and seamlessly receive your interview scorecards back into your ATS.",
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Feature-Four.png?alt=media&token=ca0bc3ff-c6bd-4dff-bac3-da0aa5f3e035"
  }
];

export const qaPairs = [
  {
    "question": "Did the candidate mention using any specific sales techniques or strategies in their previous roles?",
    "answer": "The candidate highlighted their use of the SPIN selling technique, leveraging an effective way of questioning customers to uncover implied and explicit needs so they could later go on to tailor a proposal."
  },
  {
    "question": "Were there any notable industries or markets the candidate has experience selling in?",
    "answer": "The candidate has experience selling both B2B and B2C, with a focus on B2B SaaS products and enterprise solutions. They have a solid track record in Marketing Technology."
  },
  {
    "question": "What technologies or sales tools did the candidate mention using to streamline their sales processes?",
    "answer": "The candidate is familiar with a number of CRM software including Salesforce, Hubspot and Zendesk and sales analytics tools to track customer interactions and identify trends, which have enhanced their sales forecasting accuracy."
  },
  {
    "question": "Can you highlight any specific examples the candidate gave regarding successful deals they closed?",
    "answer": "They provided an example of a complex deal selling in a Customer Data Platform licence to American Express. This involved negotiation with multiple stakeholders, resulting in a successful contract worth $1.2 million over a 2 year period."
  },
  {
    "question": "Can you provide a summary of the candidate's approach to handling objections or rejections from potential clients?",
    "answer": "The candidate adopts a consultative approach to objections, addressing concerns empathetically and providing relevant case studies or testimonials to alleviate doubts."
  }
];