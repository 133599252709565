import { Divider, Text } from "@nextui-org/react"

const DividerWithText = ({text}: {text: string}) => {

  return (
    <Divider>
      <Text css={{fontWeight: "$medium",fontSize:"0.8rem", color:"$gray700"}}>
        {text}
      </Text>
    </Divider>
  )
}

export default DividerWithText;