import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Badge, Button, Card, Col, Divider, Spacer, Text } from "@nextui-org/react"
import { FaLinkedin } from "react-icons/fa";
import ReactCardFlip from 'react-card-flip';
import { ExpertProfile } from "types";
import { styles } from "./styles";
import ExpandText from "components/shared/expand-text";

interface ExpertProfileCardProps {
  profile: ExpertProfile;
  selected: boolean;
}

const ExpertProfileCard = ({ profile, selected }: ExpertProfileCardProps) => {
  const [flip, setFlip] = useState(false);
  const ref = useRef<HTMLDivElement>(null)

  const { questionnaire } = profile;

  const questions = [
    {
      title: "Hired / Managed at these levels",
      value: questionnaire.managedSalesRoles,
    },
    {
      title: "Sales Expertise",
      value: questionnaire.salesRolesExpertise,
    },
    {
      title: "Industry Expertise",
      value: questionnaire.industries,
    },
    {
      title: "Regional Expertise",
      value: questionnaire.regionsExperience,
    },
  ]

  useEffect(() => {
    ref?.current?.scrollIntoView()
  }, [flip])

  const profileFront = useMemo(() => {
    return (
      <Card.Body css={styles.imageContainer} ref={ref}>
        <div style={{ position: "absolute", right: 10, top: 0, }}>
          <Badge color="primary" size={"sm"} css={{ bgColor: "$warning" }}>
            {profile.credits} Credits
          </Badge>
        </div>
        <Avatar
          zoomed
          css={{ width: 140, height: 140 }}
          src={profile.profilePhotoUrl} />
        <Col css={{ textAlign: "center" }}>
          <Spacer y={0.75} />
          <Text css={styles.title}>
            {`${profile.firstName} ${profile.lastName}`}{" "}
            {profile.linkedin && (
              <FaLinkedin
                style={{ cursor: "pointer", fontSize: 32 }}
                onClick={() => window.open(profile.linkedin)} />
            )}
          </Text>
          <ExpandText text={profile.headline} limit={70} />
          <Spacer y={0.5} />
          {questionnaire.roles.length > 0 && (
            <Text css={{ fontSize: "$sm" }}>
              Previously:<br /> {questionnaire?.roles?.map(r => r.company).join(", ")}
            </Text>
          )}
        </Col>
      </Card.Body>
    )
  }, [profile, flip, setFlip]);

  const profileBack = useMemo(() => {
    return (
      <Card.Body>
        {questionnaire.roles.length > 0 && (
          <>
            <Text weight="medium" css={{ fontSize: 20 }}>
              Experience
            </Text>
            <Spacer y={0.5} />
            {questionnaire.roles.map(role => (
              <Fragment key={role.company}>
                <Text css={{ fontSize: 14, lineHeight: 1.4 }}>
                  <b>{role.title}</b><br />{role.company}<br /><span style={{ opacity: 0.7, fontSize: 12 }}>{`${role.startYear} - ${role.endYear}`}</span>
                </Text>
                <Text css={{ fontSize: 12, marginTop: 7.5 }}>
                  {role.proudestAchievement}
                </Text>
                <Spacer y={0.5} />
                <Divider />
                <Spacer y={0.5} />
              </Fragment>
            ))}
          </>
        )}
        <Spacer y={0.5} />
        {questions.filter(q => q.value != null).map(({ title, value }) =>
          <Fragment key={`question-${title}`}>
            <Text css={{ fontSize: 14 }} color="primary">
              <b>{title}</b>
            </Text>
            <Text css={{ fontSize: 12 }}>
              {value?.join(", ")}
            </Text>
            <Spacer y={1} />
          </Fragment>
        )}
      </Card.Body>
    )
  }, [questions])

  return (
    <Card
      className="expert-card"
      css={{ h: 400, w: 320, border: selected ? "4.5px solid #3eb68a" : "none" }}
      isHoverable
      variant="shadow">
      <Card.Body css={{pb: 0}}>
        <ReactCardFlip
          isFlipped={flip}
          flipDirection="horizontal"
          containerStyle={{ overflowY: "scroll" }}>
          {profileFront}
          {profileBack}
        </ReactCardFlip>
      </Card.Body>
      <Card.Footer css={{ p: "$5" }}>
        <Button
          css={{ width: "100%" }}
          size="sm"
          onClick={() => setFlip(prev => !prev)}>
          View Expert
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default ExpertProfileCard;