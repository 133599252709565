import { Dropdown, Avatar, Navbar, Text } from "@nextui-org/react";
import { useAuth } from "hooks/useAuth";
import { useCompany } from "hooks/useCompany";
import { useExpert } from "hooks/useExpert";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

const UserMenuDropdown = () => {
  const { signOut, user } = useAuth();
  const { profile: companyProfile, availableCredits } = useCompany();
  const { profile: expertProfile } = useExpert();

  const history = useHistory();

  const { displayName, profilePhotoUrl } = useMemo(() => {
    const name = companyProfile?.name || `${expertProfile?.firstName} ${expertProfile?.lastName}` ;
    const photoUrl = companyProfile?.logoUrl || expertProfile?.profilePhotoUrl;
    return { displayName: name, profilePhotoUrl: photoUrl };
  }, [companyProfile, expertProfile]);

  const dropdownItems = useMemo(() => {
    if (companyProfile) {
      return (
        <Dropdown.Item key="purchase_credits" withDivider css={{ height: "$18" }}>
          <Text color="inherit" css={{ d: "flex" }}>
            {availableCredits} Credits Available
          </Text>
          <Text b>
            Purchase Credits
          </Text>
        </Dropdown.Item>
      )
    }
    return (
      <Dropdown.Item withDivider key="payments_account">
        {expertProfile?.connectedAccount ? "Manage Payment Account" : "Setup Payments Account"}
      </Dropdown.Item>
    )
  }, [companyProfile, availableCredits]);

  const onDropdownClick = async ({ actionKey }: any) => {
    switch (actionKey) {
      case "help_and_feedback":
        return;
      case "logout":
        await signOut();
        return;
      case "purchase_credits":
        history.push('/account/credits');
        return;
      case "payments_account":
        history.push('/account/payments');
        return;
      default:
        break;
    }
  };

  return (
    <Dropdown placement="bottom-right">
      <Navbar.Item>
        <Dropdown.Trigger>
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <Text css={{ pr: "$4", color: "#898989" }} hideIn={"xs"}>
              {displayName}
            </Text>
            <Avatar
              bordered
              as="button"
              size="md"
              src={profilePhotoUrl}
              text={displayName?.slice(0, 1)}
            />
          </div>
        </Dropdown.Trigger>
      </Navbar.Item>
      <Dropdown.Menu
        aria-label="User menu actions"
        color="primary"
        onAction={(actionKey) => onDropdownClick({ actionKey })}>
        <Dropdown.Item key="profile" css={{ height: "auto" }}>
          <Text b color="inherit" css={{ d: "flex" }}>
            Signed in as
          </Text>
          <Text color="inherit" css={{ d: "flex" }}>
            {user?.email}
          </Text>
        </Dropdown.Item>
        {dropdownItems}
        <Dropdown.Item key="logout" color="error" withDivider>
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserMenuDropdown;
