import { useEffect, useMemo, useState } from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { Loading, Progress, Spacer, Text } from "@nextui-org/react";
import { Caption } from "components/shared/styled"
import OnboardingSuccessScreen from "./success";
import FormStepperActions from "components/forms/stepper-actions"

interface BaseOnboardingFormProps {
  onSubmit: (values: any, { setSubmitting, validateForm, setFieldValue }: FormikHelpers<any>) => void;
  refreshData: () => void;
  initialValues: FormikValues;
  validationSchema: any;
  loading: boolean;
  steps: {
    component: JSX.Element;
    title: string;
    description?: string;
  }[]
}

const BaseOnboardingForm = ({
  onSubmit,
  refreshData,
  initialValues,
  validationSchema,
  steps,
  loading,
}: BaseOnboardingFormProps) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    refreshData();
  }, []);

  const stepContent = useMemo(() => {
    const { component, title, description } = steps[step];
    return (
      <>
        <Text h2 css={{ "@smMax": { fontSize: 32 }, "@xsMax": { fontSize: 26 } }}>
          {title}
        </Text>
        <Caption>
          {description}
        </Caption>
        <Spacer y={1} />
        {component}
      </>
    )
  }, [step, steps]);

  if (loading) return <Loading type="spinner" />;

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <Progress
            min={0}
            max={steps.length}
            value={step}
            css={{ position: "absolute", top: 30, "@xsMax": { width: "90%" } }} />
          <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", position: "relative", maxWidth:"100%" }}>
            <Spacer y={3} />
            {stepContent}
            <Spacer y={1} />
            <FormStepperActions
              step={step}
              setStep={setStep}
              STEPS={steps}
              handleSaveDraft={onSubmit} />
          </div>
        </>
      )}
    </Formik>
  )
}

export default BaseOnboardingForm;