import { useEffect, useState } from 'react';
import moment from 'moment';
import { BiLinkExternal } from 'react-icons/bi';
import { useAPI } from 'hooks/useApi';
import { Table } from '@nextui-org/react';
import { displayMoney } from 'utils';
import { StyledBadge } from 'components/shared/styled-badge';

interface Payment {
  id: string;
  created: number;
  amount: number;
  currency: string;
  status: "pending" | "failed" | "succeeded"
  receipt_url: string;
}

const PaymentHistory = () => {
  const api = useAPI();
  const [payments, setPayments] = useState<Payment[]>([]);

  const fetchPaymentHistory = async () => {
    try {
      const { data } = await api.getPaymentHistory();
      setPayments(data);
    } catch (error) {
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  return (
    <Table
      aria-label="Payment history table"
      shadow={false}
      compact
      css={{
        pl: 0,
        height: 'auto',
        minWidth: '100%',
      }}
    >
      <Table.Header>
        <Table.Column>DATE</Table.Column>
        <Table.Column>AMOUNT</Table.Column>
        <Table.Column>STATUS</Table.Column>
        <Table.Column>{''}</Table.Column>
      </Table.Header>
      <Table.Body>
        {payments.map((payment) => (
          <Table.Row key={payment.id}>
            <Table.Cell css={{fontSize: 12}}>{moment.unix(payment.created).calendar()}</Table.Cell>
            <Table.Cell css={{fontSize: 12}}>{displayMoney(payment.amount, payment.currency)}</Table.Cell>
            <Table.Cell>
              <StyledBadge type={payment.status}>{payment.status}</StyledBadge>
            </Table.Cell>
            <Table.Cell>
              <BiLinkExternal
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(payment.receipt_url, '_blank')}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default PaymentHistory;
