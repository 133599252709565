import { Spacer } from "@nextui-org/react";
import { QUESTION_MAP } from "components/forms/fields/score-card-questions";
import { Caption2 } from "components/shared/styled";
import { useInterview } from "hooks/useInterview";
import { Fragment, useMemo } from "react";
import { Rating } from "react-custom-rating-component";

const CandidateScoreCard = () => {
  const { interview } = useInterview();

  const { report } = interview;

  const scoreKeys = useMemo(() => {
    return Object.keys(report.scoreCard).filter(key => key !== "id")
  }, [report.scoreCard])
  return (
    <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
      {scoreKeys.map((key) => (
        <Fragment key={key}>
          <div>
            <Caption2>
              {QUESTION_MAP[key]}
            </Caption2>
            <Spacer y={0.2} />
            <Rating
              count={5}
              defaultValue={report.scoreCard[key] || 0} // Use the stored rating or default to 0
              precision={0.5}
              readOnly
              size='24px'
              spacing='10px'
              activeColor='gold' />
          </div>
          <Spacer y={1} />
        </Fragment>
      ))}
    </div>
  )
}

export default CandidateScoreCard;