import { useAPI } from "hooks/useApi";
import { FunctionComponent, createContext, useEffect, useState } from "react";
import { Loading } from "screens/loading";

export interface CompanyContext {
  profile: any;
  availableCredits: number;
  interviews: any[];
  refresh: () => Promise<void>
}

interface CompanyProfile {
  id: string;
  name: string;
  logoUrl: string;
  contactName: string;
  contactTitle: string;
  website: string;
  questionnaire: any;
}

export const companyContext = createContext<CompanyContext>({
  profile: null,
  availableCredits: 0,
  interviews: new Array<any>(),
  refresh: async() => void 0,
});

export const CompanyProvider: FunctionComponent = ({ children }) => {

  const api = useAPI();

  const [interviews, setInterviews] = useState([]);
  const [profile, setProfile] = useState<CompanyProfile>();
  const [availableCredits, setAvailableCredits] = useState(0);

  const [loading, setLoading] = useState(true);

  const refresh = async() => {
    setLoading(true);
    const { data} = await api.getMyCompany();
    setProfile({
      id: data.id,
      name: data.name,
      logoUrl: data.logoUrl,
      contactName: data.contactName,
      contactTitle: data.contactTitle,
      website: data.website, 
      questionnaire: data.questionnaire,
    });
    setAvailableCredits(data.credits);
    setInterviews(data?.interviewRequests);
    setLoading(false);
  }

  useEffect(() => {
    refresh();
  }, [ ]);

  if(loading) return <Loading/>

  return (
    <companyContext.Provider value={{
      profile,
      interviews,
      availableCredits,
      refresh,
    }}>
      {children}
    </companyContext.Provider>
  );
};
