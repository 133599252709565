import { Container } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { InterviewProvider } from "contexts/interview";
import { useAPI } from "hooks/useApi";
import { CompanyInterviewReport } from "screens/report";
import { Loading } from "screens/loading";

const ShareableInterviewReport = () => {
  const api = useAPI();
  const shareId = useParams<{ shareId: string }>().shareId;

  const [interview, setInterview] = useState(null);

  const fetchInterviewReport = async () => {
    const { data } = await api.getInterviewReportFromShareLink(shareId);
    setInterview(data);
  }
  
  useEffect(() => {
    fetchInterviewReport();
  }, []);

  if (!interview) return <Loading />;
  return (
    <InterviewProvider defaultInterview={interview}>
      <Container>
        <CompanyInterviewReport external/>
      </Container>
    </InterviewProvider>
  )
}

export default ShareableInterviewReport;