import { Text, styled } from "@nextui-org/react";

const Caption = styled(Text, {
  letterSpacing: -0.3,
  lineHeight: 1.4,
  fontSize: 14,
});

const Caption2 = styled(Text, {
  letterSpacing: -0.3,
  whiteSpace: "pre-wrap" ,
  opacity: 0.8, 
  fontSize: "$md", 
  lineHeight: 1.6 
})
const Header = styled(Text, {
  fontWeight:"bold"
});

export { Caption,  Caption2, Header}