import { Spacer } from "@nextui-org/react"
import Canvas from "components/shared/stripe-gradient/canvas"

interface InterviewLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const InterviewLayout = ({ children }: InterviewLayoutProps) => {

  return (
    <>
      <Canvas />
      <Spacer y={2} />
      {children}
    </>
  )
}

export default InterviewLayout;