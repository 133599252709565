import { useSnackbar } from "notistack";
import { useState } from "react";
import { EmailAuthProvider, reauthenticateWithCredential } from "@firebase/auth";
import { Button, Input, Loading, Modal, Text } from "@nextui-org/react";
import { auth } from "config";
import { mapAuthCodeToMessage } from "helpers";

interface ReauthenticateUserProps {
  open: boolean;
  onComplete: (success: boolean) => void;
}
const ReauthenticateUserModal = ({
  open,
  onComplete
}: ReauthenticateUserProps) => {
  const snackbar = useSnackbar();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const currentUser = auth.currentUser!;
      const credential = EmailAuthProvider.credential(
        currentUser.email!,
        password
      );
      await reauthenticateWithCredential(currentUser, credential);
      onComplete(true);
    } catch (err) {
      snackbar.enqueueSnackbar(JSON.stringify(mapAuthCodeToMessage(err)), { variant:"error" })
      onComplete(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal open={open} onClose={() => onComplete(false)}>
      <Modal.Header css={{ textAlign: "left" }}>
        <Text h5>
          This operation requires you to re-enter your password
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Input
          type={"password"}
          label="Enter your Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password} />
        <Button onClick={onSubmit} disabled={!password || loading}>
          {loading ? <Loading type="spinner" /> : "Reauthenticate"}
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default ReauthenticateUserModal;