import moment from "moment";

const groupTimesByDay = (availableTimes: any) => {
  // Step 1: Parse JSON times into Date objects and group them by date
  const groupedTimes = availableTimes
    .filter((timeString: any) => moment(timeString).isAfter(moment(), 'minute'))
    .map((timeString: any) => moment(timeString))
    .reduce((result: any, dateObj: any) => {
      const dateKey = dateObj.format('YYYY-MM-DD')
      result[dateKey] = result[dateKey] || [];
      result[dateKey].push(dateObj.toISOString());
      return result;
    }, {});

  // Step 2: Sort the keys and return the result
  return Object.keys(groupedTimes)
    .sort()
    .reduce((result: any, key) => {
      result[key] = groupedTimes[key];
      return result;
    }, {});
};

export { groupTimesByDay };