import { INDUSTRIES, REGIONS } from "api/data/company";
import { SALES_ROLES, SALES_TYPES } from "api/data/roles";
import SelectOptionInput from "components/forms/fields/select-option-input";
import TagInput from "components/forms/fields/tag-input";
import AddExpertRoles from "../expert-roles";

export const STEPS = [
  {
    title: "I have hired and/or managed people at the following levels",
    component: <SelectOptionInput id="managedSalesRoles" options={SALES_ROLES} />
  },
  {
    title: "I have expertise in the following",
    component: <SelectOptionInput id="salesRolesExpertise" options={SALES_TYPES} />
  },
  {
    title: "I have expertise in the following industries",
    component: <TagInput id="industries" suggestions={INDUSTRIES.map(i => ({ value: i, label: i }))} />
  },
  {
    title: "What regions do you have experience in?",
    component: <SelectOptionInput id="regionsExperience" options={REGIONS.map(i => ({ display: i, value: i }))} />
  },
  {
    title: "Tell us about your sales leadership experience",
    component: <AddExpertRoles />
  }
]