import { Text } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import { memo, useMemo } from "react";

interface FormikFieldsErrorTextProps {
  fields?: string[];
}

const FormikFieldsErrorText = memo(({ fields }: FormikFieldsErrorTextProps) => {
  const { errors, touched } = useFormikContext<any>();

  const displayText = useMemo(
    () => fields ? fields.map((field) => get(errors, field)).join(" ") : Object.values(errors).map((e, idx) => `(${idx+1}). ${e}`).join(", "),
    [errors, touched]
  );

  return <Text color="red">{displayText}</Text>;
});

export default FormikFieldsErrorText;