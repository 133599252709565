import { useAPI } from "hooks/useApi";
import { FunctionComponent, createContext, useCallback, useEffect, useState } from "react";
import * as qs from "query-string";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Loading } from "screens/loading";

export const paymentContext = createContext({});

export const PaymentProvider: FunctionComponent = ({ children }) => {

  const api = useAPI();
  const snackbar = useSnackbar();
  const history = useHistory();

  const { charge_id: chargeId } = qs.parse(window.location.search);

  const [isLoading, setIsLoading] = useState(false);

  const onWaitForCharge = useCallback(async () => {
    try {
      setIsLoading(true);
      await api.longPollCreditOrder(chargeId as string);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      snackbar.enqueueSnackbar(
        `${err}, please refresh your page and check your Shopify account.`,
        { variant: "error" }
      );
    }

    history.replace({ search: undefined });
  }, []);

 
  useEffect(() => {
    if(chargeId) {
      onWaitForCharge();
    }
  },[chargeId])

  if(isLoading) return <Loading/>
  return (
    <paymentContext.Provider value={{}}>
      {children}
    </paymentContext.Provider>
  );
};
