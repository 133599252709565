import { useState } from 'react';
import { Button, Loading } from '@nextui-org/react';
import { useSnackbar } from 'notistack';
import { useAPI } from 'hooks/useApi';

const ShareReportButton = ({ id }: {id: string}) => {
  const snackbar = useSnackbar();
  const api = useAPI();
  const [loading, setLoading] = useState(false);

  const onClickShare = async () => {
    try {
      setLoading(true);
      const { data: linkId } = await api.getShareableReportLink(id);
      const shareableLink = `${window.location.host}/s/${linkId}`;
      await navigator.clipboard.writeText(shareableLink);
      snackbar.enqueueSnackbar('Link Successfully Copied to Clipboard', { variant: 'success' });
    } catch (error) {
      snackbar.enqueueSnackbar('Error copying link to clipboard', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button auto onClick={onClickShare}>
      Share Report {loading && <Loading type="spinner" color="white" css={{ pl: '$5' }} size="sm" />}
    </Button>
  );
}

export default ShareReportButton;