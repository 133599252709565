import { SupportedCurrencies } from "types";
import { currencyMap } from "utils/currencyMap";

export const SALES_ROLES = [
  {
    value: "SDR",
    display: "SDR"
  },
  {
    value: "BDR",
    display: "BDR"
  },
  {
    value: "BDR Manager",
    display: "BDR Manager"
  },
  {
    value: "Mid-Market AE",
    display: "Mid-Market AE"
  },
  {
    value: "Enterprise AE",
    display: "Enterprise AE"
  },
  {
    value: "Sales Executive",
    display: "Sales Executive"
  },
  {
    value: "BDM",
    display: "BDM"
  },
  {
    value: "Head of Sales",
    display: "Head of Sales"
  },
  {
    value: "Sales Director",
    display: "Sales Director"
  },
  {
    value: "VP Sales",
    display: "VP Sales"
  },
  {
    value: "SVP Sales",
    display: "SVP Sales"
  },
  {
    value: "EVP Sales",
    display: "EVP Sales"
  },
  {
    value: "Pre-Sales",
    display: "Pre-Sales"
  },
  {
    value: "Solutions Consultant",
    display: "Solutions Consultant"
  },
  {
    value: "CRO",
    display: "Chief Revenue Officer"
  },
  {
    value: "COO",
    display: "Chief Operating Officer"
  },
  {
    value: "CEO",
    display: "Chief Executive Officer"
  },
]

export const SALES_TYPES = [
  {
    value: "Client Direct Sales",
    display: "Client Direct Sales"
  },
  {
    value: "Agency Sales",
    display: "Agency Sales",
  },
  {
    value: "SaaS Sales",
    display: "SaaS Sales"
  },
  {
    value: "B2B Sales",
    display: "B2B Sales"
  },
  {
    value: "B2C Sales",
    display: "B2C Sales"
  },
  {
    value: "Channel Sales",
    display: "Channel Sales"
  },
  {
    value: "Mid Market Sales",
    display: "Mid Market Sales"
  },
  {
    value: "Enterprise Sales",
    display: "Enterprise Sales"
  }
]

export const SALARY_RANGES = (currencyId: SupportedCurrencies) => {
  const currency = currencyMap[currencyId];
  return [
    {
      value: "50",
      display: `Up to ${currency}50,000 per year`
    },
    {
      value: "51-75",
      display: `${currency}51,000 - ${currency}75,000 per year`
    },
    {
      value: "76-100",
      display: `${currency}76,000 - ${currency}100,000 per year`
    },
    {
      value: "101-125",
      display: `${currency}101,000 - ${currency}125,000 per year`
    },
    {
      value: "126-150",
      display: `${currency}126,000 - ${currency}150,000 per year`
    },
    {
      value: "151-175",
      display: `${currency}151,000 - ${currency}175,000 per year`
    },
    {
      value: "176-200",
      display: `${currency}176,000 - ${currency}200,000 per year`
    },
    ,
    {
      value: "200",
      display: `Over ${currency}200,000 per year`
    }
  ]
};

export const DEAL_SIZES = (currencyId: SupportedCurrencies) => {
  const currency = currencyMap[currencyId];

  return [
    {
      value: "25",
      display: `Up to ${currency}25,000`,
    },
    {
      value: "26-50",
      display: `${currency}26,000 - ${currency}50,000`
    },
    {
      value: "51-100",
      display: `${currency}51,000 - ${currency}100,000`
    },
    {
      value: "101-300",
      display: `${currency}101,000 - ${currency}300,000`
    },
    {
      value: "301-1M",
      display: `${currency}301,000 - ${currency}1,000,000`
    },
    {
      value: "1M-3M",
      display: `${currency}1,000,000 - ${currency}3,000,000`
    },
    {
      value: "3M-5M",
      display: `${currency}3,000,000 - ${currency}5,000,000`
    },
    {
      value: "5M",
      display: `Over ${currency}5,000,000`
    }
  ]
};

export const SALES_CYCLES = [
  {
    value: "1week",
    display: "Up to 1 week",
  },
  {
    value: "1month",
    display: "1 month",
  },
  {
    value: "3month",
    display: "2 - 3 months",
  },
  {
    value: "6month",
    display: "4 - 6 months",
  },
  {
    value: "12months",
    display: "7 - 12 months",
  },
  {
    value: "18months",
    display: "12 - 18 months",
  },
  {
    value: "19months",
    display: "18+ months",
  }
]

