import { getDownloadURL, getStorage, ref, uploadBytes } from "@firebase/storage";
import { Button, Input, Loading } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { useRef, useState } from "react";
import { BsUpload } from "react-icons/bs";
import { DEFAULT_INPUT_PROPS } from "screens/auth";

interface FormikUploadFileInputProps {
  id: string;
  label: string;
  acceptedFileTypes?: string[];
  uploadBasePath: string;
}

const FormikUploadFileOrTextInput = ({
  id,
  label,
  acceptedFileTypes = ["application/pdf"],
  uploadBasePath
}: FormikUploadFileInputProps) => {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, values, handleChange } = useFormikContext<any>();
  const storage = getStorage();
  const hiddenFileInput = useRef<any>(null);

  const onChange = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) {
      setFieldValue(id, undefined);
      return;
    };
    const refLoc = ref(storage, `${uploadBasePath}/${file.name}`);
    setLoading(true);
    await uploadBytes(
      refLoc,
      file,
      {
        contentType: file.type
      }
    );

    const url = await getDownloadURL(refLoc);
    setLoading(false);
    setFieldValue(id, url);
  }

  const fileUrl = values[id];
  if (fileUrl && values?.readOnly) {
    return (
      <Input
        label={label}
        contentLeft={
          <Button
            size="xs"
            css={{ minWidth: "120px" }}
            onClick={() => window.open(fileUrl, "_blank")}>
            View Uploaded File
          </Button>
        }
        clearable
        onClearClick={() => {
          setFieldValue(id, undefined)
        }}
        value={" "}
        {...DEFAULT_INPUT_PROPS} />
    )
  }
  return (
    <>
      <Input
        id={id}
        value={fileUrl}
        {...DEFAULT_INPUT_PROPS}
        label={label}
        accept={acceptedFileTypes.join(",")}
        placeholder="Add a Link or Upload File"
        contentRight={<Button auto light
        icon={loading ? <Loading size="xs" type="spinner" /> : <BsUpload />}
        onClick={() => hiddenFileInput!.current!.click()} />}
        onChange={handleChange} />
      <input
        type="file"
        onChange={onChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}/>
    </>
  )
}

export default FormikUploadFileOrTextInput;