import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { Card, Grid } from "@nextui-org/react";
import { TABS } from "./tabs";
import { CompanyProfileWrapper } from "components/forms/company-profile";
import Container from "layout/portal-layout/container";
import AccountIntegrations from "./integrations";
import CompanyPaymentCredits from "./credits";
import EditUser from "components/forms/edit-user";
import Tabs from "components/shared/tabs";

const CompanyAccountPage = () => {
  const { tabId } = useParams<{ tabId?: string }>();
  const tab = tabId ?? "profile";

  const tabContent = useMemo(() => {
    switch (tab) {
      case "profile":
        return (
          <Card variant="bordered">
            <Card.Body>
              <CompanyProfileWrapper />
            </Card.Body>
          </Card>
        )
      case "user":
        return <EditUser />
      case "credits":
        return <CompanyPaymentCredits />
      case "integrations":
        return <AccountIntegrations />
    }
  }, [tab]);

  return (
    <Container
      title="Account"
      subtitle="Manage your account and purchase tokens">
      <Grid.Container gap={2} css={{ "@smMax": { padding: 0 } }}>
        <Grid md={2} xs={12} direction="column" css={{ "@smMax": { flexDirection: "row", gap: 15 } }}>
          <Tabs selectedTab={tab} tabs={TABS} />
        </Grid>
        <Grid md={10} xs={12}>
          {tabContent}
        </Grid>
      </Grid.Container>
    </Container>
  );
}

export default CompanyAccountPage;