import { Button, Grid, Spacer, Text } from "@nextui-org/react"
import { useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import { Caption2 } from "components/shared/styled";
import { styles } from "./styles";

interface FeatureSectionProps {
  title: string;
  description: string;
  image?: string;
  component?: JSX.Element;
  color?: string;
  reverse: boolean;
}

const FeatureSection = ({ 
  image,
  title, 
  description, 
  color, 
  reverse, 
  component 
}: FeatureSectionProps) => {
  const history = useHistory();

  const onClickCta = () => history.push("/signup");

  return (
    <Grid.Container 
      gap={4} 
      direction={reverse ? "row" : "row-reverse"}
      css={{ 
        ...styles.container,
        bgColor: color, 
      }}>
      <Grid md={6} sm={6} direction="column" justify="center">
        <Text h1 weight="bold" css={styles.header}>
          {title}
        </Text>
        <Caption2>
          {description}
        </Caption2>
        <Spacer y={1} />
        <div>
          <Button
            flat
            auto
            css={styles.ctaButton}
            iconRight={<BsArrowRight />}
            onClick={onClickCta}>
            Get Started
          </Button>
        </div>
      </Grid>

      <Grid md={6} sm={6}>
        {component || <img src={image} style={{ borderRadius: 15, objectFit:"contain" }} />}
      </Grid>
    </Grid.Container>
  )
}
export default FeatureSection;