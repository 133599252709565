import { Button, Loading } from "@nextui-org/react";
import { useAPI } from "hooks/useApi";
import { useExpert } from "hooks/useExpert";
import { useState, useMemo } from "react";
import { BsStripe } from "react-icons/bs";

const SetupStripeAccountButton = () => {
  const api = useAPI();
  const { profile } = useExpert();

  const [loading, setLoading] = useState(false);

  const isSetup = useMemo(() => profile?.connectedAccount?.enabled, [profile?.connectedAccount]);

  const onClick = async () => {
    setLoading(true);
    if(!isSetup) {
      const {data: setupLink} = await api.setupExpertStripeAccount();
      window.open(setupLink, "_self");
    } else {
      const {data: portalLink} = await api.fetchExpertStripeAccountLink();
      window.open(portalLink, "_blank");
    }
    setLoading(false);
  }

  return (
    <Button
      auto
      css={{ bgColor: "#5433FF" }}
      icon={<BsStripe />}
      onClick={onClick} >
      {!isSetup ? "Setup Stripe Account" : " View Stripe Payouts"}{loading && <Loading type="spinner" color="white" css={{ pl: '$5' }} size="sm" />}
    </Button>
  )
}

export default SetupStripeAccountButton;
