import { useState } from "react";
import { Button, Modal } from "@nextui-org/react";
import { useMediaQuery } from "hooks/useMediaQuery";
import { ExpertInterviewAvailability } from "screens/portal/expert/interview-availability";

const ExpertSetAvailabilityButton = () => {
  const isSmall = useMediaQuery(600);

  const [showModal, setShowModal] = useState(false);

  const onClose = () => setShowModal(false);
  return (
    <>
      <Button auto size="sm" onClick={() => setShowModal(true)}>
        Set Availability & Accept
      </Button>
      <Modal
        open={showModal}
        fullScreen={!isSmall}
        closeButton
        onClose={onClose}>
        <Modal.Body css={{"@xsMax": {p: "$2"}}}>
          <ExpertInterviewAvailability onClose={onClose}/>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ExpertSetAvailabilityButton;