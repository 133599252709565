export const columns = [
  {
    name: "Candidate Name",
    uid: "name",
  },
  {
    name: "Status",
    uid: "status",
  },
  {
    name: "Created",
    uid: "created",
  },
  {
    name: "Actions",
    uid: "actions",
  },
];

export const DISPLAY_STATUS = {
  "draft": {
    display: "Draft",
    color: "$gray400"
  },
  "declined": {
    display: "Declined",
    color: "$error"
  },
  "requested": {
    display: "Requested",
    color: "$warning"
  },
  "expert_accepted": {
    display: "Expert Accepted",
    color: "$primary"
  },
  "candidate_accepted": {
    display: "Candidate Accepted",
    color: "$primary"
  },
  "interview_scheduled": {
    display: "Interview Scheduled",
    color: "$primary"
  },
  "interview_completed": {
    display: "Interview Completed",
    color: "$primary"
  },
  "report_completed": {
    display: "Report Completed",
    color: "#249a6f"
  }
}
