import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { Button, Link, Loading, Spacer, Text } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { useAPI } from "hooks/useApi";
import Carousel from "react-spring-3d-carousel";
import ExpertProfileCard from "components/expert-profile-card";
import { styles } from "./styles";
import { ExpertProfile } from "types";
import { useIntercom } from "react-use-intercom";
import { useMediaQuery } from "hooks/useMediaQuery";

const SuggestedExperts = () => {
  const api = useAPI();


  const { showNewMessage } = useIntercom();
  const carouselRef = useRef<Carousel>(null);

  const { values, setFieldValue, handleSubmit } = useFormikContext<any>();

  const [slide, setSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [experts, setExperts] = useState<ExpertProfile[]>([]);

  const expertCarousel = useMemo(() => {
    return experts.map((expert: ExpertProfile, idx) => 
      ({ 
        key: idx, 
        content: <ExpertProfileCard profile={expert} selected={slide === idx} key={expert.id}/>, 
        onClick: () => {
          setSlide(idx);
        }
      })
    );
  }, [experts, slide]);

  const fetchExperts = async () => {
    setLoading(true);
    const { data } = await api.getRecommendedExperts({
      region: values.roleRegion,
      roleLevel: values.roleLevel,
    });

    // Default to existing expert
    if (values.expertId) {
      const compareById = (a: ExpertProfile, b: ExpertProfile) => (a.id === values.expertId ? -1 : b.id === values.expertId ? 1 : 0);
      setExperts([...data].sort(compareById));
    } else {
      setExperts(data);
    }

    setLoading(false);

    if(data?.length > 0) {
      const defaultExpert = data?.[0];
      setFieldValue("expertCredits", defaultExpert.credits)
      setFieldValue("expertId", defaultExpert.id)
    }
  }

  const onClickCustomSearch = async() => {
    if(values?.readOnly) return;
    setFieldValue("draft", true);
    handleSubmit();
    window.open(`${window.location.origin}/contact?message=I'm having trouble finding the right expert for me. Can you please help me find a better match?`, "_blank");
  }

  useEffect(() => {
    fetchExperts();
  }, []);

  useEffect(() => {
    if (!loading && experts.length > 0) {
      const expert = experts[slide];
      if(!expert) return;
      const { firstName, lastName, credits, id } = expert;
      setFieldValue("expertName", `${firstName} ${lastName}`)
      setFieldValue("expertCredits", credits)
      setFieldValue("expertId", id);
    }
  }, [slide, experts, loading]);

  const isSmall = useMediaQuery(600);

  const carouselProps = isSmall ? {
    offsetRadius: 0
  } : {};
  return (
    <div style={{ height: 520}}>
      <div style={styles.container as CSSProperties}>
        {loading ? (
          <div style={styles.loadingContainer as CSSProperties}>
            <Loading type="spinner" size="lg" />
            <Spacer y={0.5} />
            <Text small>
              Matching In Progress
            </Text>
          </div>
        ) :
          <Carousel
            {...carouselProps}
            slides={expertCarousel}
            showNavigation={false}
            ref={carouselRef}
            goToSlide={slide} />
        }
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          auto
          light
          icon={<BsArrowLeftCircleFill fontSize={28} />}
          onClick={() => setSlide(prev => prev - 1)} />
        <Button
          auto
          light
          icon={<BsArrowRightCircleFill fontSize={28} />}
          onClick={() => setSlide(prev => prev + 1)} />
      </div>

      <Spacer y={0.5} />
      <Text css={{ textAlign: "center", fontSize: 14 }}>
        Don't see what you're looking for?{" "}
        <Link onClick={onClickCustomSearch}>
          Save & get in touch
        </Link>
      </Text>
    </div>
  )
}

export default SuggestedExperts;