import { Spacer, Grid, Text, Divider, Button } from "@nextui-org/react"
import { Caption } from "components/shared/styled"
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";

interface ContainerProps {
  title: string;
  subtitle: string;
  backButton?: boolean;
  secondaryActions?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
}
const Container = ({ title, subtitle, backButton = false, secondaryActions, children }: ContainerProps) => {
  const history = useHistory();
  return (
    <>
      <Spacer y={2} />
      <Grid.Container
        justify="space-between"
        direction="row"
        alignItems="center">
        <div style={{ display: "flex" }}>
          {backButton && (
            <div>
            <Button
              auto
              light
              rounded
              css={{ mr: 15, bgColor: "$gray100"}}
              onClick={() => history.goBack()} >
                <BsArrowLeft />
              </Button>
            </div>
          )}
          <div>
            <Text h3 css={{ lineHeight: 1 }}>
              {title}
            </Text>
            <Caption css={secondaryActions ? { "@smMax": { mb: "$5" } } : {}}>
              {subtitle}
            </Caption>
          </div>
        </div>
        {secondaryActions}
      </Grid.Container>
      <Spacer y={1} />
      <Divider />
      <Spacer y={1} />
      {children}
    </>
  )
}
export default Container;