export const mapAuthCodeToMessage = (authCode: string) => {
  switch (authCode) {
    case "auth/invalid-password":
      return "Password provided is not corrected";
    case "auth/wrong-password":
      return "Password provided is not corrected";

    case "auth/invalid-email":
      return "Email provided is invalid";

    case "auth/email-already-in-use":
      return "Email is already in use";

    case "auth/too-many-requests":
      return "Request blocked for unusual activity. Trying again after some delay would unblock.";

    case "auth/user-not-found":
      return "There is no user record corresponding to this identifier. Sign up and retry";

    default:
      return "An unknown error occured. Please try again";
  }
}