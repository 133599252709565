import { ASSESSMENT_GOALS } from "api/data/assessment";
import CandidateProfileForm from "components/forms/create-interview/candidate-profile";
import RoleInfoForm from "components/forms/create-interview/role-info";
import SelectOptionInput from "components/forms/fields/select-option-input";
import FormikTextAreaInput from "components/forms/fields/text-area-input";
import SuggestedExperts from "components/suggested-experts";

export const STEPS = [
  {
    title: "Enter Candidate Details",
    description: "",
    validateFields: ["name", "email", "linkedinUrl", "resumeUrl"],
    component: <CandidateProfileForm />
  },
  {
    title: "Enter Role Details",
    description: "",
    validateFields: ["roleTitle", "roleLevel", "roleRegion", "roleSalaryRange", "jobDescriptionUrl", "roleDealSize", "roleAvgSalesCycle"],
    component: <RoleInfoForm />
  },
  {
    title: "What would you like the sales leader to look out for?",
    description: "Maximum of three",
    validateFields: ["tags"],
    component: <SelectOptionInput id="tags" options={ASSESSMENT_GOALS.map(g => ({ display: g, value: g }))} selectionMode="multiple" />
  },
  {
    title: "Is there anything else / something more specific you would like the sales leader to ask during the interview / give an opinion on?",
    description: "",
    validateFields: ["description"],
    component: <FormikTextAreaInput id="description" />
  },
  {
    title: "Recommended Matches",
    description: "",
    component: <SuggestedExperts/>
  },
  {
    title: "Any other information you'd like to share with the sales leader?",
    description: "",
    validateFields: ["notes"],
    component: <FormikTextAreaInput id="notes" />
  }
]
