import { useState, useEffect, CSSProperties } from 'react';
import { Button, Loading, Spacer, Text } from '@nextui-org/react';
import { useAPI } from 'hooks/useApi';
import { displayMoney } from 'utils';
import { Caption2 } from 'components/shared/styled';
import { StyledBadge } from 'components/shared/styled-badge';
import { styles } from './styles';

interface CreditPackage {
  id: string;
  currency: string;
  price: number;
  quantity: number;
}

interface CreditPackageCellProps {
  pack: CreditPackage;
  discount: number;
  isSelected: boolean;
  isBestValue: boolean;
  onSelect: (id: string) => void;
}

function calculatePercentageDiscount(originalPrice: number, discountedPrice: number) {
  if (originalPrice > 0) {
    const discount = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return discount;
  } else {
    return 0; // Handle the case where originalPrice is zero or negative to avoid division by zero.
  }
}

const CreditPackageCell = ({
  pack,
  discount,
  isBestValue,
  isSelected,
  onSelect
}: CreditPackageCellProps) => {

  const { id, quantity, price, currency } = pack;
  return (
    <div
      key={id}
      onClick={() => onSelect(id)}
      style={{
        ...styles.creditCellContainer as CSSProperties,
        border: isSelected ? "1px solid #11998e" : "1px solid #f5f5f5",
      }}>
      <div>
        <Text weight="medium" css={{ lineHeight: 1.5, display: "flex" }}>
          {quantity} Credits {isBestValue && <StyledBadge type={"succeeded"} css={{ ml: 5 }}>Best Value</StyledBadge>}
          {discount > 0 && <StyledBadge type={"pending"} css={{ ml: 5 }}>{discount}% Savings</StyledBadge>}
        </Text>
        <Caption2 css={{ fontSize: 12, }}>
          {displayMoney((price / quantity), currency, 2)} a credit
        </Caption2>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text weight="medium">{displayMoney(price, currency, 0)}</Text>
        <Spacer x={0.5} />
      </div>
    </div>
  )
}

const PurchaseCredits = () => {
  const api = useAPI();

  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState<CreditPackage[]>([]);
  const [selectedPackageId, setSelectedPackageId] = useState<string>();

  const fetchCreditPackages = async () => {
    try {
      const { data } = await api.getCreditPackages();
      setPackages(data);
      setSelectedPackageId(data?.[0].id);
    } catch (error) {
      console.error('Error fetching credit packages:', error);
    }
  };

  const onPurchaseProduct = async () => {
    setLoading(true);
    try {
      const { data } = await api.createCheckoutSession(selectedPackageId!);
      window.open(data, '_self');
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCreditPackages();
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer as CSSProperties}>
        <Loading />
      </div>
    );
  }

  const basePackage = packages[packages.length - 1];
  const baseCreditPrice = basePackage?.price / basePackage?.quantity;
  return (
    <>
      {packages.map((pack, idx) => (
        <CreditPackageCell
          key={pack.id}
          pack={pack}
          isBestValue={idx === 2}
          discount={Math.round(calculatePercentageDiscount(baseCreditPrice, pack.price/pack.quantity)) }
          isSelected={selectedPackageId === pack.id}
          onSelect={(id) => setSelectedPackageId(id)} />
      ))}
      <Spacer y={0.5} />
      <Button
        auto
        onClick={onPurchaseProduct.bind(this)}
        disabled={!selectedPackageId}>
        Buy Now
      </Button>
    </>
  );
};

export default PurchaseCredits;