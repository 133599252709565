import { Avatar, Card, Col, Grid, Spacer, Text } from "@nextui-org/react"
import { PublicExpert } from "types";
import { styles } from "./styles";
import { LOGOS } from "api/data/landing";

const ExpertProfileCard = ({
  profilePhoto,
  title,
  company,
  previousCompanies,
  city,
}: PublicExpert) => {

  const renderCompanyLogos = (company: string, previousCompanies: string[]) => {
    const gridSize = previousCompanies.length > 1 ? 4 : 6;
    return (
      <Grid.Container gap={0}>
        {[company, ...previousCompanies].map(company =>
          <Grid md={gridSize} sm={gridSize} xs={gridSize} justify="center" key={company}>
            <img
              height={25}
              width={70}
              style={{ objectFit: "contain" }}
              src={(LOGOS as any)[company]} />
          </Grid>
        )}
      </Grid.Container>
    )
  }
  return (
    <Card
      className="expert-card"
      css={{ h: 350, w: 300 }}
      isHoverable
      variant="bordered">
      <Card.Body css={styles.imageContainer}>
        <Avatar
          zoomed
          css={{ width: 140, height: 140 }}
          src={profilePhoto} />
      </Card.Body>
      <Card.Footer css={styles.textContainer}>
        <Col css={{ textAlign: "center" }}>
          <Spacer y={0.75} />
          <Text css={styles.title}>
            {`${title}`}
          </Text>
          <Text css={{ fontSize: "$sm", fontWeight: "$normal" }}>
            {city}
          </Text>
          <Spacer y={2} />
          <Grid.Container gap={0}>
            {renderCompanyLogos(company, previousCompanies)}
          </Grid.Container>
        </Col>
      </Card.Footer>
    </Card>
  )
}

export default ExpertProfileCard;