import { Fragment, useState } from "react";
import { useFormikContext } from "formik";
import { FaPlus } from "react-icons/fa";
import { Button, Row, Spacer, Text } from "@nextui-org/react";
import { Caption } from "components/shared/styled";
import { ExpertRegistration } from "types/forms";
import AddRoleModal from "components/modals/add-role";

const AddExpertRoles = () => {

  const [showModal, setShowModal] = useState(true);
  const { setFieldValue, values } = useFormikContext<ExpertRegistration>();

  return (
    <div>
      <Button
        auto
        disabled={values.roles.length > 2}
        light
        onClick={() => setShowModal(true)}
        icon={<FaPlus />}>
        Add Role (maximum 3 roles)
      </Button>
      <Spacer y={1} />
      {values.roles.map(role => (
        <Fragment key={role.company}>
          <Row justify="space-between" css={{ p: "$5", border: "1px solid #979797", borderRadius: 10, mt: "$5" }}>
            <div>
              <Text weight={"medium"}>
                {role.title}
              </Text>
              <Caption>
                {role.company} ({role.startYear} - {role.endYear})
              </Caption>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                auto
                light
                color={"error"}
                onClick={() => setFieldValue("roles", values.roles.filter(r => r !== role))}>
                Delete
              </Button>
            </div>
          </Row>
          <Spacer y={0.5} />
        </Fragment>
      ))}
      <AddRoleModal
        onAdd={(role) => {
          setFieldValue("roles", [...values.roles, role]);
          setShowModal(false);
        }}
        open={showModal}
        onClose={() => setShowModal(false)} />
    </div>

  )
}

export default AddExpertRoles;