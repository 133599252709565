import { Dropdown, Grid, Input, Spacer } from "@nextui-org/react";
import { INDUSTRIES, REGIONS } from "api/data/company";
import { SALES_ROLES, SALES_TYPES } from "api/data/roles";
import ExpertProfile from "components/forms/expert/expert-profile";
import AddExpertRoles from "components/forms/expert/expert-roles";
import DropdownSelectInput from "components/forms/fields/dropdown-select-input";
import SelectOptionInput from "components/forms/fields/select-option-input";
import TagInput from "components/forms/fields/tag-input";
import SelectExpertTier from "components/forms/select-expert-tier";
import { useFormikContext } from "formik";
import { Tag } from "react-tag-autocomplete";
import { ExperienceRole } from "types";
import { ExpertRegistration, OnboardingStatus } from "types/forms";
import { currencyMap, displayCurrencies } from "utils/currencyMap";

export const FORM_DEFAULT_VALUES = {
  type: OnboardingStatus.DRAFT,
  tier: "",
  hourlyRateCurrency: "USD",
  firstName: "",
  lastName: "",
  headline: "",
  linkedin: "",
  profilePhotoUrl: "",
  roles: [] as ExperienceRole[],
  managedSalesRoles: new Array<string>(),
  salesRolesExpertise: new Array<string>(),
  industries: new Array<Tag>(),
  regionsExperience: new Array<string>(),
}

const HourlyRateInput = () => {
  const { values, handleChange, touched, errors } = useFormikContext<ExpertRegistration>();

  return (
    <div style={{ display: "flex" }}>
      <DropdownSelectInput
        id="hourlyRateCurrency"
        label=""
        placeholder="Select Currency"
        selectionMode="single"
        data={displayCurrencies} />
      <Input
        css={{ ml:"$2" }}
        size="lg"
        fullWidth
        id="hourlyRate"
        name="hourlyRate"
        type="number"
        placeholder="Hourly rate"
        value={values.hourlyRate}
        onChange={handleChange}
        helperText={touched?.hourlyRate && errors?.hourlyRate ? errors.hourlyRate : ""}
        helperColor={"error"} />
    </div>
  )
}

export const STEPS = [
  {
    title: "What type of sales expert are you?",
    description: "",
    component: <SelectExpertTier />
  },
  {
    title: "I have hired and/or managed people at the following levels",
    component: <SelectOptionInput id="managedSalesRoles" options={SALES_ROLES} />
  },
  {
    title: "I have expertise in the following",
    component: <SelectOptionInput id="salesRolesExpertise" options={SALES_TYPES} />
  },
  {
    title: "I have expertise in the following industries",
    component: <TagInput id="industries" suggestions={INDUSTRIES.map(i => ({ value: i, label: i }))} />
  },
  {
    title: "What regions do you have experience in?",
    component: <SelectOptionInput id="regionsExperience" options={REGIONS.map(i => ({ display: i, value: i }))} />
  },
  {
    title: "What hourly rate will you be seeking if you're approved as an expert on Candid?",
    description: "",
    component: <HourlyRateInput />
  },
  {
    title: "Let's build your profile",
    description: "",
    component: <ExpertProfile />
  },
  {
    title: "Tell us about your sales leadership experience",
    description: "Highlight your current/previous role(s) at leading companies and your proudest achievements.",
    component: <AddExpertRoles />
  },
]
