import { Card, Divider, Grid, Spacer, Text, Textarea, User } from "@nextui-org/react";
import { useInterview } from "hooks/useInterview";
import CandidateScoreProgress from "../candidate-score-progress";
import CandidateScoreCard from "../candidate-score-card";

const ExpertOpinion = () => {
  const { interview } = useInterview();

  const { report } = interview;
  return (
    <Card>
      <Card.Body>
        <Grid.Container gap={3} direction="row-reverse">
          <Grid md={5} xs={12} direction="column">
            <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
              <CandidateScoreProgress />
            </div>
            <Spacer y={2} />
            <CandidateScoreCard />
          </Grid>
          <Grid md={7} xs={12} direction="column">
            <Textarea
              fullWidth
              readOnly
              bordered
              size="lg"
              label="Feedback from Expert"
              value={report?.feedback} />
            <Spacer y={1} />
            <Textarea
              fullWidth
              readOnly
              bordered
              size="lg"
              label="Red Flags from Expert"
              value={report?.concerns} />
            <Spacer y={1} />
            <Divider />
            <Spacer y={1} />
            <Text weight="medium">
              About the Expert
            </Text>
            <Spacer y={0.5} />
            <User
              size="lg"
              name={`${interview?.expert?.firstName} ${interview?.expert?.lastName}'s`}
              description={interview?.expert?.headline}
              src={interview?.expert?.profilePhotoUrl} />
          </Grid>
        </Grid.Container>
      </Card.Body>
    </Card>
  )
}

export default ExpertOpinion;