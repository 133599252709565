import { Button, Card, Checkbox, Grid, Input, Spacer } from "@nextui-org/react";
import { Formik, FormikHelpers } from "formik";
import CompanySearchInput from "components/forms/fields/company-search";
import { ROLE_VALIDATION_SCHEMA } from "config/validation.schema";

interface AddRoleModal {
  open: boolean;
  onClose: () => void;
  onAdd: (role: Role) => void;
}

interface Role {
  title: string;
  company: string;
  proudestAchievement: string;
  startYear: string;
  endYear: string;
}

const AddRoleModal = ({ open, onClose, onAdd }: AddRoleModal) => {

  const onSubmit = (values: Role, { resetForm }: FormikHelpers<any>) => {
    onAdd(values);
    resetForm();
  }

  if (!open) return <></>
  return (
    <Card css={{ mb: 0 }}>
      <Card.Body css={{ pb: 0 }}>
        <Formik
          initialValues={{
            title: "",
            company: "",
            proudestAchievement: "",
            startYear: "",
            endYear: "",
          }}
          onSubmit={onSubmit}
          validationSchema={ROLE_VALIDATION_SCHEMA}
        >
          {({ handleChange, handleSubmit, values, touched, errors, setFieldValue }) => (
            <Grid.Container gap={1.5}>
              <Grid md={12} sm={12} xs={12}>
                <Input
                  id="title"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  fullWidth
                  placeholder="Enter Title"
                  helperText={touched?.title && errors?.title ? errors.title : ""}
                  helperColor={"error"} />
              </Grid>

              <Grid lg={12} md={12} sm={12} xs={12} css={{ width: "100%" }}>
                <CompanySearchInput
                  onChange={(value) => setFieldValue("company", value?.name)} />
              </Grid>
              <Grid md={12} sm={12} xs={12}>
                <Input
                  id="proudestAchievement"
                  name="proudestAchievement"
                  onChange={handleChange}
                  value={values.proudestAchievement}
                  fullWidth
                  placeholder="Enter Proudest Achievement"
                  helperText={touched?.proudestAchievement && errors?.proudestAchievement ? errors.proudestAchievement : ""}
                  helperColor={"error"} />
              </Grid>
              <Grid md={6} sm={6} xs={6} direction="column">
                <Input
                  id="startYear"
                  name="startYear"
                  value={values.startYear}
                  onChange={handleChange}
                  fullWidth
                  placeholder="Start Year"
                  helperText={touched?.startYear && errors?.startYear ? errors.startYear : ""}
                  helperColor={"error"} />
                <Checkbox css={{ display: "none" }} />
              </Grid>
              <Grid md={6} sm={6} xs={6} direction="column">
                <Input
                  id="endYear"
                  name="endYear"
                  value={values.endYear}
                  onChange={handleChange}
                  fullWidth
                  placeholder="End Year"
                  helperText={touched?.endYear && errors?.endYear ? errors.endYear : ""}
                  helperColor={"error"} />
                <Spacer y={0.5} />
                <Checkbox size="xs" onChange={(selected) => setFieldValue("endYear", selected ? "Current" : "")}>
                  I currently work here
                </Checkbox>
              </Grid>

              <Grid md={12} justify="flex-end">
                <Button
                  size={"sm"}
                  auto onClick={() => handleSubmit()}>
                  Add
                </Button>
              </Grid>
            </Grid.Container>
          )}
        </Formik>
      </Card.Body>
    </Card>
  )
}

export default AddRoleModal;