import React, { useRef, useEffect } from "react";
import { Gradient } from "./stripegradient";

const Canvas = (props) => {
	const canvasRef2 = useRef(null);
	const gradient = new Gradient();

	useEffect(() => {
		gradient.initGradient("#gradient-canvas");
	}, []);

	return (
		<canvas
      className="canvas"
			id="gradient-canvas"
			ref={canvasRef2}
			{...props}
			data-transition-in
		/>
	);
};

export default Canvas;