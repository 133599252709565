import { CompanyRegistration, ExpertRegistration } from "types/forms";

const mapExpertQuestionnaireToInitialValues = (questionnaire: any): Partial<ExpertRegistration> => ({
  industries: questionnaire?.industries.map((i: any) => ({label: i, value: i})) || [],
  managedSalesRoles: questionnaire?.managedSalesRoles || [],
  salesRolesExpertise: questionnaire?.salesRolesExpertise || [],
  regionsExperience:  questionnaire?.regionsExperience || [],
  roles: questionnaire?.roles || [],
});

const mapExpertProfileToInitialValues = (profile: any): Partial<ExpertRegistration> => ({
  tier: profile?.tier || "",
  firstName: profile?.firstName || "",
  lastName: profile?.lastName || "",
  headline: profile?.headline || "",
  linkedin: profile?.linkedin || "",
  profilePhotoUrl: profile?.profilePhotoUrl || "",
  hourlyRate: profile?.hourlyRate || 0
});

const mapCompanyProfileToInitialValues = (profile: any): Partial<CompanyRegistration> => ({
  contactName: profile?.contactName || "",
  contactTitle: profile?.contactTitle || "",
  logoUrl: profile?.logoUrl || "",
  name: profile?.name || "",
  website: profile?.website || "",
});

const mapCompanyQuestionnaireToInitialValues = (questionnaire: any): Partial<CompanyRegistration> => ({
  atsProvider: questionnaire?.atsProvider || "",
  industry: questionnaire?.industry ? [{ label: questionnaire?.industry, value: questionnaire?.industry }] : [],
  interviewStages: questionnaire?.interviewStages || [],
  preferredValueAdd: questionnaire?.preferredValueAdd || [],
  salesHiringGoal: questionnaire?.salesHiringGoal || "",
  salesOrgSize: questionnaire?.salesOrgSize || "",
  salesRoleHiringType: questionnaire?.salesRoleHiringType || [],
});

export {
  mapExpertProfileToInitialValues,
  mapExpertQuestionnaireToInitialValues,
  mapCompanyProfileToInitialValues,
  mapCompanyQuestionnaireToInitialValues
}