import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import {
  Button,
  Input,
  Loading,
  Spacer,
  Text,
  Divider,
} from "@nextui-org/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { signInWithEmailAndPassword } from "@firebase/auth";
import * as qs from "query-string";

import { auth } from "config";
import { LOGIN_VALIDATION_SCHEMA } from "config/validation.schema";
import { DEFAULT_INPUT_PROPS } from "screens/auth";
import { Caption } from "components/shared/styled";
import LinkedinAuthButton from "components/auth/linkedin-auth-button";
import DividerWithText from "components/shared/divider-with-text";
import { mapAuthCodeToMessage } from "helpers";

interface LoginFormValues {
  email: string;
  password: string;
}


const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const snackbar = useSnackbar();
  const {redirectTo } = qs.parse(window.location.search);

  const onSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>
  ) => {
    setSubmitting(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
  
      if(redirectTo) {
        history.push(redirectTo as any);

      } else {
        history.push('/')
      }
    } catch (err) {
      snackbar.enqueueSnackbar(mapAuthCodeToMessage((err as any).code), { variant: "error" });
    }
    setSubmitting(false);
  };

  return (
    <>
      <Text h2 weight="medium">
        Log In
      </Text>
      <Caption css={{color:"$gray700"}}>
        Enter your email and password
      </Caption>
      <Spacer y={1.5} />
    
      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={LOGIN_VALIDATION_SCHEMA}
        onSubmit={onSubmit}>
        {({
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => (
          <>
            <Input
              {...DEFAULT_INPUT_PROPS}
              label="Email"
              id="email"
              name="email"
              placeholder="Enter your email"
              helperText={touched.email && errors.email ? errors.email : ""}
              onChange={handleChange} />
            <Spacer y={1} />
            <Input
              {...DEFAULT_INPUT_PROPS}
              label="Password"
              id="password"
              name="password"
              placeholder="Enter your password"
              type="password"
              helperText={touched.password && errors.password ? errors.password : ""}
              onChange={handleChange} />
            <Text css={{ mt: "$5", textAlign: "left", fontSize: 14 }}>
              <Link to={`/forgot-password`}>
                Forgot password?
              </Link>
            </Text>
            <Spacer y={1} />
            <Button
              css={{ w: "100%" }}
              type="submit"
              color="secondary"
              size="lg"
              disabled={isSubmitting}
              rounded
              onClick={() => handleSubmit()}>
              {isSubmitting ? (
                <Loading type="spinner" color="currentColor" size="sm" />
              ) : (
                "Log In"
              )}
            </Button>
          </>
        )}
      </Formik>

      <Spacer y={1.5} />
      <DividerWithText text="OR"/>
      <Spacer y={1.5} />
      <LinkedinAuthButton/>
      <Spacer y={1}/>

      <Text css={{ textAlign: "center", mt: "$5", letterSpacing: -0.5, fontSize: "0.95rem" }}>
        {"Don't have an account? "}
        <Link to={"/signup"}>Sign Up</Link>
      </Text>
    </>
  );
};

export default Login;