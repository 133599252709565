import { Container, Spacer, Text } from "@nextui-org/react";
import LandingFooter from "components/landing/footer";
import LandingNavBar from "components/landing/navbar";

const PrivacyPolicy = () => {

  return (
    <>
    <LandingNavBar/>
    <Container lg>
      <Spacer y={2}/>
      <Text h2 weight={"bold"}>
        Privacy Policy
      </Text>
      <p>&nbsp;</p>
      <h2><strong>Introduction</strong></h2>
      <p><span style={{fontWeight: 400}}>Candid&rsquo;s website and online services are designed to protect the privacy of all users. We ensure that the data supplied to us is processed fairly and lawfully, and with skill and care. This Data Protection Policy governs our processing of all personal data provided to us, in all forms. By registering your details with us and using our website, you consent to us collecting and processing personal data supplied by you and disclosing this information to our clients and partners (prospective hiring companies and industry experts and influencers) in connection with the interview, assessment and recruitment process.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>This Privacy Notice provides you with clear information as to how we process your personal data. We are obliged to use your information in line with all applicable laws. We do so in a manner that is fair by using your information in a way that you would reasonably expect when providing our interview, assessment and recruitment services, and being transparent so that you know how it will be used.</span></p>
      <p>&nbsp;</p>
      <p><span style={{fontWeight: 400}}>This website and our interview, assessment and recruitment process solutions are not intended for children and we do not knowingly collect data relating to children.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>This Privacy Notice sets out: the types of personal data that we collect about you; how and why we use it; how long we will keep it for; when, why and with whom we will share it; the legal basis for us using your personal data; and your rights in relation to us storing and processing your personal data. It also explains how we may contact you and how you can contact us.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>Part of the interview, assessment and recruitment process will involve sharing personal data with our client partners (independent data controllers) who have potential opportunities that may be suitable for you. These third parties will have their own privacy policies and will also need to comply with all applicable laws. We encourage you to contact them directly if you have any concerns or questions about your data.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Who we are&nbsp;</strong></h2>
      <p><span style={{fontWeight: 400}}>Candid is an interview, assessment and recruitment process technology solution and is a trading name of AgencyDiagonal Ltd (11758441) with a registered address at 85 Great Portland Street, London W1W 7LT. We match employers with experts via our technology who carry out external assessments on candidates who have applied for a role or recommended to the employer.&nbsp;</span></p>
      <p>&nbsp;</p>
      <h2><strong>Legal basis for processing your data&nbsp;</strong></h2>
      <p><span style={{fontWeight: 400}}></span></p>
      <p><span style={{fontWeight: 400}}>For hiring companies, industry experts, candidates and clients, our processing is necessary for our legitimate interests in that we need the information in order to be able to assess suitability for potential roles and identify relevant expertise.&nbsp;&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>We do not consider our legitimate interests to be overridden by your interests or fundamental rights and freedoms. We take into consideration the reasonable expectations of individuals who are actively applying for jobs or making their personal information publicly accessible on professional networking sites.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>For candidates submitted or requested for interview, then this may involve the processing of more detailed personal data including sensitive data such as health information or any unspent criminal convictions that you or others provide about you. We will ask for consent before undertaking such processing if we are required to do so.&nbsp; For employer clients, it may also be necessary for us to process data in order to perform a contract for you if one is in place, for example in contacting you.</span></p>
      <p>&nbsp;</p>
      <h2><strong>What information will we collect about you?&nbsp;</strong></h2>
      <p><span style={{fontWeight: 400}}>We collect and process personal data only as far as is necessary to facilitate the interview, assessment and recruitment process. We need personal data in order to match candidates to experts and experts to employers and vice versa to find suitable matching opportunities and further information may be needed to assess the suitability of these matches through the different stages of the recruitment process.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>The information we collect may include name, email address, telephone number, CV, identification and right to work documents, educational records, work history, employment and references.&nbsp; In general, we will not ask about sensitive personal data but there may be times when it is necessary for information to be disclosed to us and for us to share it with potential employers. We will only share the information where explicit consent has been provided.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Where does the information we collect about you come from?</strong></h2>
      <p><span style={{fontWeight: 400}}>The data we collect about you is obtained from the following sources:</span></p>
      <p><span style={{fontWeight: 400}}></span></p>
      <ul style={{listStyle:"circle"}}>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Directly from you. This may be via our registration process, information requests</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>From employers where candidates have applied for a role</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Publicly available sources. We may also obtain your information from professional networking sites or job boards, where you have actively published or submitted your information.</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Reference or word of mouth. You may be recommended by a friend, former employer or colleague.</span></li>
      </ul>
      <p>&nbsp;</p>
      <h2><strong>How will we use the information collected about you?</strong></h2>
      <p><span style={{fontWeight: 400}}>We will use your information in such ways to facilitate the interviewing, assessment and recruitment process, including but not limited to:</span></p>
      <p><span style={{fontWeight: 400}}></span></p>
      <ul style={{listStyle:"circle"}}>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Using candidate details to match skills, experience and education with industry experts and influencers so that a reliable interview assessment can be made regarding candidate suitability to a role (we use technology in ways to help identify the most suitable matches but this does not constitute automated decision making);</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Making candidates aware of current opportunities that may suit their requirements;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Contacting and updating candidates about assessment you will undertake as part of the interview process&nbsp; made or roles you have been submitted for;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Sharing your details with industry experts with the aim of securing a potential role (our client may be the company you will provide your services to or an intermediary that supports the interview process).</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>We may also analyse the data internally so that we can offer a more relevant service, for marketing and strategic development purposes or for research purposes to improve our service.</span></li>
      </ul>
      <p>&nbsp;</p>
      <h2><strong>How and when will we contact you?</strong></h2>
      <p><span style={{fontWeight: 400}}>We may contact you by phone, email, video call or social media. Some examples of when we will contact you are:</span></p>
      <p><span style={{fontWeight: 400}}></span></p>
      <ul style={{listStyle:"circle"}}>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Upon receipt of an instruction to assess a candidate from an employer whom a candidate has applied to for a role.&nbsp;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>When reviewing a candidates CV or personal data to determine whether we are likely to be able to provide an expert assessment, we will notify candidates if they are added to our database.</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Before we recommend candidates for assessment</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>During the assessment, usually over video call&nbsp;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>In relation to any correspondence we receive from you.</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To update you on any material changes to our policies and practices.</span></li>
      </ul>
      <p>&nbsp;</p>
      <h2><strong>Who will we share your information with?</strong></h2>
      <p><span style={{fontWeight: 400}}>In order to provide interview, assessment and recruitment process services, it is necessary for us to share your personal data of candidates and experts with each other and with our clients (independent data controllers) who have potential work opportunities that may be suitable for candidates. Once your data has been shared with them, they will determine how they use, store and process your data and will have their own privacy policy.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>There may also be circumstances where disclosure is required or permitted by law (such as to government bodies and law enforcement bodies). As an example, we are under an obligation to report pay details of all workers we place with clients to HMRC.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>Generally, we will process your information within Candid. There may be occasions where we use third parties to process your information on our behalf. In such situations these third parties will be under strict instructions and they will not be permitted to use your information for their own business purposes.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Will we transfer your data outside the EEA?</strong></h2>
      <p><span style={{fontWeight: 400}}>Candid is based in the UK and our back-up systems are stored in the EEA. We do not generally transfer your data out of the EEA.&nbsp; In the event that we do need to make transfers to countries outside the EEA, we will ensure the appropriate safeguards are in place.</span></p>
      <p>&nbsp;</p>
      <h2><strong>For how long will we keep your personal information?</strong></h2>
      <p><span style={{fontWeight: 400}}>Candid will only retain information for as long as necessary for the relevant activity. This may be determined by legislation or a decision as to what we consider necessary for the business based on a number of factors:</span></p>
      <p><span style={{fontWeight: 400}}></span></p>
      <ul style={{listStyle:"circle"}}>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>If you have not registered with us, your data will be retained for 12 months from when it was added to our database or from when you were last contacted. You can request to have your record deleted (see below).</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>If you do register with us, your data will be retained for 3 years from the last contact or activity on your record. You can request to have your record deleted (see below).</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>If we place you in a temporary assignment or permanent role we will be required to keep certain information for specific lengths of time up to a maximum of 6 years. The purpose of keeping this information is to comply with our legal obligations.</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Client contact details are retained for 3 years. You can request to have your record deleted (see below).&nbsp;</span></li>
      </ul>
      <h3><strong></strong></h3>
      <h2><strong>Will we process special categories of personal data and criminal convictions?</strong></h2>
      <p><span style={{fontWeight: 400}}>We avoid processing special categories of data (these are sensitive personal data that reveal racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership) and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health, or data concerning a natural person&rsquo;s sex life or sexual orientation. However, by taking copies of passports or ID it may be that racial or ethnic origin can be revealed. Processing this data is necessary for us to comply with employment law obligations and the data is only used for this specific purpose.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>We are under an obligation to ensure that the candidates we submit to clients are suitable. As part of our registration process we may require individuals to disclose any unspent convictions and may need to make further enquiries if necessary.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Will we contact you for marketing purposes?</strong></h2>
      <p><span style={{fontWeight: 400}}>We will only send you direct marketing emails that promote our company or services if you have opted in to this. You will have the option to opt in when you are provided with a copy of our Privacy Notice. You can also manage your direct marketing preferences by contacting Candid, updating your preferences or clicking the unsubscribe link on an email.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>To clarify, contacting you about specific interview, assessment and recruitment process related opportunities is not marketing because we are not advertising or marketing our services.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Can you refuse to provide your data to us, or request us to delete your data?</strong></h2>
      <p><span style={{fontWeight: 400}}>If you do not provide the personal data requested, or use your right to withdraw your consent for the processing of your sensitive personal data, we may not be able to match you with available experts for assessment.&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>You have the right to object to us processing your data based on legitimate interest. If you have not been placed then we will be able to delete your data. You will have the option to delete your data when you are provided with a copy of our Privacy Notice by following the link on the transparency notice email or you can do so by emailing hello@candidexpert.ai</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>In the event we are directly involved in successfully placing you then we will be under certain legal obligations to retain information for specified periods (up to 6 years). This includes, but is not limited to, ensuring you have the right to work in the UK and allowing us to comply with HMRC reporting requirements regarding payroll information. In such situations you do not have the right to object to us processing your data.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>You do also have the right to restrict processing which means that we will stop further use of your data but still store it. However, if we stop processing your data, it will mean that any assignment you may currently be on will be terminated immediately or in line with any notice period.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>Please note that we may require you to provide us with proof of your identity and answer security questions before processing your request and if your data is deleted we will have no record and you may be contacted again in the future.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Can you find out about what data we hold about you?</strong></h2>
      <p><span style={{fontWeight: 400}}>You have the right to be informed about, and access a copy of, the information comprised in your personal data. If any of the data we hold is inaccurate, you have the right to rectify it. You can contact us at hello@candidexpert.ai or your Candid representative directly to update your data.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>You can make a request to find out what personal data we hold about you. You may exercise this right by making a written subject access request (SAR) to hello@candidexpert.ai. We require you to provide us with proof of your identity and answer security questions before processing your request.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>We usually act on such requests and provide information free of charge, but may charge a reasonable fee to cover our administrative costs of providing the information for baseless or excessive/repeated requests, or further copies of the same information.</span></p>
      <p><span style={{fontWeight: 400}}>&nbsp;</span></p>
      <p><span style={{fontWeight: 400}}>Alternatively, we may be entitled to refuse to act on the request. Please consider your request responsibly before submitting it. We will respond as soon as we can. Generally, this will be within one month from when we receive your request but, if the request is going to take longer to deal with, we will let you know.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Do you have the right to data portability?</strong></h2>
      <p><span style={{fontWeight: 400}}>The GDPR introduces a new right to data portability. This will enable individuals to both receive and transmit the personal data they have provided to a data controller in a structured, commonly used and machine-readable format to another data controller.&nbsp; The right to data portability only applies to the following:</span></p>
      <p><span style={{fontWeight: 400}}></span></p>
      <ul style={{listStyle:"circle"}}>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personal data provided by the individual;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personal data that is processed with the individual&rsquo;s consent or on the basis of a contract (excluding the other legal bases);</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>When processing is carried out through automated means.</span></li>
      </ul>
      <p><span style={{fontWeight: 400}}></span></p>
      <p><span style={{fontWeight: 400}}>As we rely on the legal basis of legitimate interests, this right will not apply to the data we hold about you. In addition, references obtained directly from third parties about you will not be subject to the right of data portability.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Cookies and external websites</strong></h2>
      <p><span style={{fontWeight: 400}}>To provide better service to you on our website, we use cookies to collect your personal data when you browse. See our cookie policy on our website here for more details.&nbsp; Some external links appear on our website, to websites owned and operated by third parties. These websites have their own privacy policies and we encourage you to review them. We accept no responsibility or liability for the privacy practices of such third parties and you use them at your own risk.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Changes to this privacy notice</strong></h2>
      <p><span style={{fontWeight: 400}}>We reserve the right to change our Privacy Notice at any time. Changes to our Privacy Notice will be displayed on our website.</span></p>
      <p>&nbsp;</p>
      <h2><strong>Complaints</strong></h2>
      <p><span style={{fontWeight: 400}}>If you have any issues or concerns then we ask that in the first instance you set this out in writing and send this to hello@candidexpert.ai. If we are unable to satisfactorily deal with your complaint you can complain to the ICO which is the UK supervisory authority. You have the right to claim compensation for damages caused by a breach of data protection legislation</span></p>
      <p>&nbsp;</p>
    </Container>
    <LandingFooter/>
    </>
  )
}

export default PrivacyPolicy;