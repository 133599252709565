import {
  Redirect,
  Route,
} from "react-router-dom";

import Login from "./login";
import SignUp from "./signup";
import ForgotPassword from "./forgot-password";
import AuthLayout from "layout/auth-layout";

export const DEFAULT_INPUT_PROPS = {
  clearable: true,
  fullWidth: true,
  size: 'lg',
} as any;

const AuthViews = () => {
  return (
    <AuthLayout>
      <Route exact path={'/login'} component={Login} />
      <Route exact path={'/signup'} component={SignUp} />
      <Route exact path={'/forgot-password'} component={ForgotPassword} />
    </AuthLayout>
  );
};

export default AuthViews;
