import { Button, Card, Divider, Spacer, Text, Textarea } from "@nextui-org/react";
import { Formik } from "formik";

import { useInterview } from "hooks/useInterview";
import { ASSESSMENT_GOALS } from "api/data/assessment";
import { Caption2 } from "components/shared/styled";
import RoleInfoForm from "components/forms/create-interview/role-info";
import SelectOptionInput from "components/forms/fields/select-option-input";

const ExpertInterviewRequestDetails = () => {
  const { interview } = useInterview();

  return (
    <Card>
      <Card.Body>
        <Formik
          onSubmit={() => void 0}
          initialValues={{
            expertId: interview?.expert?.id || '',
            name: interview?.candidate?.name || '',
            email: interview?.candidate?.email || '',
            linkedinUrl: interview?.candidate?.linkedinUrl || '',
            resumeUrl: interview?.candidate?.resumeUrl || '',
            jobDescriptionUrl: interview?.role?.jobDescriptionUrl || '',
            roleTitle: interview?.role?.title || '',
            roleLevel: interview?.role?.level || '',
            roleSalaryRange: interview?.role?.salaryRange || '',
            roleRegion: interview?.role?.location || '',
            roleDealSize: interview?.role?.averageDealSize || '',
            roleAvgSalesCycle: interview?.role?.averageSalesCycle || '',
            roleCurrency: interview?.role?.currency || '',
            tags: interview?.tags || [],
            description: interview?.description || '',
            notes: interview?.notes || '',
            readOnly: true,
          }}
        >
          {({ values }) => (
            <>
              <Text css={{ display: 'flex', flex: 1, justifyContent: 'space-between', "@xsMax": { flexDirection: "column", alignItems: "center", textAlign: "center" } }}>
                <div>
                  <Text h3 css={{ lineHeight: 1, mb: 0 }}>
                    {interview?.candidate?.name}
                  </Text>
                  <Caption2>
                    {interview?.candidate?.email}
                  </Caption2>
                </div>
                <Button.Group auto size="sm" light>
                  {values.linkedinUrl && <Button onClick={() => window.open(values.linkedinUrl, "_blank")}>View Linkedin</Button>}
                  {values.resumeUrl && <Button onClick={() => window.open(values.resumeUrl, "_blank")}>View Resume</Button>}
                </Button.Group>
              </Text>
              <Spacer y={1} />
              <Divider />
              <Spacer y={1} />
              <RoleInfoForm />
              <Spacer y={1} />
              <Divider />
              <Spacer y={1} />
              <Text css={{ fontSize: '$md', ml: '$3', letterSpacing: -0.2 }}>
                What would you like the sales leader to look out for?
              </Text>
              <SelectOptionInput
                id="tags"
                options={ASSESSMENT_GOALS.map(g => ({ display: g, value: g }))}
                selectionMode="multiple"
              />
              <Spacer y={1} />
              <Textarea
                label="Is there anything else / something more specific you would like the sales leader to ask during the interview / give an opinion on?"
                readOnly
                fullWidth
                size="lg"
                value={interview?.description}
              />
              <Spacer y={1} />
              <Textarea
                size="lg"
                label="Any other information you'd like to share with the sales leader?"
                readOnly
                fullWidth
                value={interview?.notes}
              />
              <Spacer y={1} />
            </>
          )}
        </Formik>
        <Divider />
      </Card.Body>
    </Card>
  );
};

export default ExpertInterviewRequestDetails;