import { useState } from "react"
import { Button, Grid, Input, Loading, Spacer } from "@nextui-org/react"
import { Formik, FormikHelpers, useFormikContext } from "formik"
import ImageUploader from "../../fields/image-uploader"

import { ExpertRegistration, OnboardingStatus } from "types/forms"
import { DEFAULT_INPUT_PROPS } from "screens/auth"
import { GRID_FULL_WIDTH } from "config/grid"
import { useAPI } from "hooks/useApi"
import { useExpert } from "hooks/useExpert"
import EditExpertQuestionnaire from "../edit-questionnaire"
import { mapExpertQuestionnaireToInitialValues, mapExpertProfileToInitialValues } from "utils/forms"

export const ExpertProfileWrapper = () => {
  const api = useAPI();
  const { profile, refresh } = useExpert();

  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const onSubmit = async (values: ExpertRegistration, { setSubmitting }: FormikHelpers<ExpertRegistration>) => {
    setSubmitting(true);
    await api.createExpertProfile({
      ...values,
      industries: values.industries.map(i => i.value),
      type: OnboardingStatus.COMPLETE,
    });
    await refresh()
    setSubmitting(false);
  }

  return (
    <>
      <Formik
        initialValues={{
          ...mapExpertProfileToInitialValues(profile),
          ...mapExpertQuestionnaireToInitialValues(profile?.questionnaire),
        } as any}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}>
        {({
          isSubmitting,
          isValid,
          handleSubmit
        }) => (
          <div>
            <ExpertProfile />
            <Spacer y={1} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button light onClick={() => setShowQuestionnaire(true)}>
                Update Questionnaire
              </Button>
              <Button
                auto
                rounded
                disabled={isSubmitting || !isValid}
                onClick={() => handleSubmit()}>
                {isSubmitting ? <Loading type="spinner" /> : "Save"}
              </Button>
            </div>
          </div>
        )}
      </Formik>
      <EditExpertQuestionnaire
        open={showQuestionnaire}
        onClose={() => setShowQuestionnaire(false)} />
    </>
  )
}

const ExpertProfile = () => {
  const { setFieldValue, handleChange, values, touched, errors } = useFormikContext<ExpertRegistration>();

  return (
    <>
      <Grid.Container gap={2}>
        <Grid {...GRID_FULL_WIDTH} css={{ justifyContent: "center" }}>
          <ImageUploader
            label="Upload File"
            savePath="experts/images"
            defaultFileUrl={values.profilePhotoUrl}
            onChange={(media) => setFieldValue("profilePhotoUrl", media)} />
        </Grid>
        <Grid.Container gap={2}>
          <Grid xl={6} md={6} sm={6} xs={12}>
            <Input
              {...DEFAULT_INPUT_PROPS}
              id="firstName"
              name="firstName"
              label="First Name"
              placeholder=""
              onChange={handleChange}
              value={values.firstName}
              helperText={touched?.firstName && errors?.firstName ? errors.firstName : ""}
              helperColor={"error"} />

          </Grid>
          <Grid xl={6} md={6} sm={6} xs={12}>
            <Input
              {...DEFAULT_INPUT_PROPS}
              id="lastName"
              name="lastName"
              label="Last Name"
              placeholder=""
              onChange={handleChange}
              value={values.lastName}
              helperText={touched?.lastName && errors?.lastName ? errors.lastName : ""}
              helperColor={"error"} />

          </Grid>
        </Grid.Container>
        <Grid {...GRID_FULL_WIDTH}>
          <Input
            {...DEFAULT_INPUT_PROPS}
            id="headline"
            name="headline"
            label="Headline"
            placeholder="Ex-Sales Leader for Fortune 500"
            onChange={handleChange}
            value={values.headline}
            helperText={touched?.headline && errors?.headline ? errors.headline : ""}
            helperColor={"error"} />
        </Grid>
        <Grid {...GRID_FULL_WIDTH}>
          <Input
            {...DEFAULT_INPUT_PROPS}
            id="linkedin"
            name="linkedin"
            label="Linkedin"
            placeholder="https://linkedin.com/in/john-doe"
            onChange={handleChange}
            value={values.linkedin}
            helperText={touched?.linkedin && errors?.linkedin ? errors.linkedin : ""}
            helperColor={"error"} />
        </Grid>
      </Grid.Container>

    </>
  )
}
export default ExpertProfile;