import { Key } from "react";
import { Dropdown, Text } from "@nextui-org/react";
import { useFormikContext } from "formik";

interface DropdownSelectInputProps {
  id: string;
  label: string;
  placeholder: string;
  selectionMode: "single" | "multiple";
  data: { value: string, display: string }[];
}
const DropdownSelectInput = ({
  id,
  label,
  placeholder,
  data,
  selectionMode,
}: DropdownSelectInputProps) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();
  const onSelectionChange = (keys: any) => {
    if(values?.readOnly) return;
    const keyArr = Array.from(keys as Set<Key>);
    setFieldValue(id, selectionMode === "single" ? keyArr?.[0] : keyArr)
  };

  const selectedKeys = values[id] || [];

  const selectedDisplayValues = data
    .filter(d => selectionMode === "single" ? selectedKeys === d.value : selectedKeys.includes(d.value))
    .map(d => d.display)
    .join(", ");

  return (
    <>
      <Text css={{ ml: 5 }}>
        {label}
      </Text>
      <Dropdown>
        <Dropdown.Button 
          disabled={values?.readOnly}
          flat css={{ tt: "capitalize", bgColor: "#F1F3F5", color: "#999", }} size="lg">
          {selectedDisplayValues || placeholder}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          css={{ $$dropdownMenuWidth: "100%" }}
          selectionMode={selectionMode}
          disallowEmptySelection
          selectedKeys={(values as any)[id]}
          onSelectionChange={onSelectionChange}
        >
          {data.map(val => (
            <Dropdown.Item
              key={val.value}>
              {val.display}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Text small color="error" css={{mt:"$5"}}>
        {touched[id] && errors[id]}
      </Text>
    </>
  )
}

export default DropdownSelectInput;