import { CSS } from "@nextui-org/react";

export const styles: { [key: string]: CSS } = {
  iconContainer: {
    minWidth: 30,
    width: 30,
    height: 30,
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "center"
  },
  stepContainer: { 
    display: "flex", 
    flexDirection: "row" 
  },
  stepHeaderContainer: { 
    display: "flex",
    alignItems: "center", 
    flexDirection: "column" 
  },
  progressBar: { 
    mt: 5, 
    mb: 5, 
    height: 50, 
    width: 10 
  }
}