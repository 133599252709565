import { Text } from "@nextui-org/react";
import { Link } from "react-router-dom";

export const TABS = [
  {
    id: "profile",
    title: "Profile",
  },
  {
    id: "user",
    title: "User",
  },
  {
    id: "integrations",
    title: "Integrations",
  },
  {
    id: "credits",
    title: "Credits",
  },
];

const AccountTabs = ({ tabs = TABS, selectedTab }: { tabs?: {id: string, title: string}[], selectedTab: string }) => {

  return (
    <>
      {tabs.map((tab) => (
        <Text
          h4
          color="primary"
          weight={tab.id === selectedTab ? "bold" : "medium"}
          css={{
            pt: "$5",
            whiteSpace: "nowrap",
          }}
          key={tab.id}
        >
          <Link to={`/account/${tab.id}`}>{tab.title}</Link>
        </Text>
      ))}
    </>
  );
};

export default AccountTabs;
