import * as qs from "query-string";
import { Route, Switch, useHistory } from "react-router-dom"
import CompanyAccountPage from "./account"
import CreateInterview from "./create-interview-request"
import InterviewPage from "./interview"
import PortalLayout from "layout/portal-layout"
import CompanyHome from "./home"
import CompanyInterviewReport from "screens/report"
import ShareableInterviewReport from "screens/report/shareable-interview-report"
import { RouteWrapper } from "screens/route-wrapper"
import { useEffect } from "react"
import ContactPage from "screens/contact";
import AuthLayout from "layout/auth-layout";

export const CompanyPortalViews = () => {
  const history = useHistory();

  const { redirectTo } = qs.parse(window.location.search);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo as any);
    }
  }, []);


  return (
    <Switch>
      <RouteWrapper
        exact
        path={"/account/:tabId?"}
        component={CompanyAccountPage}
        layout={PortalLayout}
      />
      <RouteWrapper
        exact
        path="/request-interview"
        component={CreateInterview}
        layout={PortalLayout} />
      <RouteWrapper
        exact
        path="/interview/:interviewId"
        component={InterviewPage}
        layout={PortalLayout} />
      <RouteWrapper
        exact
        path="/interview/:interviewId/report"
        component={CompanyInterviewReport}
        layout={PortalLayout} />
      
      <Route 
        exact path={'/contact'} 
        component={ContactPage}/>

      <Route 
        exact 
        path="/s/:shareId" 
        component={ShareableInterviewReport}/>


      <RouteWrapper
        exact
        path="/*"
        component={CompanyHome}
        layout={PortalLayout} />

    </Switch>
  )
}