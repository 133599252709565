import { FunctionComponent, createContext, useEffect, useState } from "react";
import { useAPI } from "hooks/useApi";
import { Loading } from "screens/loading";
import { ExpertProfile } from "types";

export interface ExpertContext {
  profile: ExpertProfile;
  refresh: () => Promise<void>
}

export const expertContext = createContext<ExpertContext>({
  profile: {} as any,
  refresh: async() => void 0,
});

export const ExpertProvider: FunctionComponent = ({ children }) => {
  const api = useAPI();

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<ExpertProfile>();

  const refresh = async() => {
    setLoading(true);
    const { data} = await api.getMyExpertProfile();
    setProfile(data);
    setLoading(false);
  }

  useEffect(() => {
    refresh();
  }, [ ]);

  if(loading || !profile) return <Loading/>
  return (
    <expertContext.Provider value={{
      profile,
      refresh,
    }}>
      {children}
    </expertContext.Provider>
  );
};
