import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  container: { 
    p: "$15", 
    minHeight: "70vh", 
    borderRadius: 30, 
    justifyContent: "center",
    "@smMax": {
      p: "$5 !important"
    },
  },
  header: { 
    lineHeight: 1.3, 
    letterSpacing: -1.5, 
    "@smMax": {fontSize: "$4xl !important"} 
  },
  ctaButton: { 
    bgColor: "#bfefda", 
    color: "$green800" 
  }
}