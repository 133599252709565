import { useState } from "react";
import { FormikHelpers } from "formik"
import { useSnackbar } from "notistack";

import { ExpertRegistration, OnboardingStatus } from "types/forms";
import { useAPI } from "hooks/useApi";
import { EXPERT_PROFILE_VALIDATION_SCHEMA } from "config/validation.schema";
import { FORM_DEFAULT_VALUES, STEPS } from "./steps";
import BaseOnboardingForm from "../base-onboarding-form";
import { useAuth } from "hooks/useAuth";
import { mapExpertProfileToInitialValues, mapExpertQuestionnaireToInitialValues } from "utils/forms";

const ExpertOnboardingForm = () => {
  const api = useAPI();
  const snackbar = useSnackbar();
  const { refreshProfiles } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<ExpertRegistration>(FORM_DEFAULT_VALUES as ExpertRegistration);

  const onSubmit = async (values: ExpertRegistration, { setSubmitting, validateForm, setFieldValue }: FormikHelpers<ExpertRegistration>) => {
    const isDraft = values.type === OnboardingStatus.DRAFT;
    setSubmitting(true);
    if (!isDraft) {
      const hasErrors = await validateForm(values);
      if (Object.keys(hasErrors).length > 0) {
        await setFieldValue("type", OnboardingStatus.DRAFT)
        return;
      }
    }
    try {
      await api.createExpertProfile({
        ...values,
        industries: values.industries.map(i => i.value),
      });
      const successMessage = isDraft ? "Your progress has been saved" : "Your account has been created.";
      snackbar.enqueueSnackbar(`Success! ${successMessage}`, { variant: "success" });
      await refresh();
    } catch (err) {
      snackbar.enqueueSnackbar("An error occurred, please try again later", { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  }

  const refresh = async () => {
    setLoading(true);
    try {
      const { data: profile } = await api.getMyExpertProfile();
      if (!profile) {
        setLoading(false);
        return;
      }

      if (profile.completed) {
        await refreshProfiles();
        setLoading(false);
        return;
      }

      const questionnaire = profile?.questionnaire;
      const newValues: ExpertRegistration = {
        ...initialValues,
        type: OnboardingStatus.DRAFT,
        ...mapExpertProfileToInitialValues(profile),
        ...mapExpertQuestionnaireToInitialValues(questionnaire),
      };
      setInitialValues(newValues);
    } catch (err) {
      // snackbar.enqueueSnackbar("An error occurred while fetching data", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseOnboardingForm
      steps={STEPS}
      onSubmit={onSubmit}
      refreshData={refresh}
      loading={loading}
      validationSchema={EXPERT_PROFILE_VALIDATION_SCHEMA}
      initialValues={initialValues}/>
  )
}


export default ExpertOnboardingForm;