import { Fragment } from "react";
import { useSnackbar } from "notistack";
import { Button, Container, Divider, Loading, Modal, Spacer, Text } from "@nextui-org/react";
import { Formik, FormikHelpers } from "formik";
import { useAPI } from "hooks/useApi";
import { useExpert } from "hooks/useExpert";
import { ExpertRegistration } from "types/forms";
import { mapExpertQuestionnaireToInitialValues } from "utils/forms";
import { STEPS } from "./steps";
import { EXPERT_EDIT_QUESTIONNAIRE_VALIDATION_SCHEMA } from "config/validation.schema";

interface EditExpertQuestionnaire {
  open: boolean;
  onClose: () => void;
}
const EditExpertQuestionnaire = ({open, onClose}: EditExpertQuestionnaire) => {
  const api = useAPI();
  const snackbar = useSnackbar();

  const { profile, refresh } = useExpert();

  const onSubmit = async (values: ExpertRegistration, { setSubmitting }: FormikHelpers<ExpertRegistration>) => {
    try {
      setSubmitting(true);
      await api.updateExpertProfileQuestionnaire({
        ...values,
        industries: values.industries.map(i => i.value),
      });
      await refresh();
    } catch (err) {
      onClose();
      snackbar.enqueueSnackbar("An unknown error occured. Please try again later.", { variant:"error" })
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal 
      open={open} 
      onClose={onClose} 
      fullScreen 
      closeButton>
      <Modal.Header>
        <Text h5>
          Edit Profile Questionnaire
        </Text>
      </Modal.Header>
      <Divider />
      <Formik
        initialValues={mapExpertQuestionnaireToInitialValues(profile?.questionnaire) as any}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
        validationSchema={EXPERT_EDIT_QUESTIONNAIRE_VALIDATION_SCHEMA}>
        {({
          isSubmitting,
          handleSubmit
        }) => (
          <>
            <Modal.Body css={{"@xsMax": {pl: 0, pr: 0}}}>
              <Container sm>
                <Spacer y={1} />
                {STEPS.map((section, idx) => (
                  <Fragment key={`step_${idx}`}>
                    <Text h4>{section.title}</Text>
                    <Spacer y={0.5} />
                    {section.component}
                    <Spacer y={1.5} />
                  </Fragment>
                ))}
              </Container>
            </Modal.Body>
            <Divider />
            <Modal.Footer>
              <Button 
                auto 
                disabled={isSubmitting}
                onClick={() => handleSubmit()}>
                {isSubmitting ? <Loading type="spinner"/> : "Save & Close"}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default EditExpertQuestionnaire;