import axios from "axios"
import debounce from "lodash/debounce"; // Import the debounce function
import { User } from "@nextui-org/react"
import { useCallback, useEffect, useState } from "react"
import { ReactTags, Tag, TagSuggestion } from "react-tag-autocomplete"
import { uniqBy } from "lodash";

interface Company {
  name: string;
  icon: string;
  domain: string;
}

const CompanySearchInput = ({
  onChange
}: { onChange: (value: Company) => void }) => {
  const [suggestions, setSuggestions] = useState<TagSuggestion[]>([])
  const [selected, setSelected] = useState<Tag>()
  const [inputValue, setInputValue] = useState<string>("")

  useEffect(() => {
    onChange(selected?.value as any)
  }, [selected]);

  const onAdd = useCallback((company) => {
    setSelected(company)
  }, [selected])

  const onDelete = useCallback((tagIndex) => {
    setSelected(undefined)
  }, [selected])

  const onInputChange = (value: string) => {
    setInputValue(value);
    onChange({ name: value, icon: value, domain: value });
    debouncedSearch(value); // Debounce the search function
  }

  const onSearch = async (value: string) => {
    if (value.trim() !== "") { // Check if input is not empty
      const { data } = await axios.get(`https://api.brandfetch.io/v2/search/${value}`);
      const duplicatesRemoved = uniqBy(data, 'name');
      setSuggestions(duplicatesRemoved.map((d: any) => ({ value: d, label: d.name })));
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }
  }

  const renderTag = ({ classNames, tag, ...tagProps }: any) => {
    return (
      <User
        className={classNames.tag}
        css={{ p: 2, ml: 5, position: "absolute" }}
        name={tag.value?.name}
        onClick={tagProps.onClick}
        size="sm"
        src={tag.value?.icon} />

    )
  }

  const debouncedSearch = debounce(onSearch, 300); // 300ms debounce
  return (
    <div style={{ width: "100%" }}>
      <ReactTags
        selected={selected ? [selected] : []}
        onInput={onInputChange}
        suggestions={suggestions}
        onAdd={onAdd}
        onDelete={onDelete}
        renderTag={renderTag}
        placeholderText={!selected ? "Company" : ""}
        noOptionsText="No matching companies" />
    </div>
  )
}


export default CompanySearchInput;