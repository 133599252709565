import moment from 'moment';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AiFillCiCircle } from 'react-icons/ai';
import { BsCameraVideo, BsCheck2Circle, BsPencilFill } from 'react-icons/bs';
import { BiUser } from 'react-icons/bi';
import { Button, Spacer } from '@nextui-org/react';
import { useIntercom } from 'react-use-intercom';
import { useAPI } from 'hooks/useApi';
import { useInterview } from 'hooks/useInterview';
import { InterviewStatus } from 'types';
import ExpertSetAvailabilityButton from '../set-availability-button';

const ExpertActionButtons = () => {
  const api = useAPI();
  const { showNewMessage } = useIntercom();
  const { interview, refresh } = useInterview();
  const history = useHistory();

  const onDecline = async () => {
    await api.declineInterview(interview?.id);
    await refresh();
    window.open(`${window.location.origin}/contact?message=I'm declining the interview request from ${interview?.company.name} because: `, "_blank");
  };

  const onClick = () => {
    const { status, call, id } = interview;
    if (status === InterviewStatus.INTERVIEW_SCHEDULED) {
      history.push(`/meet/${call?.expertAccessToken}`);
    }
    if (status === InterviewStatus.INTERVIEW_COMPLETED) {
      history.push(`/interview/${id}/report`);
    }
  };

  const getButtonConfig = () => {
    switch (interview.status) {
      case InterviewStatus.DECLINED:
        return {
          color: 'error',
          icon: <AiFillCiCircle />,
          text: 'Declined',
        };
      case InterviewStatus.EXPERT_ACCEPTED:
        return {
          color: 'primary',
          icon: <BiUser />,
          text: `Awaiting Candidate's Acceptance`,
        };
      case InterviewStatus.INTERVIEW_SCHEDULED:
        return {
          color: 'primary',
          icon: <BsCameraVideo />,
          text: `Join Interview - ${moment(interview.call?.time).format('lll')}`,
        };
      case InterviewStatus.INTERVIEW_COMPLETED:
        return {
          color: 'primary',
          icon: <BsPencilFill />,
          text: 'Write Report',
        };
      case InterviewStatus.REPORT_COMPLETED:
        return {
          color: 'primary',
          icon: <BsCheck2Circle />,
          text: 'Report Completed',
        };
      default:
        return {
          color: 'primary',
          icon: '',
          text: interview.status,
        };
    }
  };

  const { color, icon, text } = useMemo(getButtonConfig, [interview.status]);

  const isRequested = interview.status === InterviewStatus.REQUESTED;

  return (
    <div style={{ display: 'flex' }}>
      {isRequested && (
        <>
          <Button auto size="sm" color="error" onClick={onDecline}>
            Decline
          </Button>
          <Spacer x={0.3}/>
          <ExpertSetAvailabilityButton />
        </>
      )}
      {
        !isRequested && (
          <Button
            auto
            flat
            color={color as any}
            icon={icon}
            onClick={onClick}
          >
            {text}
          </Button>
        )
      }
    </div >
  );
};

export default ExpertActionButtons;
