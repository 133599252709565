import {
  createContext,
  useCallback,
  useState,
  useEffect,
  FunctionComponent
} from "react";
import { User } from "@firebase/auth";
import { signInWithCustomToken } from "@firebase/auth";
import * as qs from "query-string";
import Cookies from "js-cookie";

import { auth } from "config";
import { useAPI } from "hooks/useApi";
import { Loading } from "screens/loading";
import { ExpertProvider } from "./expert";
import { CompanyProvider } from "./company";
import { PaymentProvider } from "./payments";

export interface AuthContext {
  user: User | null;
  type?: "EXPERT" | "COMPANY";
  signOut: () => void;
  refresh: (forceRefresh?: boolean) => void;
  refreshToken: (newUser?: User) => void;
  refreshProfiles: () => void;
  loaded: boolean;
  isOnboarding: boolean;
  setIsOnboarding: (value: boolean) => void;
}

export const authContext = createContext<AuthContext>({
  user: null,
  type: "EXPERT",
  signOut: () => {
    throw new Error("Signing out from emptyt auth context!");
  },
  refresh: () => {
    throw new Error("Refreshing auth context!");
  },
  refreshProfiles: () => {
    throw new Error("Refreshing profiles");
  },
  refreshToken: () => {
    throw new Error("Refreshing auth token!");
  },
  loaded: false,
  isOnboarding: true,
  setIsOnboarding: (value: boolean) => {
    throw new Error("Onboarding Complete");
  }
});

interface ProfileMeta {
  id: string,
  type: "EXPERT" | "COMPANY",
}
export const AuthProvider: FunctionComponent = ({ children }) => {

  const api = useAPI();

  const { token } = qs.parse(window.location.search);

  const [user, setUser] = useState(auth.currentUser);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [profile, setProfile] = useState<ProfileMeta>();


  const refresh = async (forceRefresh?: boolean) => {
    await auth.currentUser?.reload();
    if (forceRefresh) {
      await refreshToken(auth.currentUser as User);
    }
  };

  const refreshToken = async (newUser?: User) => {
    const user = newUser || auth.currentUser;
    if (!user) return;
    const authToken = await user.getIdToken(true);
    if (authToken) {
      api.setAuth(authToken);
      Cookies.set("token", authToken);
      setUser(auth.currentUser);
    }

  };

  const refreshProfiles = async () => {
    setLoaded(false);
    const { data } = await api.getManagedProfiles();
    const hasManagedCompanies = data.managedCompanies.filter((c: any) => c.completed === true).length > 0;
    const hasManagedExperts = data.managedExperts.filter((c: any) => c.completed === true).length > 0;

    if (!hasManagedCompanies && !hasManagedExperts) {
      setIsOnboarding(true);
    } else {
      const profileType = hasManagedCompanies ? "COMPANY" : "EXPERT";
      const profile = hasManagedCompanies
        ? data.managedCompanies?.[0]
        : data.managedExperts?.[0];

      if (!profile.completed) {
        setIsOnboarding(true);
      } else {
        setProfile({
          id: profile.id,
          type: profileType,
        });

        setIsOnboarding(false);
      }
    }
    setLoaded(true)
  }

  useEffect(() => {
    if (user) {
      refreshProfiles();
      // LOAD ACCOUNT TYPE HERE
    }
  }, [user])

  const signOut = useCallback(async () => {
    await auth.signOut();
    window.location.reload()
  }, []);

  // Listener for authentication events directly from firebase
  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (newUser) => {
      if (newUser) {
        await refreshToken(newUser);
      } else {
        api.clear();
        Cookies.remove("token");
        setUser(null);
      }
      setLoaded(true);
    });
    return unsub;
  }, []);

  // Forces firebase auth token to be refreshed every 50 minutes
  useEffect(() => {
    if (!user) return;

    const handle = setInterval(async () => {
      refreshToken();
    }, 50 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, [user?.uid]);

  useEffect(() => {
    const signInWithToken = async () => {
      if (auth.currentUser) await signOut();
      await signInWithCustomToken(auth, token as string);
    };
    if (token) signInWithToken();
  }, []);

  if (!loaded) return <Loading />;
  return (
    <authContext.Provider value={{
      user,
      type: profile?.type,
      signOut,
      loaded,
      refresh,
      refreshToken,
      refreshProfiles,
      isOnboarding,
      setIsOnboarding: (value: boolean) => setIsOnboarding(value),
    }}>
      <PaymentProvider>
        {profile ? (profile?.type === 'EXPERT' ? (
          <ExpertProvider>
            {children}
          </ExpertProvider>
        ) : (
          <CompanyProvider>
            {children}
          </CompanyProvider>
        )) : children}
      </PaymentProvider>
    </authContext.Provider>
  );
};
