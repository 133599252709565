import { Button } from "@nextui-org/react";
import { FaLinkedin } from "react-icons/fa";

const LINKEDIN_CALLBACK_URL = `${process.env.REACT_APP_API_URL}/auth/linkedin/callback`;
const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  LINKEDIN_CALLBACK_URL
)}&scope=r_liteprofile%20r_emailaddress`;

const LinkedinAuthButton = () => {
  return (
    <Button
      rounded
      auto
      icon={<FaLinkedin fontSize={20} />}
      css={{ bgColor: "#0076b5", w: "100%" }}
      size={"lg"}
      onClick={() => window.open(linkedinOAuthURL)}>
      Sign up with Linkedin
    </Button>
  )
}

export default LinkedinAuthButton;