
import { Link, useHistory } from "react-router-dom";
import { Button, Text, Link as NextLink } from "@nextui-org/react";
import Container from "layout/portal-layout/container"
import InterviewTable from "components/interview/table";
import { useCompany } from "hooks/useCompany";
import { useIntercom } from "react-use-intercom";

const CompanyHome = () => {
  const history = useHistory();

  const { show } = useIntercom();
  const { interviews } = useCompany();

  const onCreate = () => history.push('/request-interview');
  return (
    <Container
      title="Interviews"
      subtitle="Create and manage your interviews"
      secondaryActions={(
        <Button
          css={{ "@smMax": { mt: "$5" } }}
          rounded
          auto
          onClick={onCreate}>
          Create Interview
        </Button>
      )}>
      <InterviewTable interviews={interviews} />
      {((interviews || []).length === 0) ? (
        <>
          <Text h5>
            {"Welcome to Candid! We're so happy to have you onboard."}
          </Text>
          <Text>
            •   To activate your account navigate to <Link to="/account/credits"> Account &gt; Credits</Link> to add credits via our secure Stripe payments to ensure you have uninterrupted access to our experts.<br />
            •   Next, navigate to <Link to="/request-interview">Interviews &gt; Create new interview</Link> and follow the steps to share your candidate details and select an expert.<br />
            •   Hit <NextLink onClick={() => window.open(`${window.location.origin}/contact`, "_blank")}>Help</NextLink> if you have any questions or need assistance - our support team is at your service.<br />
            •   Finally, navigate to <Link to="/account/integrations">Account &gt; Integrations</Link> to see if we have a current connection to your ATS.
          </Text>
        </>
      ) : <></>}
    </Container>
  )
}

export default CompanyHome;


