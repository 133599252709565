import { Card, Grid, Spacer, Text } from "@nextui-org/react"
import { Caption } from "components/shared/styled";
import { useFormikContext } from "formik";
import { BsCheckLg } from "react-icons/bs";

const EXPERT_TIERS = [
  {
    id: "executive",
    title: "Executive Expert",
    description: "I have held executive leadership positions at Fortune 500/FTSE 100 companies or equivalent.",
    price: 1000,
    requirements: []
  },
  {
    id: "senior",
    title: "Senior Expert",
    description: "I have held senior sales leadership positions at scale-up or established businesses.",
    price: 500,
    requirements: []
  },
  {
    id: "talent",
    title: "Talent Acquisition Expert",
    description: "I have held senior leadership positions at established businesses where I’ve recruited or led recruitment teams hiring for sales profiles.",
    price: 200,
    requirements: []
  },
]

const SelectExpertTier = () => {

  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <Grid.Container direction="row" gap={1}>
      {EXPERT_TIERS.map((tier) => (
        <Grid md={12} sm={12} xs={12} key={tier.id}>
          <Card
            variant="bordered"
            css={{ p: "$5", mt: "$5", bgColor: values["tier"] === tier.id ? "$green300" : "inherit" }}
            onClick={() => setFieldValue("tier", tier.id)}
            isPressable
            isHoverable>
            <Card.Body>
              <Text h3>{tier.title}</Text>
              <Caption>
                {tier.description}
              </Caption>
              <Spacer y={0.5} />
              {tier.requirements.map((req) => (
                <Text css={{ display: "flex", alignItems: "center" }}>
                  <BsCheckLg style={{ marginRight: 5, color: "green" }} />
                  {req}
                </Text>
              ))}
            </Card.Body>
          </Card>
        </Grid>
      ))}
    </Grid.Container>
  )
}

export default SelectExpertTier;