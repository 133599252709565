import { Divider, Link, Navbar as NextNavbar, Spacer } from "@nextui-org/react"
import { useHistory } from "react-router-dom"

import UserMenuDropdown from "./user-menu"

const NavigationItems = [
  {
    id: 'interviews',
    title: 'Interviews',
    path: '/',
  },
  {
    id: 'account',
    title: 'Account',
    path: '/account',
  },
  {
    id: 'help',
    title: 'Help',
    path: "help",
    externalPath: `${window.location.origin}/contact`,
  }
]

const Navbar = () => {
  const history = useHistory();
  const isActive = (path: string) => {
    const currentPath = location.pathname;
    return currentPath === path || currentPath.startsWith(path + '/');
  };

  const onClickLink = (path: string) => {
    if(path.startsWith('/')) {
      history.push(path);
      return;
    }
    window.open(path, "_blank")
  };

  return (
    <NextNavbar variant={"floating"} maxWidth={"xl"} css={{ zIndex: 9999 }}>
      <NextNavbar.Brand>
        <NextNavbar.Toggle css={{ pr: "$8" }} showIn={"sm"} />
        <img
          onClick={() => history.push('/')}
          style={{ cursor: "pointer" }}
          width={160}
          src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Logo-Black.png?alt=media&token=a7e0a7ee-6f9c-4383-a870-3540e832d101" />
        <NextNavbar.Content
          hideIn="sm" css={{ pl: "15%" }}>
          {NavigationItems.map(({ title, path, id, externalPath }, idx) =>
            <NextNavbar.Link
              key={id}
              onClick={onClickLink.bind(this, externalPath ?? path)}
              isActive={isActive(path)}
              css={{ opacity: isActive(path) ? 1 : 0.8, fontSize: 30 }}>
              {title}
            </NextNavbar.Link>
          )}
        </NextNavbar.Content>
      </NextNavbar.Brand>

      <NextNavbar.Content css={{ jc: "flex-end" }}>
        <UserMenuDropdown />
      </NextNavbar.Content>
      <NextNavbar.Collapse>
        {NavigationItems.map(({ title, path, externalPath, id }) => (
          <NextNavbar.CollapseItem key={id}>
            <Link
              color={isActive(path) ? "primary" : "inherit"}
              css={{ minWidth: "100%" }}
              onClick={onClickLink.bind(this, externalPath ?? path)}>
              {title}
            </Link>
          </NextNavbar.CollapseItem>
        ))}
        <Spacer y={0.5} />
        <Divider />
        <Spacer y={1} />
      </NextNavbar.Collapse>
    </NextNavbar>
  )
}

export default Navbar;