import { Container, Link, Progress, Spacer, Text } from "@nextui-org/react"
import { styles } from "./styles"
import { STEPS } from "./steps"
import { useCompany } from "hooks/useCompany"
import { useHistory } from "react-router-dom"

const CreateInterviewHeader = ({
  currStep,
}: {currStep: number}) => {
  const history = useHistory();
  const { availableCredits } = useCompany();

  return (
    <Container md css={styles.headerContainer}>
      <img style={{ cursor: "pointer" }} width={160} src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Logo-Black.png?alt=media&token=a7e0a7ee-6f9c-4383-a870-3540e832d101" />
      <Spacer x={2} />
      <Progress min={0} max={STEPS.length} value={currStep} />
      <Spacer x={2} />
      <div style={{ width: 200 }}>
        <Text h5 css={{ lineHeight: 1, mb: 0, opacity: 0.8 }} weight={"medium"}>
          {availableCredits} Credits
        </Text>
        <Link css={styles.headerLink} onClick={() => history.push("/account/credits")}>
          Purchase More
        </Link>
      </div>
    </Container>
  )
}

export default CreateInterviewHeader;