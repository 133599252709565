import { Card, Divider, Grid, Spacer, Text, Tooltip } from "@nextui-org/react";
import { useCompany } from "hooks/useCompany";
import { Caption2 } from "components/shared/styled";
import PurchaseCredits from "components/payments/company/purchase-credits";
import PaymentHistory from "components/payments/history";

const CompanyPaymentCredits = () => {
  const { availableCredits } = useCompany();

  return (
    <Card variant="bordered" css={{ p: "$8" }}>
      <Text h4 weight="normal" css={{ mb: "$5" }}>
        Credit Balance: {availableCredits}
      </Text>
      <Caption2 css={{ fontSize: 14 }}>
        Credit must be added to your account before confirming your selected expert
      </Caption2>
      <Spacer y={1} />
      <Divider />
      <Spacer y={1} />
      <Grid.Container gap={3} css={{"@smMax": { p: 0 }}}>
        <Grid md={7} xs={12} direction="column">
          <Text h3 css={{ fontSize: 24, lineHeight: 1 }}>
            Purchase Credits
            <Caption2 css={{ fontSize: 14, lineHeight: 1 }}>
              <Tooltip
                color={"invert"}
                placement="bottom"
                content={
                  <div style={{ maxWidth: 350, whiteSpace: "pre-line", fontSize: 14 }}>
                    See below guide for how many credits should be purchased for each interview level:
                    {"\n"} {"\n"}
                    <span style={{ fontSize: 12 }}>
                      1 credit   = 1 x interview with: Talent Acquisition Manager{"\n"}
                      2 credits = 1 x interview with: Head of Talent Acquisition{"\n"}
                      3 credits = 1 x interview with: VP, Sales{"\n"}
                      4 credits = 1 x interview with: RVP, Sales{"\n"}
                      5 credits = 1 x interview with: CRO / SVP, Sales
                    </span>
                  </div>
                }>
                {"\n"}
                Learn More
              </Tooltip>
            </Caption2>
          </Text>

          <Spacer y={1} />
          <PurchaseCredits />
        </Grid>
        <Grid md={5} xs={12} direction="column">
          <Text h5 css={{ mb: 0, opacity: 0.4 }}>
            Payment History
          </Text>
          <PaymentHistory />
        </Grid>
      </Grid.Container>
    </Card>
  )
}

export default CompanyPaymentCredits;