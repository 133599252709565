import { CSS } from "@nextui-org/react";

export const styles: { [key: string]: CSS } = {
  heroContainer: {
    background: "radial-gradient(41.9% 33.300000000000004% at 49.2% 42.199999999999996%,#52b68a 0%,rgba(255,255,255,0) 100%)",
    backgroundSize: "cover",
    display: "flex", 
    flex: 1, 
    flexDirection: "column",
  },
  heroSubtitle: {
    textAlign: "center",
    maxWidth: 800,
    letterSpacing: -0.5,
    fontSize: "$lg",
    "@xsMax": {
      fontSize: "$md"
    }
  },
  expertCarouselContainer: { 
    display:"flex",
    flexWrap: "nowrap", 
    width: "3000px",
    overflow:"hidden",
    // animation: "swipe 30000ms linear infinite backwards",
    // "@smMax": {
    //   animation: "swipe 5000ms linear infinite backwards",
    // }
  },
  sectionTitle: {
    textAlign: "center", 
    fontSize: 72,
    textGradient: "45deg, #52b68a -20%, #3eb68a 50%",
    "@smMax": {
      fontSize: 52
    }
  },
  sectionDescription: { 
    opacity: 0.8, 
    fontSize: "$md", 
    lineHeight: 1.6 
  }
}