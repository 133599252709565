import { Button, Grid, Input, Loading, Spacer } from "@nextui-org/react"
import { GRID_FULL_WIDTH } from "config/grid"
import { Formik, FormikHelpers, useFormikContext } from "formik"
import { useAPI } from "hooks/useApi"
import { useCompany } from "hooks/useCompany"

import { DEFAULT_INPUT_PROPS } from "screens/auth"
import { CompanyRegistration, OnboardingStatus } from "types/forms"
import FormikUploadFileInput from "../fields/upload-file-input"
import { mapCompanyProfileToInitialValues, mapCompanyQuestionnaireToInitialValues } from "utils/forms"

export const CompanyProfileWrapper = () => {
  const api = useAPI();
  const { profile, refresh } = useCompany();

  const onSubmit = async (values: CompanyRegistration, { setSubmitting }: FormikHelpers<CompanyRegistration>) => {
    setSubmitting(true);
    await api.createCompanyProfile({
      ...values,
      type: OnboardingStatus.COMPLETE,
      industry: values.industry.map(i => i.value)?.[0],
    });
    await refresh()
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        ...mapCompanyProfileToInitialValues(profile),
        ...mapCompanyQuestionnaireToInitialValues(profile?.questionnaire)
      } as any}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        isValid,
        handleSubmit
      }) => (
        <div>
          <CompanyProfile />
          <Spacer y={1} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              auto
              rounded
              disabled={isSubmitting || !isValid}
              onClick={() => handleSubmit()}>
              {isSubmitting ? <Loading type="spinner" /> : "Save"}
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

const CompanyProfile = () => {
  const {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormikContext<CompanyRegistration>();

  return (
    <Grid.Container gap={2}>
      <Grid {...GRID_FULL_WIDTH} css={{justifyContent:"center"}}>
        <FormikUploadFileInput
          label="Upload Logo"
          acceptedFileTypes={["images/*"]}
          uploadBasePath="company/images"
          id="logoUrl"/>
      </Grid>
      <Grid {...GRID_FULL_WIDTH}>
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="contactName"
          name="contactName"
          label="Your Name"
          placeholder="Enter your Name"
          onChange={handleChange}
          value={values.contactName}
          helperText={touched?.contactName && errors?.contactName ? errors.contactName : ""}
          helperColor={"error"} />
      </Grid>
      <Grid {...GRID_FULL_WIDTH}>
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="contactTitle"
          name="contactTitle"
          label="Your Title"
          placeholder="Enter your Title"
          onChange={handleChange}
          value={values.contactTitle}
          helperText={touched?.contactTitle && errors?.contactTitle ? errors.contactTitle : ""}
          helperColor={"error"} />
      </Grid>
      <Grid {...GRID_FULL_WIDTH}>
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="name"
          name="name"
          label="Company Name"
          placeholder="Enter your Company Name"
          onChange={handleChange}
          value={values.name}
          helperText={touched?.name && errors?.name ? errors.name : ""}
          helperColor={"error"} />
      </Grid>
      <Grid {...GRID_FULL_WIDTH}>
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="website"
          name="website"
          label="Website URL"
          placeholder="Website"
          onChange={handleChange}
          value={values.website}
          helperText={touched?.website && errors?.website ? errors.website : ""}
          helperColor={"error"} />
      </Grid>
      <Grid {...GRID_FULL_WIDTH}>
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="atsProvider"
          name="atsProvider"
          label="What's your Current ATS Provider?"
          placeholder="eg Greenhouse, Workable"
          onChange={handleChange}
          value={values.atsProvider}
          helperText={touched?.atsProvider && errors?.atsProvider ? errors.atsProvider : ""}
          helperColor={"error"} />
      </Grid>
    </Grid.Container>
  )
}
export default CompanyProfile;