import { Button, Text } from "@nextui-org/react"
import { useFormikContext } from "formik";

interface Option {
  display: string;
  value: string;
}

interface SelectOptionInputProps {
  id: string;
  selectionMode?: "single" | "multiple";
  options: Option[];
}

const SelectOptionInput = ({
  id,
  options,
  selectionMode = "multiple",
}: SelectOptionInputProps) => {

  const { values, errors, touched, setFieldValue } = useFormikContext<any>();

  const onChangeOption = (option: Option) => {
    if(values?.readOnly) return;
    const { value } = option;
    if (selectionMode === "single") {
      setFieldValue(id, value);
      return;
    }

    const currValues = values[id] as string[]
    setFieldValue(id, currValues.some(t => t === value) ?
        (currValues.filter(t => t !== value))
        : ([...currValues, value])
    )
  }

  return (
    <>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {options.map(option => (
        <Button
          size="md"
          auto
          css={{ m: "$2" }}
          key={option.value} // Don't forget to add a unique key to the elements in a loop
          bordered={selectionMode === "single" ? values[id] !== option.value : values[id]?.every((val: string) => val !== option.value)} // Fixed the issue here
          onClick={onChangeOption.bind(this, option)}>
          {option.display}
        </Button>
      ))}
    </div>
      <Text small color="error" css={{mt:"$5"}}>
        {touched[id] && errors[id]}
      </Text>
    </>
  );
};

export default SelectOptionInput;