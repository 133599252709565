import CircularProgressBar from "components/shared/circular-progress";
import { useInterview } from "hooks/useInterview";
import { useMemo } from "react";

const CandidateScoreProgress = () => {
  const { interview } = useInterview();

  const { report } = interview;

  const calculateScore = (scoreCard: any) => {
    const filteredValues = Object.values(scoreCard).filter(value => typeof value === 'number' && !isNaN(value));
    const sum = (filteredValues as number[]).reduce((agg, curr) => agg + (curr * 2), 0);
    return Math.round((sum / (filteredValues.length * 10)) * 100);
  };
  
  const score = useMemo(() => calculateScore(report.scoreCard), [report.scoreCard]);""
  const activeStrokeColor = useMemo(() => {
    switch (true) {
      case score > 80:
        return "#3eb68a";
      case score > 70:
        return "#F5A524";
      case score > 60:
        return "#F9CB80";
      default:
        return "#ff0000";
    }
  }, [score]);

  return (
    <CircularProgressBar
      radius={100}
      strokeWidth={15}
      valueFontSize={36}
      label={"confidence score"}
      labelFontSize={16}
      activeStrokeColor={activeStrokeColor}
      selectedValue={score}
      maxValue={100} />
  )
}

export default CandidateScoreProgress;