import { useState } from "react";
import { useSnackbar } from "notistack";
import { Formik, FormikHelpers } from "formik";
import { updateEmail } from "@firebase/auth";
import { Button, Input, Loading, Spacer, Text } from "@nextui-org/react";
import { auth } from "config";
import { mapAuthCodeToMessage } from "helpers";
import { useAuth } from "hooks/useAuth";
import ReauthenticateUserModal from "components/modals/reauthenticate-user";

interface ChangeEmailFormValues {
  email: string;
  newEmail: string;
}

const ChangeEmailForm = () => {
  const snackbar = useSnackbar();
  const { user } = useAuth();

  const [showReauthModal, setShowReauthModal] = useState(false);

  const onSubmit = async (values: ChangeEmailFormValues, { setSubmitting }: FormikHelpers<ChangeEmailFormValues>) => {
    setSubmitting(true);
    try {
      const user = auth.currentUser!;
      await updateEmail(user!, values.newEmail);
      snackbar.enqueueSnackbar("Email successfully updated", { variant: "success" });
      window.location.reload();
    } catch (err) {
      snackbar.enqueueSnackbar(mapAuthCodeToMessage(err), { variant: "error" });
    }
    setSubmitting(false);
  }

  return (
    <>
      <Formik
        initialValues={{
          email: user?.email || "",
          newEmail: "",
        }}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          isValid,
          values,
          handleSubmit,
          handleChange
        }) => (
          <>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Text h3 css={{ fontSize: 18, lineHeight: 1 }}>
                Change Email
              </Text>
              <Spacer y={0.5} />
              <Input
                id="email"
                name="email"
                label="Existing Email"
                readOnly
                onChange={handleChange}
                value={values.email}
                placeholder="Email" />
              <Spacer y={0.5} />
              <Input
                id="newEmail"
                name="newEmail"
                label="New Email"
                placeholder="New Email"
                onChange={handleChange}
                value={values.newEmail} />
              <Spacer y={0.8} />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  auto
                  flat
                  size="sm"
                  onClick={() => setShowReauthModal(true)}
                  disabled={!values.newEmail || isSubmitting}>
                  {isSubmitting ? <Loading type="spinner" /> : "Update Email"}
                </Button>
              </div>
            </div>
            <ReauthenticateUserModal
              open={showReauthModal}
              onComplete={(success) => {
                if (success) {
                  handleSubmit();
                }
                setShowReauthModal(false);
              }} />
          </>
        )}
      </Formik>

    </>
  )
}

export default ChangeEmailForm;