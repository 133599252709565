import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { Link, Spacer, Text } from "@nextui-org/react";
import { Redirect } from "react-router-dom";

import { useInterview } from "hooks/useInterview";
import { withCompanyInterview } from "screens/portal/company/interview";
import { InterviewStatus } from "types";
import Container from "layout/portal-layout/container";
import ExpertOpinion from "components/report/expert-opinion";
import ShareReportButton from "components/report/share-report-button";
import AviAiInsights from "components/avi-insights"

const TABS = [
  {
    id: "opinion",
    title: "Expert Opinion"
  },
  {
    id: "avi",
    title: "Avi Insights"
  }
]

interface CompanyInterviewReportProps {
  external?: boolean;
}

export const CompanyInterviewReport = ({
  external = false
}: CompanyInterviewReportProps) => {
  const { interview } = useInterview();

  const [selectedTab, setSelectedTab] = useState<string>("opinion");

  const content = useMemo(() => {
    switch (selectedTab) {
      case "opinion":
        return <ExpertOpinion />
      case "avi":
        return <AviAiInsights />
      default:
        return <></>
    }
  }, [selectedTab]);

  if(interview.status !== InterviewStatus.REPORT_COMPLETED) {
    return <Redirect to={`/interview/${interview.id}`}/>
  }

  return (
    <Container
      title={`Candid Report for ${interview.candidate?.name}`}
      subtitle={`Prepared by ${interview.expert?.firstName} ${interview?.expert?.lastName} on ${moment(interview?.report?.created).calendar()}`}
      secondaryActions={
        !external ? <ShareReportButton id={interview?.id}/> : <></>
      }
      backButton={!external}>
      <div style={{ display: "flex" }}>
        {TABS.map(tab =>
          <Fragment key={tab.id}>
            <Link onClick={() => setSelectedTab(tab.id)}>
              <Text h4 css={{ opacity: selectedTab === tab.id ? 1 : 0.6 }}>
                {tab.title}
              </Text>
            </Link>
            <Spacer x={1} />
          </Fragment>
        )}
      </div>
      <Spacer y={0.5} />
      {content}
    </Container>
  )
}

export default withCompanyInterview(CompanyInterviewReport);